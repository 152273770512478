import React from "react";
import { Link } from "react-router-dom";

const Monthly = () => {
  return (
    <>
      <div className="pricing-plans-container">
        <div className="pricing-plan">
          <h3>Ücretsiz Plan</h3>
          <p className="margin-top-10">
            ParttimeJET hizmetlerinden sınırlı erişim hakkına sahip olduğunuz
            plan.
          </p>
          <div className="pricing-plan-label billed-monthly-label">
            <strong>0₺</strong>/ aylık
          </div>
          <div className="pricing-plan-features">
            <strong>Ücretsiz Plan İçeriği</strong>
            <ul>
              <li>1 İlan</li>
              <li>1 Konum</li>
              <li>5 PT İşbaşı</li>
            </ul>
          </div>
          <Link to="/payment" className="button full-width margin-top-20">
            Zaten Sahipsiniz
          </Link>
        </div>

        <div className="pricing-plan recommended">
          <div className="recommended-badge">Önerilen</div>
          <h3>PRO Plan</h3>
          <p className="margin-top-10">
            ParttimeJET hizmetlerinden bir çoğuna erişim hakkına sahip olduğunuz
            plan.
          </p>
          <div className="pricing-plan-label billed-monthly-label">
            <strong>1.000₺</strong>/ aylık
          </div>
          <div className="pricing-plan-features">
            <strong>PRO Plan İçeriği</strong>
            <ul>
              <li>5 İlan</li>
              <li>5 Konum</li>
              <li>50 PT İşbaşı</li>
            </ul>
          </div>
          <Link to="/payment" className="button full-width margin-top-20">
            Satın Al
          </Link>
        </div>

        <div className="pricing-plan">
          <h3>VIP Plan</h3>
          <p className="margin-top-10">
            ParttimeJET hizmetlerine sınırsız erişim hakkına sahip olduğunuz
            plan.
          </p>
          <div className="pricing-plan-label billed-monthly-label">
            <strong>2.000₺</strong>/ aylık
          </div>
          <div className="pricing-plan-features">
            <strong>VIP Plan İçeriği</strong>
            <ul>
              <li>Limitsiz İlan Hakkı</li>
              <li>Limitsiz Konum Hakkı</li>
              <li>Limitsiz PT İşbaşı</li>
            </ul>
          </div>
          <Link to="/payment" className="button full-width margin-top-20">
            Satın Al
          </Link>
        </div>
      </div>
    </>
  );
};

export default Monthly;
