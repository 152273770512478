import clockImg from '@/images/icons/clock.png'

const ConfirmationCode = () => {
  return (
      <div className='form-container'>
        <div className="row r-container">
          <div className="col-md-8 offset-md-2">
            <div className="reset-password-page">
              <div className="welcome-text">
                <h3 style={{ fontSize: '26px' }}>Hesabını onayla</h3>
                <span>
                  Mail adresine gönderdiğimiz 6 haneli onay kodunu gir.
                  <br />
                  Onay kodunun gönderildiği mail: <strong>mail@mail.com</strong>
                </span>
              </div>
              <div
                data-v-44ddbedf=""
                className="d-flex align-items-center justify-content-center timer-area timer-active"
              >
                <div data-v-44ddbedf="">
                  <img
                    data-v-44ddbedf=""
                    // src="images/icons/clock.png"
                    src={clockImg}
                    alt="Doğrulama ikonu"
                  />{' '}
                  <span data-v-44ddbedf="" id="counter" className="ml-2">
                    05:00
                  </span>
                </div>
              </div>

              <div
                data-v-44ddbedf=""
                id="pincode-input-wrapper"
                className="d-flex justify-content-center"
              >
                <div data-v-44ddbedf="">
                  <div
                    data-v-44ddbedf=""
                    className="d-flex align-items-center pincode-area"
                  >
                    <div
                      data-v-44ddbedf=""
                      className="vue-pincode-input-wrapper"
                    >
                      <input type="tel" className="vue-pincode-input" />
                      <input type="tel" className="vue-pincode-input" />
                      <input type="tel" className="vue-pincode-input" />
                      <input type="tel" className="vue-pincode-input" />
                      <input type="tel" className="vue-pincode-input" />
                      <input type="tel" className="vue-pincode-input" />
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="button full-width button-sliding-icon ripple-effect margin-top-10"
                type="submit"
                form="login-form"
              >
                Doğrula{' '}
                <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
  )
}

export default ConfirmationCode
