// import React, { LegacyRef, useEffect, useRef, useState } from 'react';

// interface DropdownProps {
//   options: { value: string; label: string; icon?: string }[];
//   placeholder: string;
//   onSelect?: (value: string) => void;
//   label?: string;
//   className?: string;
// }

// const Dropdown: React.FC<DropdownProps> = ({
//   options,
//   placeholder,
//   onSelect,
//   label,
//   className
// }) => {
//   const [selectedOption, setSelectedOption] = useState('');
//   const [isOpen, setIsOpen] = useState(false);
//   const wrapperRef: LegacyRef<HTMLDivElement> | undefined = useRef(null);

//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleSelect = (value: string) => {
//     setSelectedOption(value);
//     onSelect && onSelect(value);
//     setIsOpen(false); // Close the dropdown after selection
//   };

//   useEffect(() => {
//     /**
//      * Alert if clicked on outside of element
//      */
//     const handleClickOutside = (event: any) => {
//       if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
//         setIsOpen(false);
//       }
//     };
//     // Bind the event listener
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       // Unbind the event listener on clean up
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [wrapperRef]);

//   return (
//     <div>
//       {label && <label className="label-input">{label}</label>}
//       <div className={`btn-group bootstrap-select with-border ${className} ${isOpen ? 'open' : ''}`} ref={wrapperRef}>
//         <button
//           type="button"
//           className="btn dropdown-toggle bs-placeholder btn-default"
//           onClick={toggleDropdown}
//           aria-haspopup="true"
//           aria-expanded={isOpen ? 'true' : 'false'}
//           data-toggle="dropdown"
//           role='button'
//         >
//           <span className="filter-option pull-left">
//             {selectedOption || placeholder}
//           </span>
//           &nbsp;
//           <span className="bs-caret">
//             <span className="caret"></span>
//           </span>
//         </button>
//         <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`} role="menu">
//           {options.map((option, index) => (
//             <li key={index} role="presentation">
//               <a
//                 href="#"
//                 className="dropdown-item"
//                 onClick={() => handleSelect(option.value)}
//               >
//                 {option?.icon && <span className={`${option.icon}`} />}
//                 <span className="margin-left-4 text">{option.label}</span>
//               </a>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Dropdown;
import React, { LegacyRef, useEffect, useRef, useState } from "react";

interface DropdownProps {
  options: { value: string; label: string; icon?: string }[];
  placeholder: string;
  onSelect?: (value: string) => void;
  label?: string;
  className?: string;
  selectedValue?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  placeholder,
  onSelect,
  label,
  className,
  selectedValue
}) => {
  // Change selectedOption to store the full option object
  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    label: string;
  } | null>(selectedValue ? options.find((option) => option.value == selectedValue) || null : null);

  console.log(selectedOption, selectedValue, options);

  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef: LegacyRef<HTMLDivElement> | undefined = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Update handleSelect to receive the full option object
  const handleSelect = (option: { value: string; label: string }) => {
    setSelectedOption(option); // Store the entire option object
    onSelect && onSelect(option.value); // Keep the value for further use if needed
    setIsOpen(false); // Close the dropdown after selection
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div>
      {label && <label className="label-input">{label}</label>}
      <div
        className={`btn-group bootstrap-select with-border ${className} ${
          isOpen ? "open" : ""
        }`}
        ref={wrapperRef}
      >
        <button
          type="button"
          className="btn dropdown-toggle bs-placeholder btn-default"
          onClick={toggleDropdown}
          aria-haspopup="true"
          aria-expanded={isOpen ? "true" : "false"}
          data-toggle="dropdown"
          role="button"
        >
          <span className="filter-option pull-left">
            {selectedOption ? selectedOption.label : placeholder}{" "}
            {/* Show label instead of value */}
          </span>
          &nbsp;
          <span className="bs-caret">
            <span className="caret"></span>
          </span>
        </button>
        <ul className={`dropdown-menu ${isOpen ? "show" : ""}`} role="menu">
          {options.map((option, index) => (
            <li key={index} role="presentation">
              <a
                href="#"
                className="dropdown-item"
                onClick={() => handleSelect(option)} // Pass the whole option object
              >
                {option?.icon && <span className={`${option.icon}`} />}
                <span className="margin-left-4 text">{option.label}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
