import React, { useState } from "react";

const Bookmark = () => {
  // State to track whether the item is bookmarked or not
  const [isBookmarked, setIsBookmarked] = useState(false);

  // Function to toggle the bookmark status
  const handleBookmarkClick = () => {
    setIsBookmarked((prevIsBookmarked) => !prevIsBookmarked); // Toggle the state
  };

  return (
    <>
      <div className="sidebar-widget">
        <h3>Bookmark</h3>

        {/* <!-- Bookmark Button --> */}
        <button
          className={`bookmark-button margin-bottom-25 ${
            isBookmarked ? "bookmarked" : ""
          }`} // Add a class when bookmarked
          onClick={handleBookmarkClick} // OnClick handler
        >
          <span className="bookmark-icon"></span>
          <span className="bookmark-text">
            {isBookmarked ? "Remove Bookmark" : "Bookmark"} {/* Dynamic text */}
          </span>
          <span className="bookmarked-text">Bookmarked</span>
        </button>
      </div>
    </>
  );
};

export default Bookmark;
