import React from 'react'
import { Link } from 'react-router-dom'

const Login = () => {
  return (
      <div className='form-container'>
        <div className="row l-container">
          <div className="col-md-8 offset-md-2">
            <div className="login-register-page">
              <div className="welcome-text">
                <h3>Giriş Yap</h3>
                <span>
                  Hesabın yok mu? <Link to="/employer/register">Üye Ol!</Link>
                </span>
              </div>

              <form method="post" id="login-form">
                <div
                  className="input-with-icon-left"
                  title="Email adresinizi girin"
                  data-tippy-placement="bottom"
                >
                  <i className="icon-material-baseline-mail-outline"></i>
                  <input
                    type="text"
                    className="input-text with-border"
                    name="emailaddress"
                    id="emailaddress"
                    placeholder="Email Adresiniz"
                  />
                </div>

                <div
                  className="input-with-icon-left"
                  title="Şifrenizi girin"
                  data-tippy-placement="bottom"
                >
                  <i className="icon-material-outline-lock"></i>
                  <input
                    type="password"
                    className="input-text with-border"
                    name="password"
                    id="password"
                    placeholder="Şifreniz"
                  />
                </div>
                <Link to="/forgot-password" className="forgot-password">
                  Şifreni mi unuttun?
                </Link>
              </form>

              <button
                className="button full-width button-sliding-icon ripple-effect margin-top-10"
                type="submit"
              >
                Giriş Yap{' '}
                <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Login
