import React, { useState } from "react";
import { Link } from "react-router-dom";
import Monthly from "./Monthly";
import Yearly from "./Yearly";

const Subscribtion = () => {
  const [selectedPlan, setSelectedPlan] = useState<string>("monthly");

  const handleTabChange = (planType: string) => {
    setSelectedPlan(planType);
  };

  return (
    <>
      <div className="section gray padding-top-60 padding-bottom-75">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-headline centered margin-top-0 margin-bottom-50">
                <h3>Üyelik Planları</h3>
              </div>
            </div>

            <div className="col-md-12">
              <div className="billing-cycle-radios margin-bottom-70">
                

                {/* Monthly and Yearly Payments */}
                {selectedPlan === "monthly" ? <Monthly /> : <Yearly />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribtion;
