import axios from 'axios';
import api from './api'; // Adjust the import based on your project structure
import  { AxiosResponse } from 'axios';

interface ICompanyData {
    name: string;
    email: string;
    description: string;
    rating: number;
    business_area: {
      name: string;
      icon: string;
    };
    phone: string;
    website: string;
  }
  
  interface IApiResponse {
    data: ICompanyData;
    status: string;
    message: string;
    error_code: string | null;
  }

// Define TypeScript interfaces for type safety
interface BusinessArea {
  name: string;
  icon: string;
}

interface CompanyInfoData {
  name: string;
  email: string;
  description: string;
  rating: number;
  business_area: BusinessArea;
  phone: string;
  website: string;
}

interface CompanyInfoResponse {
  data: CompanyInfoData;
  status: string;
  message: string;
  error_code: string | null;
}


  export const fetchCompanyInfo = async (): Promise<CompanyInfoData | null> => {
    try {
      const response = await api.get<CompanyInfoResponse>("/profile/company/info"); // Replace with your actual endpoint
      const result = response as any;
  
      // Check if the response has the expected structure
      if (result) {
        console.log("API Response Data:", result.data); // Debug: Log the response data
        return result.data; // Return the data for further use
      } else {
        console.error("Unexpected response format", result);
        return null; // Return null if the response format is not as expected
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        }
      } else {
        console.error('Unexpected error:', error);
      }
      return null; // Return null on error
    }
  };



 export const updateCompanyInfo = async (companyData: any): Promise<IApiResponse | null> => {
    try {
      // Define the request payload in the specified format
      const requestBody =companyData;
  
      // Make the PUT request
      const response: AxiosResponse<IApiResponse> = await api.put<any>(
        '/profile/company/info', // Replace with your actual endpoint URL
        requestBody, // The payload for the PUT request
      );
  
      // Handle successful response
      console.log('Response:', response.data);
      return response.data; // Return the API response data
    } catch (error: any) {
      // Handle errors
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        }
      } else {
        console.error('Unexpected error:', error);
      }
      return null; // Return null if an error occurred
    }
  };
  



  export const fetchCompanyLocations = async () => {
    const response = await api.get('/profile/company/locations');
    return response as any;
  }

  export const updateCompanyLocations = async (locations: any) => {
    const response = await api.put('/profile/company/locations', locations);
    return response as any;
  }

  export const fetchInvoiceInfo = async () => {
    const response = await api.get('/profile/company/tax-info');
    return response as any;
  }

  export const updateCompanyInvoice = async (invoice: any) => {
    const response = await api.put('/profile/company/tax-info', invoice);
    return response as any;
  }

  export const updateCompanyInvoiceFile = async (name:string, mediaType:string, payload:string) => {
    
    const response = await api.post('/profile/company/tax-info/file', {
      name,
      media_type: mediaType,
      payload,
    });
    return response as any;
  }

  export const createJob = async (jobPosting: any) => {
    const response = await api.post('/job/', jobPosting);
    return response as any;
  }