import { createSlice } from '@reduxjs/toolkit'
import TokenService from './token'


export interface IUserState {
    isLoggedIn: boolean;
    email: string | null;
    first_Name: string | null;
    last_Name: string | null;
    id: number | null;
    // sex: string | null,
    // birthdate: string | null,
}


const initialState: IUserState = {
    isLoggedIn: false,
    email: null,
    first_Name: null,
    last_Name: null,
    id: null
    // sex: null,
    // birthdate: null,
}

if(TokenService.getAccessToken()) {
    initialState.isLoggedIn = true;
    const user = TokenService.getUser();
    initialState.email = user.email;
    initialState.first_Name = user.first_name;
    initialState.last_Name = user.last_name;
    initialState.id = user.id;
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            TokenService.setTokens(action.payload.access_token);
            TokenService.setUser(action.payload.user)
            state.isLoggedIn = true;
            state.email = action.payload.user.email; // email güncelleme
            state.first_Name = action.payload.user.first_name; // first_Name güncelleme
            state.last_Name = action.payload.user.last_name; // last_Name güncelleme
     
        }, 
        clearUser: (state) => {
            TokenService.clear();
            state.isLoggedIn = false;
            state.email = null; // email temizleme
            state.first_Name = null; // first_Name temizleme
            state.last_Name = null; // last_Name temizleme
        }
    },
})

export const { setUser, clearUser } = userSlice.actions

export default userSlice.reducer