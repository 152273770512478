// BackToTop.js
import React, { useState, useEffect } from 'react';

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false); // Initially not visible

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check in case the page is already scrolled

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling behavior
    });
  };

  return (
    <div
      id="backtotop"
      className={isVisible ? 'visible' : ''}
      onClick={scrollToTop}
    >
      <a href="#" aria-label="Back to Top"></a>
    </div>
  );
};

export default BackToTop;
