import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import feather from "feather-icons";
import { fetchNotifications } from "@/api/account";

const NotificationDropdown = () => {
  // Refs for the trigger and dropdown content
  const triggerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showNotificationDropdown, setShowNotificationDropdown] =
    useState(false);

    const [notifications, setNotifications] = useState([]);


  useEffect(() => {
    const handleLoadNotifications = async () => {
      const notificationsResponse = await fetchNotifications();
      setNotifications(notificationsResponse.data);
    };
    handleLoadNotifications();
  }
  , []);
  
  // Toggle dropdown visibility
  const handleNotificationToggle = () => {
    setShowNotificationDropdown((prev) => !prev);
  };

  // Handle click outside of the dropdown content
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.target as Node)
    ) {
      setShowNotificationDropdown(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    // Feather ikonlarını DOM'a eklendikten sonra yeniden değiştirme
    feather.replace();
  }, []);

  return (
    <>
      <div className="header-notifications">
        <div
          className="header-notifications-trigger"
          onClick={handleNotificationToggle}
          ref={triggerRef} // Ref for the trigger
        >
          <a href="#">
            <i data-feather="bell"></i>
            {notifications.length > 0 && <span>{notifications.length}</span>}
          </a>
        </div>

        {showNotificationDropdown && (
          <div
            className="header-notifications-dropdown active"
            ref={dropdownRef} // Ref for the dropdown content
          >
            <div className="header-notifications-headline">
              <h4>Bildirimler</h4>
              <button
                className="mark-as-read ripple-effect-dark"
                title="Tüm Bildirimleri Okundu Say"
                data-tippy-placement="left"
              >
                <i className="icon-feather-check-square"></i>
              </button>
            </div>

            <div className="header-notifications-content">
              <div className="header-notifications-scroll" data-simplebar>
                <ul>
              {notifications.map((notification) => (
                  <li className="notifications-not-read">
                  <Link
                    to="/employer/company-info/job-applications"
                    onClick={() => setShowNotificationDropdown(false)}
                  >
                    <span className="notification-icon">
                      <i className="icon-material-outline-group"></i>
                    </span>
                    <span className="notification-text">
                      <strong>Ali Limon </strong>
                      <span className="color">ilanınıza</span> başvuru yaptı
                    </span>
                  </Link>
                </li>

              ))}

              {notifications.length === 0 && (
                  <li className="notifications-not-read">
                  <span className="notification-icon">
                      <i className="icon-material-outline-group"></i>
                    </span>
                    <span className="notification-text">
                      <strong>Henüz bildirim yok</strong>
                    </span>
                  </li>
                )}

      
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationDropdown;
