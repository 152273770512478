import { useState, useEffect, ChangeEvent, ReactNode } from "react";
import cx from "classnames";

interface ISwitchProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label: ReactNode;
  checked?: boolean;
}

export const Switch = (props: ISwitchProps) => {
  const {
    value,
    onChange,
    name,
    checked: controlledChecked = false,
    label,
  } = props;
  const [checked, setChecked] = useState(controlledChecked);

  useEffect(() => setChecked(controlledChecked), [controlledChecked]);

  return (
    <label className="switch mb-4" htmlFor={`${value}-${name}`}>
      <input
        type="checkbox"
        id={`${value}-${name}`}
        checked={checked}
        name={name}
        value={value}
        onChange={(e) => {
          setChecked(e.target.checked);
          if (onChange) onChange(e);
        }}
      />
      <span className="switch-button"></span> {label}
    </label>
  );
};

// import { ChangeEvent, ReactNode } from "react";
// import cx from "classnames";

// interface ISwitchProps {
//   onChange: (e: ChangeEvent<HTMLInputElement>) => void;
//   name: string;
//   label: ReactNode;
//   checked?: boolean;
// }

// export const Switch = (props: ISwitchProps) => {
//   const { onChange, name, checked = false, label } = props;

//   return (
//     <label className="switch mb-4" htmlFor={`${name}`}>
//       <input
//         type="checkbox"
//         checked={checked}
//         name={name}
//         onChange={onChange}
//       />
//       <span className="switch-button"></span> {label}
//     </label>
//   );
// };
