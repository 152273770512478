import Dropdown from '@/components/Dropdown'
import { DOCUMENT_TYPES } from '@/libs/constants'
import React from 'react'

const UserDocuments = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="dashboard-box margin-top-0">
          <div className="headline">
            <h3>
              <i className="icon-material-outline-business-center"></i>{' '}
              Belgelerim
            </h3>
          </div>

          <div className="content">
            <ul className="dashboard-box-list">
              <li>
                <div className="job-listing">
                  <div className="job-listing-details">
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">
                        <a href="#">ParttimeJET Kullanıcı Sözleşmesi</a>
                        <span className="dashboard-status-button green">
                          Onayladınız
                        </span>
                      </h3>

                      <div className="job-listing-footer">
                        <ul>
                          <li>
                            <i className="icon-material-outline-date-range"></i>{' '}
                            20 Mayıs 2024 tarihinde güncellendi.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="buttons-to-right always-visible">
                  <a href="#" className="button ripple-effect">
                    Sözleşmeyi Oku
                  </a>
                  <a
                    href="#"
                    className="button gray ripple-effect ico"
                    data-tippy-placement="top"
                    data-tippy=""
                    data-original-title="Sözleşmeyi İmzala"
                  >
                    <i className="icon-feather-edit"></i>
                  </a>
                </div>
              </li>

              <li>
                <div className="job-listing">
                  <div className="job-listing-details">
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">
                        <a href="#">Sürücü Belgesi</a>
                        <span className="dashboard-status-button green">
                          Yüklediniz
                        </span>
                      </h3>

                      <div className="job-listing-footer">
                        <ul>
                          <li>
                            <i className="icon-material-outline-date-range"></i>{' '}
                            20 Mayıs 2024 tarihinde yüklediniz.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="buttons-to-right always-visible">
                  <a href="#" className="button ripple-effect">
                    <i className="icon-feather-download"></i> Belgeni İndir
                  </a>
                  <a
                    href="#"
                    className="button red ripple-effect ico"
                    data-tippy-placement="top"
                    data-tippy=""
                    data-original-title="Belgeyi Sil"
                  >
                    <i className="icon-feather-trash-2"></i>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="dashboard-box margin-top-30">
          <div className="headline">
            <h3>
              <i className="icon-material-outline-business-center"></i> Belge
              Ekle
            </h3>
          </div>
          <div className="content with-padding padding-bottom-0">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <Dropdown
                        options={DOCUMENT_TYPES}
                        placeholder="Belge Türü"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <h5>Date of issue</h5>
                      <input
                        className="with-border"
                        id="birthDate"
                        name="profile-birthday"
                        type="text"
                        placeholder="gg/aa/yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <h5>End Date, if any</h5>
                      <input
                        className="with-border"
                        id="birthDate"
                        name="profile-birthday"
                        type="text"
                        placeholder="gg/aa/yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <div className="uploadButton margin-top-10">
                        <input
                          className="uploadButton-input"
                          type="file"
                          accept="image/*, application/pdf"
                          id="upload"
                          multiple
                        />
                        <label
                          className="uploadButton-button ripple-effect"
                          htmlFor="upload"
                        >
                          <i className="icon-feather-upload"></i> Upload
                          Document
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Explanation</h5>
                      <textarea
                        cols={30}
                        rows={4}
                        className="with-border"
                        placeholder="If you would like to make a statement about the document you will add, you can write it here."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="dashboard-box margin-top-30">
          {/* Belge İste */}
          <div className="headline">
            <h3>
              <i className="icon-material-outline-business-center"></i> Belge
              İste
            </h3>
          </div>
          <div className="content with-padding padding-bottom-0">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <Dropdown
                        options={DOCUMENT_TYPES}
                        placeholder="Belge Türü"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Açıklama</h5>
                      <textarea
                        cols={30}
                        rows={1}
                        className="with-border"
                        placeholder="Talep edeceğin belge ile ilgili açıklama yazmak istersen burayı kullanabilirsin."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-3">
        <a
          href="#small-dialog"
          className="popup-with-zoom-anim button full-width button-sliding-icon ripple-effect margin-top-30"
        >
          Bilgilerimi Güncelle{' '}
          <i className="icon-material-outline-arrow-right-alt"></i>
        </a>
      </div>
    </div>
  )
}

export default UserDocuments
