interface EmployementHistoryProps {
  companylogo: string;
  jobtitle: string;
  companyname: string;
  date: string;
  description: string;
}

const EmployementHistory = ({
  companylogo,
  jobtitle,
  companyname,
  date,
  description,
}: EmployementHistoryProps) => {
  return (
    <ul className="boxed-list-ul">
      <li>
        <div className="boxed-list-item">
          {/* <!-- Avatar --> */}
          <div className="item-image">
            <img src={companylogo} alt={`${companyname} logo`} />
          </div>

          {/* <!-- Content --> */}
          <div className="item-content">
            <h4>{jobtitle}</h4>
            <div className="item-details margin-top-7">
              <div className="detail-item">
                <a href="#">
                  <i className="icon-material-outline-business"></i>{" "}
                  {companyname}
                </a>
              </div>
              <div className="detail-item">
                <i className="icon-material-outline-date-range"></i> {date}
              </div>
            </div>
            <div className="item-description">
              <p>{description}</p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default EmployementHistory;
