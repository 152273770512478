// src/components/NavItem.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useMenu } from '../contexts/MenuContext';

interface NavItemProps {
  to: string;
  label: string;
}

const NavItem: React.FC<NavItemProps> = ({ to, label }) => {
  const { setShowMenu } = useMenu();

  const handleClick = () => {
    console.log('called')
    setShowMenu(false);
  };

  return (
    <li>
      <Link to={to} onClick={handleClick} className="current">
        {label}
      </Link>
    </li>
  );
};

export default NavItem;