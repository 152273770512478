import { LegacyRef, useEffect, useRef, useState } from "react";
import userAvatar from "../images/user-avatar-small-01.jpg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@/store"; // Redux store'un root tiplerini içe aktar
import { clearUser } from "@/store/user"; // clearUser eylemini içe aktar4
import store from "@/store";
import TokenService from "@/store/token";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const MenuDropdown = () => {
  const dispatch = useDispatch(); // Redux eylemleri göndermek için kullanılır

  // Redux store'dan kullanıcı verilerini seçin
  const firstName = useSelector((state: RootState) => state.user.first_Name);
  const lastName = useSelector((state: RootState) => state.user.last_Name);
  const navigate = useNavigate();

  // const handleLogout = () => {
  //   dispatch(clearUser());
  //   navigate("/");
  // };
  const handleLogout = async () => {
    try {
      const token = TokenService.getToken();
      const authHeader = `Bearer ${token}`;

      const response = await axios.get(
        "https://api.parttimejet.com/auth/logout",
        {
          headers: {
            accept: "application/json",
            Authorization: authHeader,
          },
        }
      );

      if (response.status === 200) {
        dispatch(clearUser());
        console.log("Çıkış Başarılı");

        navigate("/");
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred during logout:", error);
    }
  };

  const [showMenu, setShowMenu] = useState(false);
  const triggerRef: LegacyRef<HTMLDivElement> = useRef(null);
  const menuRef: LegacyRef<HTMLDivElement> = useRef(null);

  const handleProfileToggle = () => {
    // Toggle menu visibility
    setShowMenu((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      triggerRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      !triggerRef.current.contains(event.target as Node)
    ) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handler for closing the menu when a link is clicked
  const handleLinkClick = () => {
    setShowMenu(false);
  };

  return (
    <div className="header-widget">
      <div className="header-notifications user-menu">
        <div
          className="header-notifications-trigger"
          onClick={(e) => {
            e.stopPropagation(); // Prevent event from bubbling up and triggering the document click handler
            handleProfileToggle();
          }}
          ref={triggerRef} // Separate ref for the trigger
        >
          <a href="#">
            <div className="user-avatar status-online">
              <img src={userAvatar} alt="" />
            </div>
          </a>
        </div>

        {showMenu && (
          <div
            className="header-notifications-dropdown active"
            ref={menuRef} // Separate ref for the dropdown menu
          >
            <div className="user-status">
              <div className="user-details">
                <div className="user-avatar status-online">
                  <img src={userAvatar} alt="" />
                </div>
                <div className="user-name">
                  {firstName && lastName ? (
                    <>
                      {firstName} {lastName}
                      <span>VIP/PRO Plan</span>
                    </>
                  ) : (
                    <span>Kullanıcı Bilgisi Yok</span>
                  )}

                  {/* {firstName} {lastName}
                  <span>VIP/PRO Plan</span> */}
                </div>
              </div>
            </div>

            <ul className="user-menu-small-nav">
              <li>
                <Link to={"/employer/company-info"} onClick={handleLinkClick}>
                  <i className="icon-material-outline-dashboard"></i> Kullanıcı
                  Bilgilerim
                </Link>
              </li>
              <li>
                <Link
                  to="/employer/company-info/settings"
                  onClick={handleLinkClick}
                >
                  <i className="icon-material-outline-settings"></i> Ayarlar
                </Link>
              </li>
              <li>
                <a href="#" onClick={handleLogout}>
                  <i className="icon-material-outline-power-settings-new"></i>{" "}
                  Çıkış Yap
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuDropdown;
