import React, {FormEvent } from 'react'

import clockImg from '@/images/icons/clock.png'
import useOtp from '@/hooks/useOtp'

interface ConfirmationCodeProps {
  otpLength?: number
  onSubmit?: (otp: string[]) => void
  title?: string
  subtitle?: string
  description?: string
  email?: string
}

const OtpCode: React.FC<ConfirmationCodeProps> = ({
  otpLength = 6,
  onSubmit,
  title,
  subtitle,
  description,
  email
}) => {
  const { otp, activeOtpIndex, inputRef, handleChange, onKeyChange } = useOtp({
    otpLength
  })

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (onSubmit) {
      onSubmit(otp)
    }
  }

  return (
    <form onSubmit={handleSubmit} className='form-container'>
      <div className="container">
        <div className="row r-container">
          <div className="col-md-8 offset-md-2">
            <div className="reset-password-page">
              <div className="welcome-text">
                {title && <h3 style={{ fontSize: '26px' }}>{title}</h3>}
                {subtitle && <span>{subtitle}</span>}
                {(description || email) && (
                  <span>
                    {description && <>{description}: </>}
                    {email && <strong>{email}</strong>}
                  </span>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-center timer-area timer-active">
                <div>
                  <img src={clockImg} alt="Doğrulama ikonu" />{' '}
                  <span id="counter" className="ml-2">
                    05:00
                  </span>
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center gap-2">
                {otp.map((value: number, index: number) => (
                  <input
                    key={index}
                    ref={activeOtpIndex === index ? inputRef : null}
                    type="number"
                    name={`otp-${index}`}
                    placeholder=""
                    value={value}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => onKeyChange(e, index)}
                    className="confirm-code text-center"
                  />
                ))}
              </div>

              <button
                className="button full-width button-sliding-icon ripple-effect margin-top-10"
                type="submit"
              >
                Doğrula{' '}
                <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default OtpCode
