// import EmployementHistory from "./EmployementHistory";
// import companyLogo3 from "@/images/company-logo-03a.png";
// const jobHistoryData = [
//   {
//     companylogo: companyLogo3,
//     jobtitle: "Development Team Leader",
//     companyname: "Acodia",
//     date: "May 2019 - Present",
//     description:
//       "Focus the team on the tasks at hand or the internal and external customer requirements.",
//   },
//   {
//     companylogo: companyLogo3,
//     jobtitle: "Senior Developer",
//     companyname: "Tech Solutions",
//     date: "Jan 2015 - Apr 2019",
//     description:
//       "Led the development of major projects, ensuring quality and timely delivery.",
//   },
//   // Daha fazla iş geçmişi...
// ];

// const EmployementComponent = () => {
//   return (
//     <>
//       <div className="boxed-list margin-bottom-60">
//         <div className="boxed-list-headline">
//           <h3>
//             <i className="icon-material-outline-business"></i> Employment
//             History
//           </h3>
//         </div>
//         {jobHistoryData.map((job, index) => (
//           <EmployementHistory
//             key={index}
//             companylogo={job.companylogo}
//             jobtitle={job.jobtitle}
//             companyname={job.companyname}
//             date={job.date}
//             description={job.description}
//           />
//         ))}
//       </div>
//     </>
//   );
// };

// export default EmployementComponent;

import React, { useState } from "react";
import EmployementHistory from "./EmployementHistory";
import companyLogo3 from "@/images/company-logo-03a.png";

const jobHistoryData = [
  {
    companylogo: companyLogo3,
    jobtitle: "Development Team Leader",
    companyname: "Acodia",
    date: "May 2019 - Present",
    description:
      "Focus the team on the tasks at hand or the internal and external customer requirements.",
  },
  {
    companylogo: companyLogo3,
    jobtitle: "Senior Developer",
    companyname: "Tech Solutions",
    date: "Jan 2015 - Apr 2019",
    description:
      "Led the development of major projects, ensuring quality and timely delivery.",
  },
  {
    companylogo: companyLogo3,
    jobtitle: "Senior Developer",
    companyname: "Tech Solutions",
    date: "Jan 2015 - Apr 2019",
    description:
      "Led the development of major projects, ensuring quality and timely delivery.",
  },
  {
    companylogo: companyLogo3,
    jobtitle: "Senior Developer",
    companyname: "Tech Solutions",
    date: "Jan 2015 - Apr 2019",
    description:
      "Led the development of major projects, ensuring quality and timely delivery.",
  },
  {
    companylogo: companyLogo3,
    jobtitle: "Senior Developer",
    companyname: "Tech Solutions",
    date: "Jan 2015 - Apr 2019",
    description:
      "Led the development of major projects, ensuring quality and timely delivery.",
  },
  {
    companylogo: companyLogo3,
    jobtitle: "Senior Developer",
    companyname: "Tech Solutions",
    date: "Jan 2015 - Apr 2019",
    description:
      "Led the development of major projects, ensuring quality and timely delivery.",
  },
  {
    companylogo: companyLogo3,
    jobtitle: "Senior Developer",
    companyname: "Tech Solutions",
    date: "Jan 2015 - Apr 2019",
    description:
      "Led the development of major projects, ensuring quality and timely delivery.",
  },
  {
    companylogo: companyLogo3,
    jobtitle: "Senior Developer",
    companyname: "Tech Solutions",
    date: "Jan 2015 - Apr 2019",
    description:
      "Led the development of major projects, ensuring quality and timely delivery.",
  },
  // Daha fazla iş geçmişi...
];

const EmployementComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // İş geçmişini tarihe göre sıralama (en güncelden en eskiye)
  const sortedJobHistory = jobHistoryData.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  // Mevcut sayfada gösterilecek iş geçmişini hesaplama
  const indexOfLastJob = currentPage * itemsPerPage;
  const indexOfFirstJob = indexOfLastJob - itemsPerPage;
  const currentJobs = sortedJobHistory.slice(indexOfFirstJob, indexOfLastJob);

  // Toplam sayfa sayısını hesaplama
  const totalPages = Math.ceil(jobHistoryData.length / itemsPerPage);

  return (
    <>
      <div className="boxed-list margin-bottom-60">
        <div className="boxed-list-headline">
          <h3>
            <i className="icon-material-outline-business"></i> Employment
            History
          </h3>
        </div>

        {currentJobs.map((job, index) => (
          <EmployementHistory
            key={index}
            companylogo={job.companylogo}
            jobtitle={job.jobtitle}
            companyname={job.companyname}
            date={job.date}
            description={job.description}
          />
        ))}

        {/* Pagination */}
        {totalPages > 1 && ( // Eğer 1'den fazla sayfa varsa pagination gösterilir
          <>
            <div className="clearfix"></div>
            <div className="pagination-container margin-top-40 margin-bottom-10">
              <nav className="pagination">
                <ul>
                  {[...Array(totalPages)].map((_, i) => (
                    <li key={i}>
                      <a
                        href="#"
                        className={`ripple-effect ${
                          currentPage === i + 1 ? "current-page" : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentPage(i + 1);
                        }}
                      >
                        {i + 1}
                      </a>
                    </li>
                  ))}
                  {currentPage < totalPages && (
                    <li className="pagination-arrow">
                      <a
                        href="#"
                        className="ripple-effect"
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentPage(currentPage + 1);
                        }}
                      >
                        <i className="icon-material-outline-keyboard-arrow-right"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
            <div className="clearfix"></div>
          </>
        )}
      </div>
    </>
  );
};

export default EmployementComponent;
