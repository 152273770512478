import Dropdown from '@/components/Dropdown'
import { DOCUMENT_OPTIONS } from '@/libs/constants'
import React from 'react'

const MyDocuments = () => {
  return (
    <div className="row">
      {/* Dashboard Box */}
      <div className="col-md-12">
        <div className="col-md-12 dashboard-box margin-top-0">
          {/* Headline */}
          <div className="headline">
            <h3>
              <i className="icon-material-outline-business-center"></i>{' '}
              Belgelerim
            </h3>
          </div>

          <div className="content">
            <ul className="dashboard-box-list">
              <li>
                {/* Job Listing */}
                <div className="job-listing">
                  {/* Job Listing Details */}
                  <div className="job-listing-details">
                    {/* Details */}
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">
                        <a href="#">ParttimeJET Kullanıcı Sözleşmesi</a>{' '}
                        <span className="dashboard-status-button green">
                          Onayladınız
                        </span>
                      </h3>
                      {/* Job Listing Footer */}
                      <div className="job-listing-footer">
                        <ul>
                          <li>
                            <i className="icon-material-outline-date-range"></i>{' '}
                            20 Mayıs 2024 tarihinde güncellendi.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Buttons */}
                <div className="buttons-to-right always-visible">
                  <a href="#" className="button ripple-effect">
                    Sözleşmeyi Oku
                  </a>
                  <a
                    href="#"
                    className="button gray ripple-effect ico"
                    data-tippy-placement="top"
                    title="Sözleşmeyi İmzala"
                  >
                    <i className="icon-feather-edit"></i>
                  </a>
                </div>
              </li>

              <li>
                {/* Job Listing */}
                <div className="job-listing">
                  {/* Job Listing Details */}
                  <div className="job-listing-details">
                    {/* Details */}
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">
                        <a href="#">Sürücü Belgesi</a>{' '}
                        <span className="dashboard-status-button green">
                          Yüklediniz
                        </span>
                      </h3>
                      {/* Job Listing Footer */}
                      <div className="job-listing-footer">
                        <ul>
                          <li>
                            <i className="icon-material-outline-date-range"></i>{' '}
                            20 Mayıs 2024 tarihinde yüklediniz.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Buttons */}
                <div className="buttons-to-right always-visible">
                  <a href="#" className="button ripple-effect">
                    <i className="icon-feather-download"></i> Belgeni İndir
                  </a>
                  <a
                    href="#"
                    className="button red ripple-effect ico"
                    data-tippy-placement="top"
                    title="Belgeyi Sil"
                  >
                    <i className="icon-feather-trash-2"></i>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="col-md-12 dashboard-box margin-top-30">
          {/* Kimlik Bilgileri */}
          <div className="headline">
            <h3>
              <i className="icon-material-outline-business-center"></i> Belge
              Ekle
            </h3>
          </div>
          <div className="content with-padding padding-bottom-0">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Belge Türü</h5>
                      <Dropdown
                        options={DOCUMENT_OPTIONS}
                        placeholder="Belge Türü"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Belge Türü"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <h5>Veriliş Tarihi</h5>
                      <input
                        className="with-border"
                        id="birthDate"
                        name="profile-birthday"
                        type="date"
                        placeholder="gg/aa/yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <h5>Varsa Bitiş Tarihi</h5>
                      <input
                        className="with-border"
                        id="endDate"
                        name="end-date"
                        type="date"
                        placeholder="gg/aa/yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <div className="uploadButton margin-top-10">
                        <input
                          className="uploadButton-input"
                          type="file"
                          accept="image/*, application/pdf"
                          id="upload"
                          multiple
                        />
                        <label
                          className="uploadButton-button ripple-effect"
                          htmlFor="upload"
                        >
                          <i className="icon-feather-upload"></i> Belge Yükle
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Açıklama</h5>
                      <textarea
                        cols={30}
                        rows={4}
                        className="with-border"
                        placeholder="Ekleyeceğin belge ile ilgili bizlere açıklama yapmak istersen buraya yazabilirsin."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-3">
        <a
          href="#small-dialog"
          className="popup-with-zoom-anim button full-width button-sliding-icon ripple-effect margin-top-30"
          style={{ width: '189.5px' }}
        >
          Bilgilerimi Güncelle{' '}
          <i className="icon-material-outline-arrow-right-alt"></i>
        </a>
      </div>
    </div>
  )
}

export default MyDocuments
