import React, { useEffect, useState } from "react";
import Dropdown from "@/components/Dropdown";
import {
  TAX_PROVINCES_OPTIONS,
  WOMEN_DISTRICTS_OPTIONS,
} from "@/libs/constants";
import { fetchCompanyLocations, updateCompanyLocations } from "@/api/company";
import { fetchDistrict, fetchProvince } from "@/api/public";
import DropdownWithSearch from "@/components/DropdownWithSearch";
import { debug } from "console";
import { toast } from "react-toastify";

const CompanyLocation = () => {

  const [locations, setLocations] = useState<any[]>([]);
  const [provinces, setProvinces] = useState<any[]>([]);
  const [districts, setDistricts] = useState<any>({})

  const addLocation = () => {
    console.log("add location, locations:", locations);
    setLocations([...locations, {}]);
  };

  const loadDistricts = async (index: number, provinceId: number) => {
    if(districts.hasOwnProperty(provinceId) == false) {
      const response = await fetchDistrict(provinceId);
      setDistricts({
        ...districts,
        [provinceId]: response.data.districts.map((district: any) => ({
          value: district.id,
          label: district.name,
        }))
      });
    }

    const location = locations[index];
    location.districts = districts[provinceId];
    location.province_id = provinceId;
    setLocations([...locations]);
  }

  const handleSaveLocations = async () => {
    const response = await updateCompanyLocations(locations.map((location) => ({
      province_id: location.province_id,
      district_id: location.district_id,
    })));

    if(response){
      toast.success('Konumlar başarıyla güncellendi');
    }
  }

  useEffect(() => {
    const handleFetchProvinces = async () => {
      const provincesResponse = await fetchProvince();
      setProvinces(provincesResponse.data.provinces.map((province: any) => ({
        value: province.id,
        label: province.name,
      })));
    }

    const handleLoadLocations = async () => {
      const locationsResponse = await fetchCompanyLocations();
      setLocations(locationsResponse.data);


      
    };
    handleFetchProvinces();
    handleLoadLocations();
  }
  , []);

  useEffect(() => {
    locations.forEach((location, index) => {
      if(location.province_id && !location.districts){
        loadDistricts(index, location.province_id);
      }
    });
  }
  , [locations]);

  return (
    <>
      <div className="col-md-12 dashboard-box margin-top-30">
        <div className="headline">
          <h3>
            <i className="icon-line-awesome-book"></i> Konum
          </h3>
        </div>
        <div className="content with-padding padding-bottom-0">
        {locations.length > 0 && locations.map((location, index) => (
          (
            <div className="row">
            {/* Şehir Seçimi */}
            <div className="col-md-5">
              <div className="submit-field">
                <DropdownWithSearch onSelect={(e)=>
                  loadDistricts(index,parseInt(e))
                }
                key={'provinces_locations'+index+location.province_id+(provinces || []).length}
                selectedValue={location.province_id}
                options={provinces} placeholder="İl" />
              </div>
            </div>
            <div className="col-md-5">
              <div className="submit-field">
                <DropdownWithSearch key={'districts_locations'+index+location.province_id+location.district_id+(location.districts || []).length}
                   onSelect={(e)=>{
                    location.district_id = parseInt(e);
                    setLocations([...locations]);
                  }}
                  selectedValue={location.district_id}
                  options={location.districts || []}
                  placeholder="İlçe"
                />
              </div>
            </div>
            <div className="col-md-2 margin-bottom-12">
              <button className="b-add-location" type="button" onClick={() => {
                locations.splice(index, 1);
                setLocations([...locations]);
              }
              }>
                <i className="icon-line-awesome-trash"></i>
              </button>
            </div>
            
          </div>
           )
        ))}
            
            
        <div className="row">
        <div className="col-md-3 margin-bottom-10">
              <a href="#" className="button ripple-effect small" onClick={() => addLocation()}>
                <i className="icon-feather-plus"></i> Konum Ekle
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <button
                onClick={() => handleSaveLocations()}
                className="button full-width button-sliding-icon ripple-effect margin-top-30"
                type="submit"
                form="login-form"
              >
                Güncelle{" "}
                <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
          </div>
         
          <br />
        </div>
      </div>
    </>
  );
};

export default CompanyLocation;
