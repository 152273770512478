import React from "react";
import Textarea from "./TextArea";
import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
  Marker,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};
const center = {
  lat: 37.777842, // Latitude
  lng: -122.391805, // Longitude
};
const Contact = () => {
  const onLoad = (ref: any) => {
    console.log(ref);
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="contact-location-info margin-bottom-50 padding-top-50">
            <div className="contact-address">
              <ul>
                <li className="contact-address-headline">PartTimeJet Ofis</li>
                <li>425 Berry Street, CA 93584</li>
                <li>Phone (123) 123-456</li>
                <li>
                  <a href="#">mail@example.com</a>
                </li>
              </ul>
            </div>
            <div id="single-job-map-container">
              {/* Google Map Component */}
              <LoadScript
                googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY"
                libraries={["places"]}
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={15}
                >
                  <StandaloneSearchBox
                    onLoad={onLoad}
                    onPlacesChanged={() => {}}
                  >
                    <input
                      type="text"
                      placeholder="Search Box"
                      style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `240px`,
                        height: `32px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                        position: "absolute",
                        left: "50%",
                        marginLeft: "-120px",
                      }}
                    />
                  </StandaloneSearchBox>
                  <Marker position={center} />
                </GoogleMap>
              </LoadScript>
              <a href="#" id="streetView">
                Street View
              </a>
            </div>
          </div>
        </div>

        <div className="col-xl-8 col-lg-8 offset-xl-2 offset-lg-2">
          <section id="contact" className="margin-bottom-60">
            <h3 className="headline margin-top-15 margin-bottom-35">
              Sorunlarınız için bizimle iletişime geçebilirsiniz...
            </h3>

            <form method="post" name="contactform" id="contactform">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-with-icon-left">
                    <input
                      className="with-border"
                      name="name"
                      type="text"
                      id="name"
                      placeholder="Your Name"
                      required={true}
                    />
                    <i className="icon-material-outline-account-circle"></i>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input-with-icon-left">
                    <input
                      className="with-border"
                      name="email"
                      type="email"
                      id="email"
                      placeholder="Email Address"
                      pattern="^[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$"
                      required={true}
                    />
                    <i className="icon-material-outline-email"></i>
                  </div>
                </div>
              </div>

              <div className="input-with-icon-left">
                <input
                  className="with-border"
                  name="subject"
                  type="text"
                  id="subject"
                  placeholder="Subject"
                  required={true}
                />
                <i className="icon-material-outline-assignment"></i>
              </div>

              <div>
                <Textarea />
              </div>

              <input
                type="submit"
                className="submit button margin-top-15"
                id="submit"
                value="Gönder !"
              />
            </form>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Contact;
