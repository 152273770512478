import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import './styles/colors/orange.css'
import { MenuProvider } from './contexts/MenuContext'
import { ModalProvider } from './contexts/ModalContext'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <MenuProvider>
      <ModalProvider>
        <ToastContainer />
        <App />
      </ModalProvider>
    </MenuProvider>
  </BrowserRouter>
)
