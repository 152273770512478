import { Link } from "react-router-dom";
import Input from "@/components/Input";
import { Switch } from "@/components/Switch";
import { signupSchema } from "@/libs/schemes/signup";
import { ISignup } from "@/types/userInd";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { fetchRegister } from "@/api/user";
import store, { setUser } from "@/store";
import TokenService from "@/store/token";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (
    values: ISignup, // Burada ISignup türünü kullanıyoruz
    { setSubmitting }: FormikHelpers<ISignup>
  ) => {
    setError(false);
    try {
      const response: any = await fetchRegister(
        values.first_name,
        values.last_name,
        values.email,
        values.password,
        values.confirm_password,
        (values.type = "INDIVIDUAL")
      );
      // console.log("Fetch Register Response:", response);

      if (response.status === "0") {
        TokenService.setTokens(response.data.token);
        TokenService.setUser(response.data.user);
        store.dispatch(setUser(response.data));
        navigate("/");

        console.log(response);
        console.log("Kullanıcı kaydı başarılı:", response.data);
      } else {
        setError(true);
        console.error("Kullanıcı kaydı başarısız:", response.data);
      }
    } catch (error) {
      setError(true);
      console.error("Beklenmeyen bir hata oluştu:", error);
    } finally {
      setSubmitting(false);
    }
  };
  // const handleSubmit: (
  //   values: ISignup,
  //   formikHelpers: FormikHelpers<ISignup>
  // ) => void | Promise<any> = async (values, { setSubmitting }) => {
  //   setError(true);
  //   // setError(false)
  //   // setSubmitting(false)

  // };
  // const [NewUser, setNewUser] = useState([]);
  // useEffect(()=>{
  //  const setNewUser = fetchRegister(this.firstName,this.lastName,this.email,this.password,this.reTypePassword);

  // })
  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        userAgreement: false,
        privacyPolicy: false,
        terms: false,
        type: "INDIVIDUAL",
      }}
      // validationSchema={signupSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, getFieldMeta }) => (
        <Form className="form-container">
          <div className="row r-container">
            <div className="col-md-8 offset-md-2">
              <div className="login-register-page">
                <div className="welcome-text">
                  <h3 style={{ fontSize: "26px" }}>Hesap Oluştur</h3>
                  <span>
                    Zaten bir hesabın var mı?{" "}
                    <Link to="/job-seeker/login">Giriş Yap!</Link>
                  </span>
                </div>

                {/* <form method="post" id="register-account-form"> */}
                <div
                  className="input-with-icon-left"
                  title="Adınızı girin"
                  data-tippy-placement="bottom"
                >
                  <i className="icon-material-outline-account-circle"></i>
                  <Input
                    type="text"
                    className="input-text with-border"
                    name="first_name"
                    id="first_name"
                    placeholder="Adınız"
                  />
                </div>

                <div
                  className="input-with-icon-left"
                  title="Soyadınızı girin"
                  data-tippy-placement="bottom"
                >
                  <i className="icon-material-outline-account-circle"></i>
                  <Input
                    type="text"
                    className="input-text with-border"
                    name="last_name"
                    id="last_name"
                    placeholder="Soyadınız"
                  />
                </div>

                <div
                  className="input-with-icon-left"
                  title="Email adresinizi girin"
                  data-tippy-placement="bottom"
                >
                  <i className="icon-material-baseline-mail-outline"></i>
                  <Input
                    type="text"
                    className="input-text with-border"
                    name="email"
                    id="email"
                    placeholder="Email adresiniz"
                  />
                </div>

                <div
                  className="input-with-icon-left"
                  title="Şifreniz en az 8 karakter içermelidir"
                  data-tippy-placement="bottom"
                >
                  <i className="icon-material-outline-lock"></i>
                  <Input
                    type="password"
                    className="input-text with-border"
                    name="password"
                    id="password"
                    placeholder="Şifreniz"
                  />
                </div>

                <div
                  className="input-with-icon-left"
                  title="Şifrenizi tekrar girin"
                  data-tippy-placement="bottom"
                >
                  <i className="icon-material-outline-lock"></i>
                  <Input
                    type="password"
                    className="input-text with-border"
                    name="confirm_password"
                    id="confirm_password"
                    placeholder="Şifrenizi tekrar girin"
                  />
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="switches-list">
                      <div className="switch-container">
                        {/* <Switch
                          name="userAgreement"
                          onChange={(e) =>
                            setFieldValue("userAgreement", e.target.checked)
                          }
                          checked={
                            getFieldMeta("userAgreement").value as boolean
                          }
                          label="ParttimeJET kullanıcı sözleşmesini onaylıyorum"
                        /> */}

                        <Switch
                          name="userAgreement"
                          value="userAgreement"
                          onChange={(e) =>
                            setFieldValue("userAgreement", e.target.checked)
                          }
                          checked={
                            getFieldMeta("userAgreement").value as boolean
                          }
                          label="ParttimeJET kullanıcı sözleşmesini onaylıyorum"
                        />
                        {getFieldMeta("userAgreement").touched &&
                          getFieldMeta("userAgreement").error && (
                            <p className="switch-error">
                              {getFieldMeta("userAgreement").error}
                            </p>
                          )}
                      </div>
                      <div className="switch-container">
                        {/* <Switch
                          name="privacyPolicy"
                          onChange={(e) =>
                            setFieldValue("privacyPolicy", e.target.checked)
                          }
                          checked={
                            getFieldMeta("privacyPolicy").value as boolean
                          }
                          label="ParttimeJET kullanıcı sözleşmesini onaylıyorum"
                        /> */}

                        <Switch
                          name="privacyPolicy"
                          value="privacyPolicy"
                          onChange={(e) =>
                            setFieldValue("privacyPolicy", e.target.checked)
                          }
                          checked={
                            getFieldMeta("privacyPolicy").value as boolean
                          }
                          label="ParttimeJET gizlilik politikasını onaylıyorum"
                        />
                        {getFieldMeta("privacyPolicy").touched &&
                          getFieldMeta("privacyPolicy").error && (
                            <p className="switch-error">
                              {getFieldMeta("privacyPolicy").error}
                            </p>
                          )}
                      </div>
                      <div className="switch-container">
                        {/* <Switch
                          name="terms"
                          onChange={(e) =>
                            setFieldValue("terms", e.target.checked)
                          }
                          checked={getFieldMeta("terms").value as boolean}
                          label="ParttimeJET kullanıcı sözleşmesini onaylıyorum"
                        /> */}

                        <Switch
                          name="terms"
                          value="terms"
                          onChange={(e) =>
                            setFieldValue("terms", e.target.checked)
                          }
                          checked={getFieldMeta("terms").value as boolean}
                          label="çık rıza metni kapsamında kişisel verilerimin işlenmesini onaylıyorum"
                        />
                        {getFieldMeta("terms").touched &&
                          getFieldMeta("terms").error && (
                            <p className="switch-error">
                              {getFieldMeta("terms").error}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* </form> */}

                <button
                  className="button full-width button-sliding-icon ripple-effect margin-top-10"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Üye Ol{" "}
                  <i className="icon-material-outline-arrow-right-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Register;
