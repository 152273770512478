interface FreelancerAboutMeProps {
  aboutMe: string;
}

const FreelancerAboutMe = ({ aboutMe }: FreelancerAboutMeProps) => {
  return (
    <>
      {/* <!-- Page Content --> */}
      <div className="single-page-section">
        <h3 className="margin-bottom-25">About Me</h3>
        <p>{aboutMe}</p>
      </div>
    </>
  );
};

export default FreelancerAboutMe;
