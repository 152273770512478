import { useEffect, useRef, useState } from "react";
import userAvatar from "../images/user-avatar-small-01.jpg";
import userAvatar02 from "../images/user-avatar-small-02.jpg";
import { Link } from "react-router-dom";
import { fetchMessagesHistory, fetchNotifications } from "@/api/account";

const EmailDropdown = () => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showEmailDropdown, setShowEmailDropdown] = useState(false);
  const [messages, setMessages] = useState([]);


  useEffect(() => {
    const handleLoadNotifications = async () => {
      const messagesResponse = await fetchMessagesHistory();
      setMessages(messagesResponse.data);
    };
    handleLoadNotifications();
  }
  , []);



  const handleEmailToggle = () => {
    setShowEmailDropdown((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.target as Node)
    ) {
      setShowEmailDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="header-notifications">
      <div
        className="header-notifications-trigger"
        onClick={handleEmailToggle}
        ref={triggerRef} // Ref for the trigger
      >
        

        <a href="#">
          <i data-feather="mail"></i>
          {messages.length > 0 && (
          <span>{messages.length}</span> )}
        </a>
       
      </div>

      {showEmailDropdown && (
        <div
          className="header-notifications-dropdown active"
          ref={dropdownRef} // Ref for the dropdown content
        >
          <div className="header-notifications-headline">
            <h4>Mesajlar</h4>
            <button
              className="mark-as-read ripple-effect-dark"
              title="Tüm Mesajları Okundu Say"
              data-tippy-placement="left"
            >
              <i className="icon-feather-check-square"></i>
            </button>
          </div>

          <div className="header-notifications-content">
            <div className="header-notifications-scroll" data-simplebar>
              <ul>
              {messages.map((message:any) => (
                <li className="notifications-not-read">
                  <Link
                    to="/employer/company-info/messages"
                    onClick={() => setShowEmailDropdown(false)}
                  >
                    <span className="notification-avatar">
                      <img src={userAvatar} alt="" />
                    </span>
                    <div className="notification-text">
                      <strong>{message?.latest_message?.sender.first_name} </strong>
                      <p className="notification-msg-text">
                        {message?.latest_message?.message}
                      </p>
                      <span className="color">{message.date}</span>
                    </div>
                  </Link>
                </li>
              ))}


              </ul>
            </div>
          </div>

          <Link
            to="/employer/company-info/messages"
            onClick={() => setShowEmailDropdown(false)}
            className="header-notifications-button ripple-effect button-sliding-icon"
          >
            Tüm Mesajlara Bak
            <i className="icon-material-outline-arrow-right-alt"></i>
          </Link>
        </div>
      )}
    </div>
  );
};

export default EmailDropdown;
