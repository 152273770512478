import React, { createContext, useState, useContext, ReactNode } from "react";

// interface ModalContextType {
//   showModal: (id: string) => void;
//   hideModal: (id: string) => void;
//   isModalVisible: (id: string) => boolean;

// }
interface ModalContextType {
  showModal: (
    id: string,
    component?: React.ComponentType | React.ReactNode
  ) => void;
  hideModal: (id: string) => void;
  isModalVisible: (id: string) => boolean;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [modals, setModals] = useState<Record<string, boolean>>({});

  const showModal = (id: string) => {
    setModals((prevModals) => ({ ...prevModals, [id]: true }));
  };

  const hideModal = (id: string) => {
    setModals((prevModals) => ({ ...prevModals, [id]: false }));
  };

  const isModalVisible = (id: string) => !!modals[id];

  return (
    <ModalContext.Provider value={{ showModal, hideModal, isModalVisible }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
