import { fetchBankDetails, updateBankDetails, updatePassword } from "@/api/account";
import { useModal } from "@/contexts/ModalContext";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const Settings = () => {
  const { showModal } = useModal();
  const [bankDetails, setBankDetails] = useState({
    holder_name: "",
    iban: "",
  });

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  

  useEffect(() => {
    fetchBankDetails().then((response) => {
      if(response.data)
        setBankDetails(response.data);
    });
  }, []);

  const handleBankDetailSubmit = async (e: any) => {
    e.preventDefault();
    const response = await updateBankDetails(bankDetails.holder_name, bankDetails.iban);
    if(response.status === '0')
      toast.success('Banka detayları başarıyla güncellendi');
    else
      toast.error('Banka detayları güncellenirken bir hata oluştu');
  }

  const handlePasswordSubmit = async () => {
    if(newPassword !== newPasswordAgain){
      toast.error('Şifreler eşleşmiyor');
      return;
    }
    
    const response = await updatePassword(oldPassword, newPassword);
    if(response.status === '0')
      toast.success('Şifreniz başarıyla güncellendi');
    else
      toast.error('Şifreniz güncellenirken bir hata oluştu');
  }


  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="col-md-12 dashboard-box margin-top-0">
            <div className="headline">
              <h3>
                <i className="icon-line-awesome-key"></i> Şifreni Değiştir
              </h3>
            </div>
            <div className="content with-padding padding-bottom-10">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="submit-field">
                        <h5>Mevcut Şifre</h5>
                        <div
                          className="input-with-icon-left"
                          data-tippy-placement="bottom"
                          data-tippy=""
                          data-original-title="Şifrenizi girin"
                        >
                          <i className="icon-material-outline-lock"></i>
                          <input
                            onChange={(e) => setOldPassword(e.target.value)}
                            type="password"
                            className="input-text with-border"
                            name="password"
                            id="password"
                            placeholder="Şifreniz"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="submit-field">
                        <h5>Yeni Şifre</h5>
                        <div
                          className="input-with-icon-left"
                          data-tippy-placement="bottom"
                          data-tippy=""
                          data-original-title="Şifrenizi girin"
                        >
                          <i className="icon-material-outline-lock"></i>
                          <input
                            onChange={(e) => setNewPassword(e.target.value)}
                            type="password"
                            className="input-text with-border"
                            name="password"
                            id="password"
                            placeholder="Yeni Şifre"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="submit-field">
                        <h5>Yeni Şifreni Tekrarla</h5>
                        <div
                          className="input-with-icon-left"
                          data-tippy-placement="bottom"
                          data-tippy=""
                          data-original-title="Şifrenizi girin"
                        >
                          <i className="icon-material-outline-lock"></i>
                          <input
                            onChange={(e) => setNewPasswordAgain(e.target.value)}
                            type="password"
                            className="input-text with-border"
                            name="password"
                            id="password"
                            placeholder="Yeni Şifre"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <button
                className="button full-width button-sliding-icon ripple-effect margin-top-30"
                type="submit"
                onClick={() => handlePasswordSubmit()}
              >
                Güncelle{" "}
                <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
            <br />
          </div>
          <div className="col-md-12  dashboard-box margin-top-30">
            <div className="headline">
              <h3>
                <i className="icon-line-awesome-bank"></i> Banka Hesabını
                Tanımla
              </h3>
              <div className="bank-account-line-d">
                <ul>
                  <li className="bank-account-line-d">
                    Hak ettiğin tüm ücretler tanımladığın banka hesabına
                    aktarılacak
                  </li>
                </ul>
              </div>
            </div>

            <div className="content">
              <ul className="fields-ul">
                <li>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="submit-field">
                        <h5>Adınız Soyadınız</h5>
                        <div className="input-with-icon-left">
                          <i className="icon-material-outline-account-circle"></i>
                          <input
                            value={bankDetails.holder_name}
                            onChange={(e) =>
                              setBankDetails({
                                ...bankDetails,
                                holder_name: e.target.value,
                              })
                            }
                            type="text"
                            className="input-text with-border"
                            placeholder="Adınız Soyadınız"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="submit-field">
                        <h5>IBAN</h5>
                        <div className="input-with-icon-left">
                          <i className="icon-line-awesome-bank"></i>
                          <input
                            onChange={(e) => setBankDetails({
                              ...bankDetails,
                              iban: e.target.value,
                            })}
                            value={bankDetails.iban}
                            type="text"
                            className="input-text with-border"
                            placeholder="IBAN"
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <button
                className="button full-width button-sliding-icon ripple-effect margin-top-30"
                type="submit"
                onClick={handleBankDetailSubmit}
              >
                Bilgilerimi Güncelle{" "}
                <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
            <br />
          </div>

          <div className="col-md-12  dashboard-box margin-top-30">
            <div className="headline">
              <h3>
                <i className="icon-material-outline-notifications-active"></i>{" "}
                Bildirim Ayarları
              </h3>
              <div className="bank-account-line-d">
                <ul>
                  <li className="bank-account-line-d">
                    Tüm bildirimlerini buradan yönet
                  </li>
                </ul>
              </div>
            </div>

            <div className="content">
              <ul className="fields-ul">
                <li>
                  <div className="row margin-bottom-10">
                    <div className="col-md-3">
                      <div className="countdown green">
                        <div
                          className="switches-list"
                          id="snackbar-user-status-s"
                        >
                          <div className="switch-container">
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="switch-button"></span> Sistem
                              Bildirimleri
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="countdown green">
                        <div
                          className="switches-list"
                          id="snackbar-user-status-s"
                        >
                          <div className="switch-container">
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="switch-button"></span> Çalışma
                              Bildirimleri
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="countdown green">
                        <div
                          className="switches-list"
                          id="snackbar-user-status-s"
                        >
                          <div className="switch-container">
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="switch-button"></span>{" "}
                              Bilgilendirmeler
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="countdown green">
                        <div
                          className="switches-list"
                          id="snackbar-user-status-s"
                        >
                          <div className="switch-container">
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="switch-button"></span> PR ve
                              Tanıtım Fırsatları
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <button
                className="button full-width button-sliding-icon ripple-effect margin-top-30"
                type="submit"
              >
                Bilgilerimi Güncelle{" "}
                <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
            <br />
          </div>

          <div className="col-md-12 dashboard-box margin-top-30">
            <div className="headline">
              <h3>
                <i className="icon-line-awesome-trash"></i> Hesabını Sil
              </h3>
            </div>
            <div className="content with-padding padding-bottom-10">
              <div className="row">
                <div className="col-md-12">
                  <div className="submit-field">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="submit-field">
                          <div className="notification error">
                            <p>
                              Hesabını silmek tüm profil bilgilerinin kalıcı
                              olarak silinmesi anlamına gelir. Sorunları bizimle
                              paylaşarak çözmemiz için fırsat tanıyabilirsin
                              veya hesabını kalıcı olarak silebilirsin.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="submit-field">
                          <a
                            onClick={() => showModal("feedback-modal")}
                            className="popup-with-zoom-anim button ripple-effect small green text-white me-2"
                          >
                            <i className="icon-material-outline-feedback"></i>{" "}
                            Geri Bildirim Gönder
                          </a>
                          <a
                            onClick={() => showModal("delete-account-modal")}
                            className="popup-with-zoom-anim button ripple-effect small red text-white"
                          >
                            <i className="icon-line-awesome-trash"></i> Hesabımı
                            Sil
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="small-dialog"
        className="zoom-anim-dialog mfp-hide dialog-with-tabs"
      >
        <div className="sign-in-form">
          <ul className="popup-tabs-nav">
            <li>
              <a href="#tab">Geri Bildirim Gönder</a>
            </li>
          </ul>

          <div className="popup-tabs-container">
            <div className="popup-tab-content" id="tab">
              <div className="welcome-text">
                <h3>Sorunu bildir ve çözmemiz için fırsat ver</h3>
              </div>

              <form method="post" id="send-pm">
                <div className="col">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input id="radio-1" name="radio" type="radio" />
                          <label htmlFor="radio-1">
                            <span className="radio-label"></span> Aradığım işi
                            veya iş gücünü bulmama yardımcı olmadı
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input id="radio-2" name="radio" type="radio" />
                          <label htmlFor="radio-2">
                            <span className="radio-label"></span> Uygulama yavaş
                            veya doğru çalışmıyan bir şeyler var
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input id="radio-3" name="radio" type="radio" />
                          <label htmlFor="radio-3">
                            <span className="radio-label"></span> Verilerimin
                            güvenliğinden endişe ediyorum
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input id="radio-4" name="radio" type="radio" />
                          <label htmlFor="radio-4">
                            <span className="radio-label"></span> Yeterli
                            müşteri desteğini alamadım
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input id="radio-5" name="radio" type="radio" />
                          <label htmlFor="radio-5">
                            <span className="radio-label"></span> Çok fazla veya
                            ilgisiz bildirim aldım
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input id="radio-6" name="radio" type="radio" />
                          <label htmlFor="radio-6">
                            <span className="radio-label"></span> Diğer
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="submit-field">
                        <textarea
                          name="textarea"
                          cols={10}
                          placeholder="Ayrılma nedenini açıklamak ister misin?"
                          className="with-border"
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <button
                className="button full-width button-sliding-icon ripple-effect"
                type="submit"
                form="send-pm"
              >
                Gönder <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="small-dialog-2"
        className="zoom-anim-dialog mfp-hide dialog-with-tabs"
      >
        <div className="sign-in-form">
          <ul className="popup-tabs-nav">
            <li>
              <a href="#tab">Hesabımı Sil</a>
            </li>
          </ul>

          <div className="popup-tabs-container">
            <div className="popup-tab-content" id="tab">
              <div className="welcome-text">
                <h3>
                  Ayrıldığın için üzgünüz. Daha iyi bir deneyim sağlamak için
                  her gün çalışıyoruz. Umarız bir gün yeniden buluşabiliriz.
                </h3>
              </div>

              <form method="post" id="send-pm">
                <div className="col">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input id="radio-1" name="radio" type="radio" />
                          <label htmlFor="radio-1">
                            <span className="radio-label"></span> Aradığım işi
                            veya iş gücünü bulmama yardımcı olmadı
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input id="radio-2" name="radio" type="radio" />
                          <label htmlFor="radio-2">
                            <span className="radio-label"></span> Uygulama yavaş
                            veya doğru çalışmıyan bir şeyler var
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input id="radio-3" name="radio" type="radio" />
                          <label htmlFor="radio-3">
                            <span className="radio-label"></span> Verilerimin
                            güvenliğinden endişe ediyorum
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input id="radio-4" name="radio" type="radio" />
                          <label htmlFor="radio-4">
                            <span className="radio-label"></span> Yeterli
                            müşteri desteğini alamadım
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input id="radio-5" name="radio" type="radio" />
                          <label htmlFor="radio-5">
                            <span className="radio-label"></span> Çok fazla veya
                            ilgisiz bildirim aldım
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input id="radio-6" name="radio" type="radio" />
                          <label htmlFor="radio-6">
                            <span className="radio-label"></span> Diğer
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="submit-field">
                        <textarea
                          name="textarea"
                          cols={10}
                          placeholder="Ayrılma nedenini açıklamak ister misin?"
                          className="with-border"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div id="delete-account">
                <button
                  className="button full-width button-sliding-icon ripple-effect"
                  type="submit"
                  form="send-pm"
                >
                  Hesabımı Sil{" "}
                  <i className="icon-material-outline-arrow-right-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
