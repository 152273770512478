import * as Yup from 'yup'

const signupSchema = Yup.object({
  first_name: Yup.string()
    .min(3, 'Must be at least 3 characters')
    .max(20, 'Must be less than 20 characters')
    .required('First Name is required')
    .trim()
    .matches(
      /^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$/,
      'Cannot contain special characters'
    ),
  last_name: Yup.string()
    .min(3, 'Must be at least 3 characters')
    .max(20, 'Must be less than 20 characters')
    .required('Last Name is required')
    .trim()
    .matches(
      /^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$/,
      'Cannot contain special characters'
    ),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .required('Password is required'),
    confirm_password: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
    company_name: Yup.string()
    .min(3, 'Must be at least 3 characters')
    .max(20, 'Must be less than 20 characters')
    .required('Company Name is required')
    .trim()
    .matches(
      /^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$/,
      'Cannot contain special characters'
    ),
  userAgreement: Yup.boolean()
    .oneOf([true], 'The user agreement must be accepted.')
    .required('The user agreement must be accepted.'),
  privacyPolicy: Yup.boolean()
    .oneOf([true], 'The privacy policy must be accepted.')
    .required('The privacy policy must be accepted.'),
  terms: Yup.boolean()
    .oneOf([true], 'The processing of personal data must be approved.')
    .required('The processing of personal data must be approved.')
})

export { signupSchema }
