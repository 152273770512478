import { configureStore } from '@reduxjs/toolkit'
import user, { setUser, IUserState, clearUser } from './user'

const store = configureStore({
    reducer: { user },
});
interface RootState {
    user: IUserState
}

export { setUser, clearUser }
export default store;
export type { RootState };