import { useModal } from '@/contexts/ModalContext'
import React, { useState } from 'react'

const FeedbackModal = () => {
  const { hideModal } = useModal()
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value)
  }
    return (
      <div className="modal-container">
        <div
          id="small-dialog"
          className="zoom-anim-dialog dialog-with-tabs modal-content"
        >
          {/* Tabs */}
          <div className="sign-in-form">
            <ul className="popup-tabs-nav">
              <li className="active">
                <a href="#">Geri Bildirim Gönder</a>
              </li>
            </ul>
            <div className="popup-tabs-container">
              {/* Tab */}
              <div className="popup-tab-content" id="tab">
                {/* Welcome Text */}
                <div className="welcome-text">
                  <h3>Sorunu bildir ve çözmemiz için fırsat ver</h3>
                </div>
                {/* Form */}
                <form method="post" id="send-pm">
                  <div className="col">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="submit-field">
                          <div className="radio">
                            <input
                              id="radio-1"
                              name="feedback"
                              type="radio"
                              value="option1"
                              checked={selectedOption === 'option1'}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="radio-1">
                              <span className="radio-label"></span> Aradığım işi
                              veya iş gücünü bulmama yardımcı olmadı
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="submit-field">
                          <div className="radio">
                            <input
                              id="radio-2"
                              name="feedback"
                              type="radio"
                              value="option2"
                              checked={selectedOption === 'option2'}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="radio-2">
                              <span className="radio-label"></span> Uygulama
                              yavaş veya doğru çalışmıyan bir şeyler var
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="submit-field">
                          <div className="radio">
                            <input
                              id="radio-3"
                              name="feedback"
                              type="radio"
                              value="option3"
                              checked={selectedOption === 'option3'}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="radio-3">
                              <span className="radio-label"></span> Verilerimin
                              güvenliğinden endişe ediyorum
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="submit-field">
                          <div className="radio">
                            <input
                              id="radio-4"
                              name="feedback"
                              type="radio"
                              value="option4"
                              checked={selectedOption === 'option4'}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="radio-4">
                              <span className="radio-label"></span> Yeterli
                              müşteri desteğini alamadım
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="submit-field">
                          <div className="radio">
                            <input
                              id="radio-5"
                              name="feedback"
                              type="radio"
                              value="option5"
                              checked={selectedOption === 'option5'}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="radio-5">
                              <span className="radio-label"></span> Çok fazla
                              veya ilgisiz bildirim aldım
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="submit-field">
                          <div className="radio">
                            <input
                              id="radio-6"
                              name="feedback"
                              type="radio"
                              value="option6"
                              checked={selectedOption === 'option6'}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="radio-6">
                              <span className="radio-label"></span> Diğer
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="submit-field">
                          <textarea
                            name="textarea"
                            cols={10}
                            placeholder="Ayrılma nedenini açıklamak ister misin?"
                            className="with-border"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                {/* Button */}
                <button
                  className="button full-width button-sliding-icon ripple-effect"
                  type="submit"
                  form="send-pm"
                >
                  Gönder{' '}
                  <i className="icon-material-outline-arrow-right-alt"></i>
                </button>
              </div>
            </div>
          </div>
          <button
            title="Close (Esc)"
            type="button"
            className="mfp-close"
            onClick={() => hideModal('feedback-modal')}
          ></button>
        </div>
      </div>
    )
}

export default FeedbackModal
