import Dropdown from '@/components/Dropdown'
import {
  CITY_OPTIONS,
  COMPANY_OPTIONS,
  COUNTRY_OPTIONS,
  DIPLOMA_OPTIONS,
  EDUCATION_OPTIONS,
  EDUCATION_TYPE_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  INDUSTRY_OPTIONS,
  JOB_TITLES,
  LANGUAGE_OPTION,
  LANGUAGE_OPTIONS,
  POSITION_LEVEL_OPTIONS,
  TAX_PROVINCES_OPTIONS,
  UNIVERSITY_OPTIONS
} from '@/libs/constants'
import React from 'react'

const MyCV = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="col-md-12 dashboard-box margin-top-0">
          {/* Headline */}
          <div className="headline">
            <h3>
              <i className="icon-line-awesome-comment-o"></i> Biyografi Ekleme
            </h3>
          </div>

          <div className="content">
            <ul className="fields-ul">
              <li>
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Kısa Biyografinizi Yazınız</h5>
                      <textarea
                        cols={30}
                        rows={5}
                        className="with-border"
                      ></textarea>
                      <div className="uploadButton margin-top-30">
                        <input
                          className="uploadButton-input"
                          type="file"
                          accept="image/*, application/pdf"
                          id="upload"
                          multiple
                        />
                        <label
                          className="uploadButton-button ripple-effect"
                          htmlFor="upload"
                        >
                          Tanıtım Videosu Yükle
                        </label>
                        <span className="uploadButton-file-name">
                          Biyografine bir tanıtım videosu ekleyerek kendini daha
                          iyi ifade edebilirsin.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-md-12 dashboard-box margin-top-30">
          <div className="headline">
            <h3>
              <i className="icon-material-outline-business-center"></i>{' '}
              Deneyimlerim
            </h3>
          </div>
          <div className="content with-padding padding-bottom-0">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <Dropdown
                        options={COMPANY_OPTIONS}
                        placeholder="Firma Adı"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Firma Adı"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <Dropdown
                        options={JOB_TITLES}
                        placeholder="Pozisyon"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Pozisyon"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <h5>Başlangıç Tarihi</h5>
                      <input
                        className="with-border"
                        id="birthDate"
                        name="profile-birthday"
                        type="profile-birthday"
                        placeholder="gg/aa/yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <h5>Bitiş Tarihi</h5>
                      <input
                        className="with-border"
                        id="birthDate"
                        name="profile-birthday"
                        type="profile-birthday"
                        placeholder="gg/aa/yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <Dropdown
                        options={COMPANY_OPTIONS}
                        placeholder="Firma Sektörü"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Firma Sektörü"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <Dropdown
                        options={INDUSTRY_OPTIONS}
                        placeholder="İş Alanı"
                        onSelect={() => {}}
                        className="mb-3"
                        label="İş Alanı"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <Dropdown
                        options={EMPLOYMENT_TYPE_OPTIONS}
                        placeholder="Çalışma Şekli"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Çalışma Şekli"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <Dropdown
                        options={POSITION_LEVEL_OPTIONS}
                        placeholder="Pozisyon Seviyesi"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Pozisyon Seviyesi"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <Dropdown
                        options={COUNTRY_OPTIONS}
                        placeholder="Ülke"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Ülke"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <Dropdown
                        options={CITY_OPTIONS}
                        placeholder="Şehir"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Şehir"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>İş Tanımı</h5>
                      <textarea
                        cols={30}
                        rows={19}
                        className="with-border"
                        placeholder="Tecrübelerini eklerken, iş tanımınla ilgili detaylar vermen, şirketlerin seni yakından tanımasını sağlar ve mülakata davet edilme ihtimalini arttırır."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 dashboard-box margin-top-30">
          <div className="headline">
            <h3>
              <i className="icon-line-awesome-book"></i> Eğitim Bilgilerim
            </h3>
          </div>
          <div className="content with-padding padding-bottom-0">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <div className="submit-field">
                      <Dropdown
                        options={EDUCATION_OPTIONS}
                        placeholder="Eğitim Durumu"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Eğitim Durumu"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <h5>Hala Öğrenci Misin?</h5>
                      <div className="switches-list">
                        <div className="switch-container margin-top-8">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="switch-button"></span> Hala
                            Öğrenciyim
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <h5>Başlangıç Tarihi</h5>
                      <input
                        className="with-border"
                        id="birthDate"
                        name="profile-birthday"
                        type="profile-birthday"
                        placeholder="gg/aa/yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <h5>Bitiş Tarihi</h5>
                      <input
                        className="with-border"
                        id="birthDate"
                        name="profile-birthday"
                        type="profile-birthday"
                        placeholder="gg/aa/yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <Dropdown
                        options={DIPLOMA_OPTIONS}
                        placeholder="Diploma Not Sistemi"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Diploma Not Sistemi"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <h5>Diploma Notu</h5>
                      <input
                        className="with-border"
                        id="birthDate"
                        name="profile-birthday"
                        type="profile-birthday"
                        placeholder="Diploma Notu"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <Dropdown
                        options={UNIVERSITY_OPTIONS}
                        placeholder="Üniversite"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Üniversite"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <Dropdown
                        options={TAX_PROVINCES_OPTIONS}
                        placeholder="Şehir"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Şehir"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <Dropdown
                        options={EDUCATION_TYPE_OPTIONS}
                        placeholder="Öğrenim Tipi"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Öğrenim Tipi"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <Dropdown
                        options={LANGUAGE_OPTION}
                        placeholder="Öğretim Dili"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Öğretim Dili"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Açıklama</h5>
                      <textarea
                        cols={30}
                        rows={5}
                        className="with-border"
                        placeholder="Eğitim bilgilerini eksiksiz ve kronolojik olarak belirtmelisin. Varsa derecelerini de “Ek bilgiler” alanına ekleyebilirsin."
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <div className="uploadButton margin-top-10">
                        <input
                          className="uploadButton-input"
                          type="file"
                          accept="image/*, application/pdf"
                          id="upload"
                          multiple
                        />
                        <label
                          className="uploadButton-button ripple-effect"
                          htmlFor="upload"
                        >
                          Mezuniyet Belgeni Yükle
                        </label>
                        <span className="uploadButton-file-name">
                          Mezuniyet belgeni yükleyerek eğitim durumunu
                          onaylatabilirsin.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 dashboard-box margin-top-30">
          {/* Dil Bilgileri */}
          <div className="headline">
            <h3>
              <i className="icon-line-awesome-book"></i>
              Foreign languages
            </h3>
          </div>
          <div className="content with-padding padding-bottom-0">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <div className="submit-field">
                      <Dropdown
                        options={LANGUAGE_OPTIONS}
                        placeholder="Şehir"
                        onSelect={() => {}}
                        className="mb-3"
                        label="Şehir"
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="submit-field">
                      <h5>Read</h5>
                      <div className="feedback-yes-no">
                        <div className="leave-rating">
                          <input
                            type="radio"
                            name="rating"
                            id="rating-radio-1"
                            value="1"
                            required
                          />
                          <label
                            htmlFor="rating-radio-1"
                            className="icon-material-outline-star"
                          ></label>
                          <input
                            type="radio"
                            name="rating"
                            id="rating-radio-2"
                            value="2"
                            required
                          />
                          <label
                            htmlFor="rating-radio-2"
                            className="icon-material-outline-star"
                          ></label>
                          <input
                            type="radio"
                            name="rating"
                            id="rating-radio-3"
                            value="3"
                            required
                          />
                          <label
                            htmlFor="rating-radio-3"
                            className="icon-material-outline-star"
                          ></label>
                          <input
                            type="radio"
                            name="rating"
                            id="rating-radio-4"
                            value="4"
                            required
                          />
                          <label
                            htmlFor="rating-radio-4"
                            className="icon-material-outline-star"
                          ></label>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="submit-field">
                      <h5>Don't write</h5>
                      <div className="feedback-yes-no">
                        <div className="leave-rating">
                          <input
                            type="radio"
                            name="rating"
                            id="rating-radio-1"
                            value="1"
                            required
                          />
                          <label
                            htmlFor="rating-radio-1"
                            className="icon-material-outline-star"
                          ></label>
                          <input
                            type="radio"
                            name="rating"
                            id="rating-radio-2"
                            value="2"
                            required
                          />
                          <label
                            htmlFor="rating-radio-2"
                            className="icon-material-outline-star"
                          ></label>
                          <input
                            type="radio"
                            name="rating"
                            id="rating-radio-3"
                            value="3"
                            required
                          />
                          <label
                            htmlFor="rating-radio-3"
                            className="icon-material-outline-star"
                          ></label>
                          <input
                            type="radio"
                            name="rating"
                            id="rating-radio-4"
                            value="4"
                            required
                          />
                          <label
                            htmlFor="rating-radio-4"
                            className="icon-material-outline-star"
                          ></label>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="submit-field">
                      <h5>Speech</h5>
                      <div className="feedback-yes-no">
                        <div className="leave-rating">
                          <input
                            type="radio"
                            name="rating"
                            id="rating-radio-1"
                            value="1"
                            required
                          />
                          <label
                            htmlFor="rating-radio-1"
                            className="icon-material-outline-star"
                          ></label>
                          <input
                            type="radio"
                            name="rating"
                            id="rating-radio-2"
                            value="2"
                            required
                          />
                          <label
                            htmlFor="rating-radio-2"
                            className="icon-material-outline-star"
                          ></label>
                          <input
                            type="radio"
                            name="rating"
                            id="rating-radio-3"
                            value="3"
                            required
                          />
                          <label
                            htmlFor="rating-radio-3"
                            className="icon-material-outline-star"
                          ></label>
                          <input
                            type="radio"
                            name="rating"
                            id="rating-radio-4"
                            value="4"
                            required
                          />
                          <label
                            htmlFor="rating-radio-4"
                            className="icon-material-outline-star"
                          ></label>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="submit-field">
                      <h5>Is it your mother tongue?</h5>
                      <div className="switches-list">
                        <div className="switch-container margin-top-8">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="switch-button"></span>
                            Native language
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 margin-bottom-10">
                    <a href="#" className="button ripple-effect small">
                      <i className="icon-feather-plus"></i> Add Another Language
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 dashboard-box margin-top-30">
          {/* Yetenek Bilgileri */}
          <div className="headline">
            <h3>
              <i className="icon-line-awesome-book"></i> Yetkinlikler &amp;
              Uzmanlık Alanları
            </h3>
          </div>
          <div className="content with-padding padding-bottom-0">
            <div className="row">
              <div className="col-md-12">
                <div className="submit-field">
                  <h5>
                    Yetkinlikler &amp; Uzmanlık Alanları
                    <i
                      className="help-icon"
                      data-tippy-placement="right"
                      data-tippy=""
                      data-original-title="Eklemek istediğiniz yetenek ve uzmanlık alanınızı seçin"
                    ></i>
                  </h5>
                  {/* Skills List */}
                  <div className="keywords-container">
                    <div className="keyword-input-container">
                      <input
                        type="text"
                        className="keyword-input with-border"
                        placeholder="Ör: Angular, Laravel"
                      />
                      <button className="keyword-input-button ripple-effect">
                        <i className="icon-material-outline-add"></i>
                      </button>
                    </div>
                    <div className="keywords-list" style={{ height: 'auto' }}>
                      <span className="keyword">
                        <span className="keyword-remove"></span>
                        <span className="keyword-text">Angular</span>
                      </span>
                      <span className="keyword">
                        <span className="keyword-remove"></span>
                        <span className="keyword-text">Vue JS</span>
                      </span>
                      <span className="keyword">
                        <span className="keyword-remove"></span>
                        <span className="keyword-text">iOS</span>
                      </span>
                      <span className="keyword">
                        <span className="keyword-remove"></span>
                        <span className="keyword-text">Android</span>
                      </span>
                      <span className="keyword">
                        <span className="keyword-remove"></span>
                        <span className="keyword-text">Laravel</span>
                      </span>
                      <span className="keyword">
                        <span className="keyword-remove"></span>
                        <span className="keyword-text">PS</span>
                      </span>
                      <span className="keyword">
                        <span className="keyword-remove"></span>
                        <span className="keyword-text">JS</span>
                      </span>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <button
          className="button full-width button-sliding-icon ripple-effect margin-top-30"
          type="submit"
          form="login-form"
          style={{ width: '344.5px' }}
        >
          Bilgilerimi Güncelle{' '}
          <i className="icon-material-outline-arrow-right-alt"></i>
        </button>
      </div>
    </div>
  )
}

export default MyCV
