import profileImg from "@/images/user-avatar-placeholder.png";
import loopArrowImg from "@/images/icons/loop-arrow.png";
import Dropdown from "@/components/Dropdown";
import {
  COUNTRIES_OPTIONS,
  COUNTRY_CODE_OPTIONS,
  DISTRICT_OPTIONS,
  GENDER_OPTIONS,
} from "@/libs/constants";
import DropdownWithSearch from "@/components/DropdownWithSearch";
import Textarea from "@/components/TextArea";
import { useState } from "react";
import Input from "@/components/Input";
import { Formik, Form } from "formik";

const MyInformation = () => {
  const [profileImage, setProfileImage] = useState<string>("");

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    console.log("submit");
  };

  const handleSelect = (value: string) => {
    console.log('Selected:', value)
  }


  return (
    <Formik
      initialValues={{
        identificationNumber: null,
        firstName: "",
        lastName: "",
        dob: "",
        gender: "",
        phoneNumber: "",
        email: "",
        city: "",
        province: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, getFieldMeta }) => (
        <Form>
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-12 dashboard-box margin-top-0">
                <div className="headline">
                  <h3>
                    <i className="icon-material-outline-account-circle"></i>
                    Kimlik Bilgilerim
                  </h3>
                </div>
                <div className="content with-padding padding-bottom-0">
                  <div className="row">
                    <div className="col-auto">
                      <div
                        className="avatar-wrapper"
                        data-tippy-placement="bottom"
                        data-tippy=""
                        data-original-title="Profil Fotoğrafını Değiştir"
                      >
                        <img
                          className="profile-pic"
                          src={profileImage || profileImg}
                          alt=""
                        />

                        <button
                          data-v-profile-picture=""
                          className="profile-photo"
                        >
                          <img
                            data-v-76dbb04c=""
                            src={loopArrowImg}
                            alt=""
                            className="pp-edit"
                          />
                        </button>
                        <div className="upload-button">
                          <Input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            placeholder="click me"
                            className='position-absolute top-0 bottom-0'
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="submit-field">
                            <Input
                              id="firstName"
                              name="firstName"
                              type="text"
                              placeholder="Ahmet"
                              label="İsim"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="submit-field">
                            <Input
                              id="lastName"
                              name="lastName"
                              type="text"
                              placeholder="TANCI"
                              label="Soyisim"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="submit-field">
                            <Input
                              id="identificationNumber"
                              name="identificationNumber"
                              type="number"
                              placeholder="12345678901"
                              label="TC Kimlik No"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="submit-field">
                            <Input
                              id="dob"
                              name="dob"
                              type="text"
                              placeholder="gg/aa/yyyy"
                              label="Doğum Tarihi"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="submit-field">
                            <Dropdown
                              options={GENDER_OPTIONS}
                              placeholder="Cinsiyet seçin"
                              onSelect={handleSelect}
                              className="mb-3"
                              label="Cinsiyet"
                            />
                            {getFieldMeta('gender').touched &&
                              getFieldMeta('gender').error && (
                                <p className="text-sm text-danger">
                                  {getFieldMeta('gender').error}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 dashboard-box margin-top-30">
                <div className="headline">
                  <h3>
                    <i className="icon-feather-phone-call"></i> İletişim
                    Bilgilerim
                  </h3>
                </div>
                <div className="content with-padding padding-bottom-10">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="submit-field">
                        <h5>Telefon Numaranız</h5>
                        <div className="row">
                          <div className="col-md-3">
                            <div className="submit-field">
                              <Dropdown
                                options={COUNTRY_CODE_OPTIONS}
                                placeholder="+90"
                                onSelect={() => {}}
                              />
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="submit-field">
                              <input
                                className="with-border"
                                id="telephone"
                                name="profile-telephone"
                                type="profile-telephone"
                                placeholder="(555) 555 55 55"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="submit-field">
                        <h5>E-posta Adresiniz</h5>
                        <div className="submit-field">
                          <input
                            className="with-border"
                            id="mail"
                            name="profile-mail"
                            type="profile-mail"
                            placeholder="mail@mail.com"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12  dashboard-box margin-top-30">
                <div className="headline">
                  <h3>
                    <i className="icon-material-outline-home"></i> Adres
                    Bilgilerim
                  </h3>
                </div>

                <div className="content">
                  <ul className="fields-ul">
                    <li>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="submit-field">
                            <h5>İl</h5>
                            <DropdownWithSearch
                              options={COUNTRIES_OPTIONS}
                              placeholder="İl seçin"
                              onSelect={() => {}}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="submit-field">
                            <h5>İlçe</h5>
                            <DropdownWithSearch
                              options={DISTRICT_OPTIONS}
                              placeholder="İl seçin"
                              onSelect={() => {}}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="submit-field">
                            <h5>Açık Adresinizi Yazınız</h5>
                            <Textarea />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <button
                className="button full-width button-sliding-icon ripple-effect margin-top-30"
                type="submit"
                form="login-form"
                style={{ width: "344.5px" }}
              >
                Bilgilerimi Güncelle{" "}
                <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MyInformation;
