import profileImg from "@/images/user-avatar-placeholder.png";
import loopArrowImg from "@/images/icons/loop-arrow.png";
import Input from "@/components/Input";
import Dropdown from "@/components/Dropdown";
import {
  COUNTRIES_OPTIONS,
  COUNTRY_CODE_OPTIONS,
  DISTRICT_OPTIONS,
  GENDER_OPTIONS,
} from "@/libs/constants";
import DropdownWithSearch from "@/components/DropdownWithSearch";
import { useEffect, useState } from "react";
import Textarea from "@/components/TextArea";
import { Formik, Form, FormikHelpers } from "formik";
import { userInformationSchema } from "@/libs/schemes/userInformation";
import { fetchAccountInfo, updateAccountInfo } from "@/api/account";
import { debug } from "console";
import { fetchProvince } from "@/api/public";
import { BarLoader, BounceLoader, ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

const UserInformation = () => {
  const [error, setError] = useState(false);
  const [profileImage, setProfileImage] = useState<string>("");
  const [accountInfo, setAccountInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    dob: "",
    gender: "",
  });

  const [isLoading, setIsLoading] = useState(true);

  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
    const fetchAccount = async () => {
      const response = await fetchAccountInfo();

      setAccountInfo(response.data);
    };
    const handleFetchProvinces = async () => {
      const response = await fetchProvince();
      setProvinces(response.data.provinces);
    };

    Promise.all([fetchAccount(), handleFetchProvinces()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSelect = (value: string) => {
    console.log("Selected:", value);
  };

  const handleSubmit = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    if(accountInfo.first_name !== values.firstName 
      || accountInfo.last_name !== values.lastName 
      || accountInfo.dob !== values.dob 
      || accountInfo.gender !== values.gender) {
        const response = await updateAccountInfo({
          first_name: values.firstName,
          last_name: values.lastName,
          dob: values.dob,
          gender: values.gender
        });
        if(response.status == 0) {
          toast.success('Bilgileriniz başarıyla güncellendi');
        } else {
          toast.error(response.message);
        }

    }

    console.log("values:", values);
    // Handle submission logic here
    // setError(false)
    // formikHelpers.setSubmitting(false)
  };

  return (
    <>
      {isLoading ? (
        <BarLoader
          loading={isLoading}
          aria-label="Loading Spinner"
          width={"100%"}
        />
      ) : (
        <Formik
          initialValues={{
            identificationNumber: null,
            firstName: accountInfo.first_name,
            lastName: accountInfo.last_name,
            dob: "",
            gender: "",
            phoneNumber: accountInfo.phone,
            email: accountInfo.email,
            city: "",
            province: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, getFieldMeta }) => (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <div className="col-md-12 dashboard-box margin-top-0">
                    <div className="headline">
                      <h3>
                        <i className="icon-material-outline-account-circle"></i>{" "}
                        Kimlik Bilgilerim
                      </h3>
                    </div>
                    <div className="content with-padding padding-bottom-0">
                      <div className="row">
                        <div className="col-auto">
                          <div
                            className="avatar-wrapper"
                            data-tippy-placement="bottom"
                            data-tippy=""
                            data-original-title="Profil Fotoğrafını Değiştir"
                          >
                            <img
                              className="profile-pic"
                              src={profileImage || profileImg}
                              alt=""
                            />

                            <button
                              data-v-profile-picture=""
                              className="profile-photo"
                            >
                              <img
                                data-v-76dbb04c=""
                                src={loopArrowImg}
                                alt=""
                                className="pp-edit"
                              />
                            </button>
                            <div className="upload-button">
                              <Input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                placeholder="click me"
                                className="position-absolute top-0 bottom-0"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="submit-field">
                                <Input
                                  id="firstName"
                                  name="firstName"
                                  type="text"
                                  placeholder="Ahmet"
                                  label="İsim"
                                  value={accountInfo.first_name}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="submit-field">
                                <Input
                                  id="lastName"
                                  name="lastName"
                                  type="text"
                                  placeholder="TANCI"
                                  label="Soyisim"
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="submit-field">
                                <Input
                                  id="dob"
                                  name="dob"
                                  type="text"
                                  placeholder="gg/aa/yyyy"
                                  label="Doğum Tarihi"
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="submit-field">
                                <Dropdown
                                  options={GENDER_OPTIONS}
                                  placeholder="Cinsiyet seçin"
                                  onSelect={handleSelect}
                                  className="mb-3"
                                  label="Cinsiyet"
                                />
                                
                              </div>
                            </div>
                          </div>
                          <div className="row">
                        <div className="col-md-6">
                          <div className="submit-field">
                            {/* <p className="label-Input">Cinsiyet</p> */}
                            <div className="row">
                             
                              <div className="col-md-9">
                                <div className="submit-field">
                                  <Input
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    type="number"
                                    placeholder="(555) 555 55 55"
                                    label="Telefon"
                                  />
                                </div>
                              </div>
                            </div>
                            <p className="notification-msg-text">
                              Teklifler bu numaraya ulaşacak
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="submit-field">
                            <Input
                              label="E-posta Adresiniz"
                              id="email"
                              name="email"
                              type="email"
                              placeholder="mail@mail.com"
                            />
                            <p className="notification-msg-text">
                              Teklifler bu e-posta adresine ulaşacak
                            </p>
                          </div>
                        </div>
                      </div>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-5">
                      <button
                        className="button full-width button-sliding-icon ripple-effect margin-top-30"
                        type="submit"
                      >
                        Bilgilerimi Güncelle{" "}
                        <i className="icon-material-outline-arrow-right-alt"></i>
                      </button>
                    </div>
                      </div>
                      
                      
                      <br />
                      
                    </div>
                  </div>
                 

                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default UserInformation;
