import { numberWithCommas } from "@/libs/math";
import React, { useState, useEffect } from "react";

interface CustomCounterProps {
  end: number;
  duration: number;
}

const CustomCounter: React.FC<CustomCounterProps> = ({ end, duration }) => {
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    const start = performance.now();

    const animate = (time: number) => {
      const progress = (time - start) / duration;
      if (progress < 1) {
        setCount(Math.min(Math.round(progress * end), end));
        requestAnimationFrame(animate);
      } else {
        setCount(end);
      }
    };

    requestAnimationFrame(animate);
  }, [end, duration]);

  return (
    <strong className="counter">{numberWithCommas(Math.floor(count))}</strong>
  );
};

export default CustomCounter;

// import { numberWithCommas } from "@/libs/math";
// import React, { useState, useEffect } from "react";

// interface CustomCounterProps {
//   end: number | string;
//   duration: number;
// }

// const CustomCounter: React.FC<CustomCounterProps> = ({ end, duration }) => {
//   const [count, setCount] = useState<number>(0);

//   useEffect(() => {
//     const start = performance.now();
//     const endValue = typeof end === "string" ? parseFloat(end) : end; // end'i number'a çevir

//     const animate = (time: number) => {
//       const progress = (time - start) / duration;
//       if (progress < 1) {
//         setCount(Math.min(Math.round(progress * endValue), endValue));
//         requestAnimationFrame(animate);
//       } else {
//         setCount(endValue);
//       }
//     };

//     requestAnimationFrame(animate);
//   }, [end, duration]);

//   return (
//     <strong className="counter">{numberWithCommas(Math.floor(count))}</strong>
//   );
// };

// export default CustomCounter;
