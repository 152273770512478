import React, { useEffect, useState } from "react";
import Dropdown from "@/components/Dropdown";
import Textarea from "@/components/TextArea";
import { TAX_DISTRICTS_OPTIONS, TAX_PROVINCES_OPTIONS } from "@/libs/constants";
import { fetchDistrict, fetchProvince } from "@/api/public";
import { fetchInvoiceInfo, updateCompanyInvoice, updateCompanyInvoiceFile } from "@/api/company";
import DropdownWithSearch from "@/components/DropdownWithSearch";
import { toast } from "react-toastify";
import { BarLoader } from "react-spinners";

const CompanyInvoice = () => {
  const [provinces, setProvinces] = useState<any[]>([]);
  const [districts, setDistricts] = useState<any[]>([]);

  const [invoice, setInvoice] = useState<any>({});
  const [invoiceFile, setInvoiceFile] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const handleFetchProvinces = async () => {
      const provincesResponse = await fetchProvince();
      
      setProvinces(
        provincesResponse.data.provinces.map((province: any) => ({
          value: province.id,
          label: province.name,
        }))
      );
    }
    
    const handleFetchInvoice = async () => {
      
      const invoiceResponse = await fetchInvoiceInfo();
      if (!invoiceResponse.data)
        return
      const districtResponse = await fetchDistrict(invoiceResponse.data.province_id);
      
        setInvoice(invoiceResponse.data);
      setDistricts(districtResponse.data.districts.map((district: any) => ({
        value: district.id,
        label: district.name,
      })));

      setIsLoading(false);
    }
    handleFetchProvinces().then(() => {

      handleFetchInvoice();
      
    });
    
    



  }
  , []);

  const loadDistricts = async (provinceId: number) => {
    const response = await fetchDistrict(provinceId);
    setDistricts(response.data.districts.map((district: any) => ({
      value: district.id,
      label: district.name,
    })));
  }

  const saveInvoiceInfo = async () => {
    const updateCompanyInvoiceResponse = await updateCompanyInvoice(invoice);
    if(updateCompanyInvoiceResponse){
      toast.success('Fatura bilgileri başarıyla güncellendi');
    }
  }
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    debugger
    const file = event.target.files?.[0];
    
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        
          if(!reader.result)
            return
        
        updateCompanyInvoiceFile(file.name,file.type,reader.result?.toString()).then((response) => {
          if(response.status == 0){
            toast.success('Vergi levhası başarıyla yüklendi');
          }else{
            toast.error('Vergi levhası yüklenirken bir hata oluştu');
          }
        });

      };
      reader.readAsDataURL(file);
    }
  };


  return (

    isLoading ? (
      <BarLoader color={"#0c1b3a"} loading={isLoading} />
    ) : (
    <>
      <div className="col-md-12 dashboard-box margin-top-30">
        <div className="headline">
          <h3>
            <i className="icon-material-outline-business-center"></i> Fatura
          </h3>
        </div>
        <div className="content with-padding padding-bottom-0">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <div className="submit-field">
                    <h5>Vergi Dairesi İli</h5>
                    <DropdownWithSearch
                      key={"provinces_invoice" + invoice.province_id + (provinces || []).length}
                      selectedValue={invoice.province_id}
                      onSelect={(e) => {
                        loadDistricts(parseInt(e)); setInvoice({...invoice, province_id: parseInt(e)});
                      }}
                      options={provinces}
                      placeholder={"Vergi Dairesi İli"}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="submit-field">
                    <h5>Vergi Dairesi İlçesi</h5>
                    <DropdownWithSearch
                      key={"districts_invoice" + invoice.district_id + (districts || []).length}
                      selectedValue={invoice.district_id}

                      onSelect={(e) => {
                        setInvoice({...invoice, district_id: parseInt(e)});
                      }}
                      options={districts}
                      placeholder="Vergi Dairesi İlçesi"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="submit-field">
                    <h5>Vergi No</h5>
                    <div
                      className="input-with-icon-left"
                      data-tippy-placement="bottom"
                      data-tippy=""
                      data-original-title="Vergi No'yu Girin"
                    >
                      <i className="icon-material-outline-business"></i>
                      <input
                      value={invoice.tax_number}
                      onChange={(e) => setInvoice({...invoice, tax_number: e.target.value})}
                        type="text"
                        name="name-register"
                        id="name-register"
                        placeholder="Vergi No"
                        // startIcon=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="submit-field">
                    <div className="uploadButton margin-top-8">
                      <input
                        className="uploadButton-input"
                        type="file"
                        accept="image/*, application/pdf"
                        id="uploadcompanytax"
                        onChange={handleFileUpload}
                      />
                      <label
                        className="uploadButton-button ripple-effect"
                        htmlFor="uploadcompanytax"
                      >
                        Yükle
                      </label>
                      <span className="uploadButton-file-name">
                        Vergi Levhası Yükle
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="submit-field">
                    <h5>Fatura Adresi</h5>
                    <Textarea
                      onChange={(e) => setInvoice({...invoice, address: e.target.value})}
                      value={invoice.address}
                      rows={4}
                      placeholder="Fatura adresinizi giriniz."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <button
                  className="button full-width button-sliding-icon ripple-effect margin-top-30"
                  type="submit"
                  form="login-form"
                  onClick={() => saveInvoiceInfo()}
                >
                  Güncelle{" "}
                  <i className="icon-material-outline-arrow-right-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </>
  )
  );
};

export default CompanyInvoice;
