import { ReactNode } from 'react'
import { useMenu } from '@/contexts/MenuContext'
import MobileNav from './MobileNav'
import Navbar from './Navbar'
import Footer from './Footer'
import BackToTop from './BackToTop'
import { useShouldHideFooter } from '@/hooks'
import Modals from './Modals'
import { MODALS_LIST } from '@/libs/constants'

const MainLayout = ({ children }: { children: ReactNode }) => {
  const { showMenu } = useMenu()
  const shouldHideFooter = useShouldHideFooter()

  return (
    <div>
      <MobileNav />
      <div
        id="wrapper"
        className="mm-page mm-slideout"
        style={showMenu ? { minHeight: '903px' } : {}}
      >
        <BackToTop />
        <Navbar />
        <div className="clearfix"></div>
        {children}
        {!shouldHideFooter && <Footer />}
      </div>
      {/* Render FeedbackModal */}
      <Modals modals={MODALS_LIST} />
    </div>
  )
}

export default MainLayout
