import Input from '@/components/Input'
import { signinSchema } from '@/libs/schemes/signin'
import { ISignin } from '@/types/user'
import { Form, Formik, FormikHelpers } from 'formik'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const Login = () => {
  const [error, setError] = useState(false)

  const handleSubmit: (
    values: ISignin,
    formikHelpers: FormikHelpers<ISignin>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    setError(true)
    // setError(false)
    // setSubmitting(false)
  }
  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={signinSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className='form-container'>
          <div className="container">
            <div className="row l-container">
              <div className="col-md-8 offset-md-2">
                <div className="login-register-page">
                  <div className="welcome-text">
                    <h3>Giriş Yap</h3>
                    <span>
                      Hesabın yok mu?{' '}
                      <Link to="/employer/register">Üye Ol!</Link>
                    </span>
                  </div>

                  <form method="post">
                    <div
                      className="input-with-icon-left"
                      title="Email adresinizi girin"
                      data-tippy-placement="bottom"
                    >
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Email adresiniz"
                        startIcon="icon-material-baseline-mail-outline"
                      />
                    </div>

                    <div
                      className="input-with-icon-left"
                      title="Şifrenizi girin"
                      data-tippy-placement="bottom"
                    >
                      <Input
                        type="text"
                        name="password"
                        id="password"
                        placeholder="Şirket Ünvanı"
                        startIcon="icon-material-outline-lock"
                      />
                    </div>
                    <Link
                      to="/forgot-password"
                      className="forgot-password"
                    >
                      Şifreni mi unuttun?
                    </Link>
                  </form>

                  <button
                    className="button full-width button-sliding-icon ripple-effect margin-top-10"
                    type="submit"
                  >
                    Giriş Yap{' '}
                    <i className="icon-material-outline-arrow-right-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Login
