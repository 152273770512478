interface IndicatorsProps {
  jobSuccessPercentage: number;
  recommendationPercentage: number;
  onTimePercentage: number;
  onBudgetPercentage: number;
}

const Indicators = ({
  jobSuccessPercentage,
  recommendationPercentage,
  onTimePercentage,
  onBudgetPercentage,
}: IndicatorsProps) => {
  return (
    <>
      {/* <!-- Freelancer Indicators --> */}
      <div className="sidebar-widget">
        <div className="freelancer-indicators">
          {/* <!-- Indicator --> */}
          <div className="indicator">
            <strong>{jobSuccessPercentage}%</strong>
            <div
              className="indicator-bar"
              data-indicator-percentage={jobSuccessPercentage}
            >
              <span
                style={{
                  width: `${jobSuccessPercentage}%`,
                  backgroundColor: "rgb(253, 191, 0)",
                  height: "100%", // Yükseklik ayarı
                  transition: "width 0.5s ease-in-out", // Animasyon etkisi
                }}
              ></span>
            </div>
            <span>Job Success</span>
          </div>

          {/* <!-- Indicator --> */}
          <div className="indicator">
            <strong>{recommendationPercentage}%</strong>
            <div
              className="indicator-bar"
              data-indicator-percentage={recommendationPercentage}
            >
              <span
                style={{
                  width: `${recommendationPercentage}%`,
                  backgroundColor: "rgb(253, 191, 0)",
                  height: "100%",
                  transition: "width 0.5s ease-in-out",
                }}
              ></span>
            </div>
            <span>Recommendation</span>
          </div>

          {/* <!-- Indicator --> */}
          <div className="indicator">
            <strong>{onTimePercentage}%</strong>
            <div
              className="indicator-bar"
              data-indicator-percentage={onTimePercentage}
            >
              <span
                style={{
                  width: `${onTimePercentage}%`,
                  backgroundColor: "rgb(253, 191, 0)",
                  height: "100%",
                  transition: "width 0.5s ease-in-out",
                }}
              ></span>
            </div>
            <span>On Time</span>
          </div>

          {/* <!-- Indicator --> */}
          <div className="indicator">
            <strong>{onBudgetPercentage}%</strong>
            <div
              className="indicator-bar"
              data-indicator-percentage={onBudgetPercentage}
            >
              <span
                style={{
                  width: `${onBudgetPercentage}%`,
                  backgroundColor: "rgb(253, 191, 0)",
                  height: "100%",
                  transition: "width 0.5s ease-in-out",
                }}
              ></span>
            </div>
            <span>On Budget</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Indicators;
