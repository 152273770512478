interface MakeAnOfferProps {
  hourlyrate: string;
  jobscount: string;
  rehiredcount: string;
}

const MakeAnOffer = ({
  hourlyrate,
  jobscount,
  rehiredcount,
}: MakeAnOfferProps) => {
  return (
    <>
      <div className="profile-overview">
        <div className="overview-item">
          <strong>{hourlyrate}</strong>
          <span>Hourly Rate</span>
        </div>
        <div className="overview-item">
          <strong>{jobscount}</strong>
          <span>Jobs Done</span>
        </div>
        <div className="overview-item">
          <strong>{rehiredcount}</strong>
          <span>Rehired</span>
        </div>
      </div>

      {/* <!-- Button --> */}
      <a
        href="#small-dialog"
        className="apply-now-button popup-with-zoom-anim margin-bottom-50"
      >
        Make an Offer <i className="icon-material-outline-arrow-right-alt"></i>
      </a>
    </>
  );
};

export default MakeAnOffer;
