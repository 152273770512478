import AcceptanceModal from "@/components/AcceptanceModal";
import ApplyForJobModal from "@/components/ApplyForJobModal";
import DeleteAccountModal from "@/components/DeleteAccountModal";
import FeedbackModal from "@/components/FeedbackModal";
import IndRegisterForwardModal from "@/components/IndRegisterForwardModal";
import MessageModal from "@/components/MessageModal";
import RegisterInterfaceComponent from "@/components/RegisterInterfaceComponent";
import { NavSection } from "@/types/navbar";
import { Component } from "react";

export const hideFooterPaths = [
  "/job-seeker/profile",
  "/job-seeker/profile/corporate-information",
  "/job-seeker/profile/cv",
  "/job-seeker/profile/documents",
  "/job-seeker/profile/order",
  "/job-seeker/profile/job-applications",
  "/job-seeker/profile/settings",
  "/job-seeker/profile/support-center",
  "/employer/company-info",
  "/employer/company-info/corporate-information",
  "/employer/company-info/messages",
  "/employer/company-info/documents",
  "/employer/company-info/create-job-posting",
  "/employer/company-info/job-applications",
  "/employer/company-info/search-workspace",
  "/employer/company-info/settings",
  "/employer/company-info/support-center",
];

export const companyNavSection: NavSection[] = [
  {
    title: "Firma Bilgileri",
    items: [
      {
        label: "Kullanıcı Bilgilerim",
        href: "",
        iconClass: "icon-material-outline-dashboard",
      },
      {
        label: "Kurumsal Bilgilerim",
        href: "corporate-information",
        iconClass: "icon-material-outline-question-answer",
      },
      {
        label: "Mesajlarım",
        href: "messages",
        iconClass: "icon-material-outline-question-answer"
      },
      {
        label: "Belgelerim",
        href: "documents",
        iconClass: "icon-material-outline-star-border",
      },
    ],
  },
  {
    title: "İlan Ayarları",
    items: [
      {
        label: "İş İlanı Oluştur",
        href: "create-job-posting",
        iconClass: "icon-material-outline-dashboard",
      },
      {
        label: "İş Başvuruları",
        href: "job-applications",
        iconClass: "icon-material-outline-question-answer",
      },
      {
        label: "İş Gücü Ara",
        href: "search-workspace",
        iconClass: "icon-material-outline-question-answer",
      },
    ],
  },
  {
    title: "Hesabım",
    items: [
      {
        label: "Ayarlar",
        href: "settings",
        iconClass: "icon-material-outline-settings",
      },
      {
        label: "Destek Merkezi",
        href: "support-center",
        iconClass: "icon-material-outline-contact-support",
      },
      {
        label: "Çıkış Yap",
        href: "#",
        iconClass: "icon-material-outline-power-settings-new",
      },
    ],
  },
];

export const profileNavSection: NavSection[] = [
  {
    title: "Kullanıcı Bilgieri",
    items: [
      {
        label: "Bilgilerim",
        href: "",
        iconClass: "icon-material-outline-dashboard",
      },
      {
        label: "Özgeçmişlerim",
        href: "cv",
        iconClass: "icon-material-outline-question-answer",
      },
      {
        label: "Belgelerim",
        href: "documents",
        iconClass: "icon-material-outline-find-in-page",
      },
      {
        label: "Çalışma Düzenim",
        href: "order",
        iconClass: "icon-material-outline-rate-review",
      },
    ],
  },
  {
    title: "Ayarlar",
    items: [
      {
        label: "Ayarlar",
        href: "settings",
        iconClass: "icon-material-outline-settings",
      },
      {
        label: "Destek Merkezi",
        href: "support-center",
        iconClass: "icon-material-outline-contact-support",
      },
      {
        label: "Çıkış Yap",
        href: "#",
        iconClass: "icon-material-outline-power-settings-new",
      },
    ],
  },
];

export const GENDER_OPTIONS = [
  { value: "man", label: "Erkek" },
  { value: "woman", label: "Kadın" },
  { value: "other", label: "Diğer" },
];

export const COUNTRY_CODE_OPTIONS = [
  { value: "+91", label: "+91" },
  { value: "+92", label: "+92" },
  { value: "+93", label: "+93" },
];

export const COUNTRIES_OPTIONS = [
  { value: "Ankara", label: "Ankara" },
  { value: "İstanbul", label: "İstanbul" },
];

export const DISTRICT_OPTIONS = [
  { value: "Çankaya", label: "Çankaya" },
  { value: "Kızılay", label: "Kızılay" },
];

export const SUBJECT_ACTIVITY_OPTIONS = [
  {
    value: "Elektrik",
    label: "Elektrik",
    icon: "glyphicon icon-material-outline-access-alarm",
  },
  {
    value: "Mühendislik",
    label: "Mühendislik",
    icon: "glyphicon icon-material-outline-account-circle",
  },
  {
    value: "Ulaşım",
    label: "Ulaşım",
    icon: "glyphicon icon-material-outline-cake",
  },
];

export const TAX_PROVINCES_OPTIONS = [
  { value: "Ankara", label: "Ankara" },
  { value: "İstInbul", label: "İstanbul" },
  { value: "İzmit", label: "İzmit" },
];

export const TAX_DISTRICTS_OPTIONS = [
  { value: "Çankaya", label: "Çankaya" },
  { value: "Tuzla", label: "Tuzla" },
  { value: "Çeşme", label: "Çeşme" },
];

export const WOMEN_DISTRICTS_OPTIONS = [
  { value: "Bakırköy", label: "Bakırköy" },
  { value: "Çankaya", label: "Çankaya" },
  { value: "Urla", label: "Urla" },
];

export const DOCUMENT_TYPES = [
  { value: "Sürücü Belgesi", label: "Sürücü Belgesi" },
  { value: "Mezuniyet Belgesi", label: "Mezuniyet Belgesi" },
  { value: "Uzmanlık Belgesi", label: "Uzmanlık Belgesi" },
];

export const POSITION_OPTIONS = [
  { value: "Müdür", label: "Müdür" },
  { value: "CEO", label: "CEO" },
  { value: "Genel Müdür", label: "Genel Müdür" },
];

export const WORKING_OPTIONS = [
  { value: "FULL_TIME", label: "Tam Zamanlı" },
  { value: "PART_TIME", label: "Yarı Zamanlı" },
  { value: "DAILY", label: "Günlük" },
  { value: "HOURLY", label: "Saatlik" }
];

export const STAFF_GENDER_OPTIONS = [
  { value: "Farketmez", label: "Farketmez" },
  { value: "Kadın", label: "Kadın" },
  { value: "Erkek", label: "Erkek" },
];

export const NUMBER_OF_PERSONNEL = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
];

export const COMPANY_OPTIONS = [
  { value: "microsoft", label: "MICROSOFT" },
  { value: "apple", label: "APPLE" },
  { value: "hp", label: "HP" },
];

export const JOB_TITLES = [
  { value: "ceo", label: "CEO" },
  { value: "general-manager", label: "Genel Müdür" },
  { value: "worker", label: "İşçi" },
];

export const INDUSTRY_OPTIONS = [
  { value: "security", label: "Güvenlik" },
  { value: "realEstate", label: "Gayrimenkul" },
  { value: "finance", label: "Finans" },
];

export const LANGUAGE_OPTIONS = [
  { value: "english", label: "English" },
  { value: "german", label: "German" },
  { value: "russian", label: "Russian" },
];

export const LANGUAGE_OPTION = [
  { value: "turkish", label: "Türkçe" },
  { value: "english", label: "İngilizce" },
  { value: "german", label: "Almanca" },
];

export const EDUCATION_TYPE_OPTIONS = [
  { value: "formal", label: "Örgün Öğretim" },
  { value: "part-time", label: "İkinci Öğretim" },
  { value: "distance", label: "Uzaktan Eğitim" },
];

export const UNIVERSITY_OPTIONS = [
  { value: "ankara", label: "Ankara Üniversitesi" },
  { value: "istanbul", label: "İstanbul Üniversitesi" },
  { value: "izmir", label: "İzmir Üniversitesi" },
];

export const EDUCATION_OPTIONS = [
  { value: "lisans", label: "Lisans" },
  { value: "önlisans", label: "Önlisans" },
  { value: "yüksek_lisans", label: "Yüksek Lisans" },
];

export const CITY_OPTIONS = [
  { value: "ankara", label: "Ankara" },
  { value: "istanbul", label: "İstanbul" },
  { value: "izmir", label: "İzmir" },
];

export const COUNTRY_OPTIONS = [
  { value: "turkey", label: "Türkiye" },
  { value: "usa", label: "ABD" },
  { value: "germany", label: "Almanya" },
];

export const POSITION_LEVEL_OPTIONS = [
  { value: "consultant", label: "Danışman" },
  { value: "officeWorker", label: "Ofis Elemanı" },
  { value: "worker", label: "İşçi" },
];

export const EMPLOYMENT_TYPE_OPTIONS = [
  { value: "partTime", label: "Yarı Zamanlı" },
  { value: "fullTime", label: "Tam Zamanlı" },
  { value: "contract", label: "Sözleşmeli" },
];

export const DIPLOMA_OPTIONS = [
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "10", label: "10" },
];

export const DOCUMENT_OPTIONS = [
  { value: "Sürücü Belgesi", label: "Sürücü Belgesi" },
  { value: "Mezuniyet Belgesi", label: "Mezuniyet Belgesi" },
  { value: "Uzmanlık Belgesi", label: "Uzmanlık Belgesi" },
];

export const MODALS_LIST = [
  { id: "feedback-modal", Component: FeedbackModal },
  { id: "delete-account-modal", Component: DeleteAccountModal },
  { id: "acceptance-modal", Component: AcceptanceModal },
  { id: "message-modal", Component: MessageModal },
  { id: "apply-for-job-modal", Component: ApplyForJobModal },
  { id: "login", Component: RegisterInterfaceComponent },
  { id: "ind-register-forward-modal", Component: IndRegisterForwardModal },
];

export const jobSeekerLinks = [
  { to: "/job-seeker/register", label: "Kayıt Ol" },
  { to: "/job-seeker/login", label: "Giriş Yap" },
  { to: "/job-seeker/confirmation-code", label: "Onay Kodu" },
  { to: "/job-seeker/reset-password", label: "Şifre Sıfırla" },
  { to: "/job-seeker/password-renewal", label: "Şifre Yenile" },
  { to: "/job-seeker/profile", label: "Kullanıcı Profili" },
  { to: "/job-seeker/job-posting", label: "İş İlanları" },
  { to: "/job-seeker/job-application", label: "İş Başvurusu" },
];

export const employerLinks = [
  { to: "/employer/register", label: "Kayıt Ol" },
  { to: "/employer/login", label: "Giriş Yap" },
  { to: "/employer/confirmation-code", label: "Onay Kodu" },
  { to: "/employer/reset-password", label: "Şifre Sıfırla" },
  { to: "/employer/password-renewal", label: "Şifre Yenile" },
  { to: "/employer/company-info", label: "Firma Bilgileri" },
];

export const jobListings = [
  {
    id: 1,
    company: "Burger King",
    title: "Şef Garson",
    description:
      "Mağazamıza tecrübeli, askerliğini yapmış, 30 yaş üzeri, ehliyeti olan şef garson aranmaktadır.",
  },
  {
    id: 2,
    company: "Burger King",
    title: "Şef Garson",
    description:
      "Mağazamıza tecrübeli, askerliğini yapmış, 30 yaş üzeri, ehliyeti olan şef garson aranmaktadır.",
  },
];

export const sortOptions = [
  { value: "high_scores", label: "Yüksek Puanlar" },
  { value: "most_reviewed", label: "Çok Değerlendirilenler" },
  { value: "salary_high_to_low", label: "Ücret(Yüksekten Düşüğe)" },
  { value: "salary_low_to_high", label: "Ücret(Düşükten Yükseğe)" },
  { value: "age_high_to_low", label: "Yaş(Yüksekten Düşüğe)" },
  { value: "age_low_to_high", label: "Yaş(Düşükten Yükseğe)" },
];
