import { NavSection } from '@/types/navbar'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

interface SidebarProps {
  navSections: NavSection[]
}

const Sidebar: React.FC<SidebarProps> = ({ navSections }) => {
  const [activeLink, setActiveLink] = useState<number>(0)
  const [activeSection, setActiveSection] = useState<number>(0)
  const [openMobileSidebar, setOpenMobileSidebar] = useState<boolean>(false)

  const handleClick = (sectionIndex: number, itemIndex: number) => {
    setActiveSection(sectionIndex)
    setActiveLink(itemIndex)
  }

  const handleMobileSidebarOnClick = () => {
    console.log('called')
    setOpenMobileSidebar(!openMobileSidebar)
  }
  return (
    <div className="dashboard-sidebar">
      <div className="dashboard-sidebar-inner" data-simplebar>
        <div className="dashboard-nav-container">
          <a
            href="#"
            className={`dashboard-responsive-nav-trigger ${
              openMobileSidebar ? 'active' : ''
            }`}
          >
            <span className="hamburger hamburger--collapse" onClick={handleMobileSidebarOnClick}>
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </span>
            <span className="trigger-title">Kontrol Paneli</span>
          </a>

          <div className={`dashboard-nav ${openMobileSidebar ? 'active' : ''}`}>
            <div className="dashboard-nav-inner">
              {navSections.map((section, sectionIndex) => (
                <ul
                  key={sectionIndex}
                  data-submenu-title={section.title}
                  className={
                    activeSection === sectionIndex ? 'active-section' : ''
                  }
                >
                  {section.items.map((item, itemIndex) => (
                    <li
                      key={item.href}
                      className={
                        activeLink === itemIndex &&
                        activeSection === sectionIndex
                          ? 'active'
                          : ''
                      }
                    >
                      <Link
                        to={item.href}
                        onClick={() => handleClick(sectionIndex, itemIndex)}
                      >
                        <i className={item.iconClass}></i> {item.label}{' '}
                        {item.tag && (
                          <span className="nav-tag">{item.tag}</span>
                        )}
                      </Link>
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
