import { deleteMessageGroup, fetchMessages, fetchMessagesHistory, sendMessageToGroup } from '@/api/account';
import { env } from '@/config';
import userAvatar01 from '@/images/user-avatar-small-01.jpg'
import userAvatar02 from '@/images/user-avatar-small-02.jpg'
import userAvatar03 from '@/images/user-avatar-small-03.jpg'
import { RootState } from '@/store';
import { createRef, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { BarLoader } from 'react-spinners';

const MyMessages = () => {

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const user = useSelector((state: RootState) => state.user);

  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const [messageContent, setMessageContent] = useState('');

  useEffect(() => {
    const handleLoadMessages = async () => {
      const messagesResponse = await fetchMessagesHistory();
      setGroups(messagesResponse.data);
    }
    handleLoadMessages();

  }
  , []);

  const handleDeleteMessageGroup = async (groupId: number) => {
    const response = await deleteMessageGroup(groupId);
    if(response){
      const updatedGroups = groups.filter((group:any) => group.id !== groupId);
      setGroups(updatedGroups);
    }
  }

  const sendMessage = async (groupId: number, message: string) => {
    if(message === '') return;
    const response = await sendMessageToGroup(groupId, message);
    
      setMessageContent('');
    
  };
  const connection = useRef<WebSocket | null>(null);

  useEffect(() => {
    if(selectedGroup === null) return;

    const handleLoadMessages = async () => {
      if(selectedGroup === null) return;
      const messagesResponse = await fetchMessages(selectedGroup.id);
      setMessages(messagesResponse.data);
    }
    handleLoadMessages();
    
    const socket = new WebSocket(env.WS_URL + '/ws/messaging/' + selectedGroup.pub_channel_id)


    // Listen for messages
    socket.addEventListener("message", (event) => {
      const msg = JSON.parse(event.data);
      setMessages((prevMessages) => [...prevMessages, msg.latest_message]);
      
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    })

    connection.current = socket;

    return () => socket.close()


  }
  , [selectedGroup]);



  return (
    <div className="messages-container margin-top-0">
      <div className="messages-container-inner">

        {/* Messages */}
        <div className="messages-inbox">
        {groups.length === 0 ? (
          <div className="messages-headline">
            <h4>Henüz mesajınız yok</h4>
          </div>
        ):(
        <>

          <div className="messages-headline">
            <div className="input-with-icon">
              <input id="autocomplete-input" type="text" placeholder="Başvuranlar İçerisinde İsim Ara" />
              <i className="icon-material-outline-search"></i>
            </div>
          </div>

          <ul>
            {groups.map((group:any, index) => (
              <li key={group.id} className={selectedGroup === group.id ? 'active-message' : ''}>
                <a href="#" onClick={() => setSelectedGroup(group)} >
                  <div className="message-avatar">
                    <i className="status-icon status-online"></i>
                    <img src={userAvatar03} alt="" />
                  </div>
                  <div className="message-by">
                    <div className="message-by-headline">
                      <h5>{group.latest_message?.sender.first_name} {group.latest_message?.sender.last_name}</h5>
                      <span>{group.date}</span>
                    </div>
                    <p>{group.latest_message?.message}</p>
                  </div>
                </a>
              </li>
            ))}



            
          </ul>
          </> )}
        </div>
        
     

        {/* Messages / End */}

        {/* Message Content */}
          {groups.length > 0 && (  
        <div className="message-content">
        {selectedGroup === null ? (
          <BarLoader color="#f7c08a" loading={true} width="100%" height={4} />
        ) : (

         (

          <>
          
          <div className="messages-headline">
          <h4>{selectedGroup.members.map((member:any) => member.first_name + ' ' + member.last_name).join(', ')}</h4>
          <a href="#" className="message-action" onClick={() => handleDeleteMessageGroup(selectedGroup.id)}>
            <i className="icon-feather-trash-2"></i> Konuşmayı Sil
          </a>
        </div>

        {/* Message Content Inner */}
        <div className="message-content-inner">

          {/* Time Sign */}
          <div className="message-time-sign">
            <span>{selectedGroup.date}</span>
          </div>

          {messages.map((message:any) => (
            
            <div className={`message-bubble ${message.sender.id == user.id ? 'me' : ''}`}>
              <div className="message-bubble-inner">
                <div className="message-avatar">
                  <img src={message.sender.id === 1 ? userAvatar01 : userAvatar02} alt="" />
                </div>
                <div className="message-text">
                  <p>{message.message}</p>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          ))}
          <div ref={messagesEndRef} />

          {false && (
          <div className="message-bubble">
            <div className="message-bubble-inner">
              <div className="message-avatar">
                <img src={userAvatar02} alt="" />
              </div>
              <div className="message-text">
                {/* Typing Indicator */}
                <div className="typing-indicator">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
          )}


        </div>
        {/* Message Content Inner / End */}

        {/* Reply Area */}
        <div className="message-reply">
          <textarea value={messageContent} cols={1} rows={1} placeholder="Mesajınız" data-autoresize onChange={(e) => setMessageContent(e.target.value)}></textarea>
          <button className="button ripple-effect" onClick={() => sendMessage(selectedGroup.id, messageContent)}>Gönder</button>
        </div></>
)
        )}
        </div>
        )}
        {/* Message Content */}
        
      </div>
    </div>
  );
};

export default MyMessages;