// import React from 'react'

// interface TextareaProps {
//   cols?: number
//   rows?: number
//   className?: string
//   value?: string
//   onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
//   placeholder?: string
// }

// const Textarea: React.FC<TextareaProps> = ({
//   cols = 30,
//   rows = 5,
//   className = 'with-border',
//   value,
//   onChange,
//   placeholder = ''
// }) => {
//   return (
//     <textarea
//       placeholder={placeholder}
//       cols={cols}
//       rows={rows}
//       className={className}
//       value={value}
//       onChange={onChange}
//     ></textarea>
//   )
// }
// export default Textarea

import React, { TextareaHTMLAttributes } from "react";

// Extend TextareaProps to include all standard textarea attributes
interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  cols?: number;
  rows?: number;
  className?: string;
  placeholder?: string;
}

// Update the Textarea component to accept all textarea attributes
const Textarea: React.FC<TextareaProps> = ({
  cols = 30,
  rows = 5,
  className = "with-border",
  placeholder = "",
  ...props // Spread props to accept all other textarea attributes
}) => {
  return (
    <textarea
      placeholder={placeholder}
      cols={cols}
      rows={rows}
      className={className}
      {...props} // Spread other props (like value, onChange, onBlur, name, etc.)
    ></textarea>
  );
};

export default Textarea;
