import { useModal } from '@/contexts/ModalContext'
import React, { useState } from 'react'

const DeleteAccountModal = () => {
  const { hideModal } = useModal()
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value)
  }

  return (
    <div className='modal-container'>
      <div id="small-dialog-2" className="zoom-anim-dialog dialog-with-tabs modal-content">
        {/* Tabs */}
        <div className="sign-in-form">
          <ul className="popup-tabs-nav" style={{ pointerEvents: 'none' }}>
            <li className="active">
              <a href="#tab">Hesabımı Sil</a>
            </li>
          </ul>
          <div className="popup-tabs-container">
            {/* Tab */}
            <div className="popup-tab-content" id="tab">
              {/* Welcome Text */}
              <div className="welcome-text">
                <h3>
                  Ayrıldığın için üzgünüz. Daha iyi bir deneyim sağlamak için her
                  gün çalışıyoruz. Umarız bir gün yeniden buluşabiliriz.
                </h3>
              </div>
              {/* Form */}
              <form method="post" id="send-pm">
                <div className="col">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input
                            id="radio-1"
                            name="radio"
                            type="radio"
                            value="option1"
                            checked={selectedOption === 'option1'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="radio-1">
                            <span className="radio-label"></span> Aradığım işi
                            veya iş gücünü bulmama yardımcı olmadı
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input
                            id="radio-2"
                            name="radio"
                            type="radio"
                            value="option2"
                            checked={selectedOption === 'option2'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="radio-2">
                            <span className="radio-label"></span> Uygulama yavaş
                            veya doğru çalışmıyan bir şeyler var
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input
                            id="radio-3"
                            name="radio"
                            type="radio"
                            value="option3"
                            checked={selectedOption === 'option3'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="radio-3">
                            <span className="radio-label"></span> Verilerimin
                            güvenliğinden endişe ediyorum
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input
                            id="radio-4"
                            name="radio"
                            type="radio"
                            value="option4"
                            checked={selectedOption === 'option4'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="radio-4">
                            <span className="radio-label"></span> Yeterli müşteri
                            desteğini alamadım
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input
                            id="radio-5"
                            name="radio"
                            type="radio"
                            value="option5"
                            checked={selectedOption === 'option5'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="radio-5">
                            <span className="radio-label"></span> Çok fazla veya
                            ilgisiz bildirim aldım
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="radio">
                          <input
                            id="radio-6"
                            name="radio"
                            type="radio"
                            value="option4"
                            checked={selectedOption === 'option6'}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="radio-6">
                            <span className="radio-label"></span> Diğer
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="submit-field">
                        <textarea
                          name="textarea"
                          cols={10}
                          placeholder="Ayrılma nedenini açıklamak ister misin?"
                          className="with-border"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              {/* Button */}
              <div id="delete-account">
                <button
                  className="button full-width button-sliding-icon ripple-effect"
                  type="submit"
                  form="send-pm"
                  style={{ width: '30px' }}
                >
                  Hesabımı Sil{' '}
                  <i className="icon-material-outline-arrow-right-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <button
          title="Close (Esc)"
          type="button"
          className="mfp-close"
          onClick={() => hideModal('delete-account-modal')}
        ></button>
      </div>
    </div>
  )
}

export default DeleteAccountModal
