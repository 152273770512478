interface SinglePageHeaderProps {
  username: string;
  qualification: string;
  rating: number;
  location: string;
  userAvatarSrc: string;
  userAvatarAlt: string;
  countryFlagSrc: string;
  countryFlagAlt: string;
  isVerified: boolean;
  backgroundImageSrc: string;
}

const SinglePageHeader = ({
  username,
  qualification,
  rating,
  location,
  userAvatarSrc,
  userAvatarAlt,
  countryFlagSrc,
  countryFlagAlt,
  isVerified,
  backgroundImageSrc,
}: SinglePageHeaderProps) => {
  return (
    <div
      className="single-page-header freelancer-header"
      style={{ backgroundImage: `url(${backgroundImageSrc})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="single-page-header-inner">
              <div className="left-side">
                <div className="header-image">
                  <img src={userAvatarSrc} alt={userAvatarAlt} />
                </div>
                <div className="header-details">
                  <h3>
                    {username} <span>{qualification}</span>
                  </h3>
                  <ul>
                    <li>
                      <div
                        className="star-rating"
                        data-rating={rating.toString()}
                      ></div>
                    </li>
                    <li>
                      <img
                        className="flag"
                        src={countryFlagSrc}
                        alt={countryFlagAlt}
                      />{" "}
                      {location}
                    </li>
                    <li>
                      {isVerified && (
                        <div className="verified-badge-with-title">
                          Verified
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePageHeader;
