import api from './api'
export const fetchBankDetails = async() => {
    const result = await api.get('/account/bank-details');
    return result;
}

export const updateBankDetails = async (holder_name: string, iban: string) => {
    const result = await api.post('/account/bank-details', { holder_name, iban });
    return result as any;
}

export const fetchAccountInfo = async () => {
    const result = await api.get('/account');
    return result as any;
}

export const updateAccountInfo = async (request: any) => {
    const result = await api.put('/account', request);
    return result as any;
}

export const fetchNotifications = async () => {
    const result = await api.get('/notifications');
    return result as any;
}

export const fetchMessagesHistory = async () => {
    const result = await api.get('/messaging/history');
    return result as any;
}

export const fetchMessages = async (group_id: number) => {
    const result = await api.get(`/messaging/history/${group_id}`);
    return result as any;
}

export const sendMessageToGroup = async (group_id: number, message: string) => {
    const result = await api.post(`/messaging/send/${group_id}`, { message });
    return result as any;
}

export const updatePassword = async (old_password: string, new_password: string) => {
    const result = await api.post('/account/password', { current_password:old_password, new_password });
    return result as any;
}

export const deleteMessageGroup = async (group_id: number) => {
    const result = await api.delete(`/messaging/history/${group_id}`);
    return result as any;
}