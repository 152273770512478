import OtpCode from '@/components/Otp';

const ConfirmationCode = () => {
  const handleOtpSubmit = (otp: string[]) => {
    console.log('OTP submitted:', otp.join(''));
  };

  return (
    <div>
      <OtpCode
        otpLength={6}
        onSubmit={handleOtpSubmit}
        title="Hesabını onayla"
        subtitle="Mail adresine gönderdiğimiz 6 haneli onay kodunu gir."
        description="Onay kodunun gönderildiği mail:"
        email=" mail@mail.com"
      />
    </div>
  );
}

export default ConfirmationCode