
import axios from 'axios';
import api from './api'

export const fetchProvince = async () => {
    const result = await api.get('/public/province');
    return result as any;
}

export const fetchDistrict = async (province_id: number) => {
    const result = await api.get(`/public/district/${province_id}`);
    return result as any;
}

export const fetchBusinesAreaOptions = async () => {
    const result = await api.get(`/public/business_areas`);
    return result as any;
}