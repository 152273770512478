import playstoreImg from "@/images/get-play-store.png";
import appstoreImg from "@/images/get-app-store.svg";
import companyLogo3 from "@/images/company-logo-03.png";
import { useState } from "react";
import Dropdown from "@/components/Dropdown";
import { jobListings, POSITION_OPTIONS } from "@/libs/constants";
import { Link } from "react-router-dom";
import MultiRangeSlider from "@/components/MultiRangeSlider";

const JobPosting = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Şehir");
  const [selectedGender, setSelectedGender] = useState("Tümü"); // Default value

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedGender(event.target.value);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value: string) => {
    setSelectedValue(value);
    setIsOpen(false); // Optionally close the dropdown when an option is selected
  };

  const [bookmarkedJobs, setBookmarkedJobs] = useState(
    new Array(jobListings.length).fill(false)
  );

  const toggleBookmark = (
    index: number,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    setBookmarkedJobs((prev) =>
      prev.map((isBookmarked, i) =>
        i === index ? !isBookmarked : isBookmarked
      )
    );
  };
  return (
    <div className="container padding-top-50">
      <div className="row">
        <div className="col-md-3">
          <div className="sidebar-container">
            <div className="sidebar-widget">
              <h3>Şehir</h3>
              <div className="input-with-icon">
                <div id="autocomplete-container">
                  <input
                    id="autocomplete-input"
                    type="text"
                    placeholder="Şehir"
                  />
                </div>
                <i className="icon-material-outline-location-on"></i>
              </div>
            </div>

            <div className="sidebar-widget">
              <h3>Cinsiyet</h3>
              <div className="keywords-container">
                <div className="keyword-input-container">
                  <div className="radio padding-right-4">
                    <input
                      id="radio-1"
                      name="radio"
                      type="radio"
                      value="Tümü"
                      checked={selectedGender === "Tümü"}
                      onChange={handleChange}
                    />
                    <label htmlFor="radio-1">
                      <span className="radio-label"></span> Tümü
                    </label>
                  </div>
                  <div className="radio padding-right-4 mx-3">
                    <input
                      id="radio-2"
                      name="radio"
                      type="radio"
                      value="Erkek"
                      checked={selectedGender === "Erkek"}
                      onChange={handleChange}
                    />
                    <label htmlFor="radio-2">
                      <span className="radio-label"></span> Erkek
                    </label>
                  </div>
                  <div className="radio">
                    <input
                      id="radio-3"
                      name="radio"
                      type="radio"
                      value="Kadın"
                      checked={selectedGender === "Kadın"}
                      onChange={handleChange}
                    />
                    <label htmlFor="radio-3">
                      <span className="radio-label"></span> Kadın
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="sidebar-widget">
              <h3>Yaş Aralığı</h3>
              <div className="margin-top-55"></div>
              <MultiRangeSlider
                min={18}
                max={65}
                onChange={({ min, max }) => {}}
              />
            </div>

            <div className="sidebar-widget">
              <Dropdown
                options={POSITION_OPTIONS}
                placeholder="Pozisyon"
                label="Pozisyon"
              />
            </div>
            <div className="sidebar-widget">
              <h3>Çalışma Şekli</h3>

              <div className="switches-list">
                <div className="switch-container">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="switch-button"></span> Uzaktan Çalışma
                  </label>
                </div>

                <div className="switch-container">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="switch-button"></span> Tam Zamanlı
                  </label>
                </div>

                <div className="switch-container">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="switch-button"></span> Yarı Zamanlı
                  </label>
                </div>

                <div className="switch-container">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="switch-button"></span> Günlük
                  </label>
                </div>
              </div>
            </div>

            <div className="sidebar-widget">
              <h3>Maaş Aralığı</h3>
              <div className="margin-top-55"></div>
              <MultiRangeSlider
                min={1000}
                max={100000}
                onChange={({ min, max }) => {}}
              />
            </div>

            

          </div>
        </div>
        <div className="col-md-9 content-left-offset">
          <h3 className="page-title">Arama Sonuçları</h3>

                <div className="notification notice download">
                  <p>
                    Daha fazla iş teklifini görebilmek için{" "}
                    <strong>ParttimeJET</strong> uygulamasını indirmeniz
                    gerekmektedir.
                  </p>
                </div>

          <div className="listings-container margin-top-35">
            {jobListings.map((job, index) => (
              <Link
                to="/job-seeker/job-application"
                className="job-listing"
                key={job.id}
              >
                <div className="job-listing-details">
                  <div className="job-listing-company-logo">
                    <img src={companyLogo3} alt="" />
                  </div>
                  <div className="job-listing-description">
                    <h4 className="job-listing-company">
                      {job.company}{" "}
                      <span
                        className="verified-badge"
                        data-tippy-placement="top"
                        data-tippy=""
                        data-original-title="Doğrulanmış İşveren"
                      ></span>
                    </h4>
                    <h3 className="job-listing-title">{job.title}</h3>
                    <p className="job-listing-text">{job.description}</p>
                  </div>
                  <div
                    className="review-job"
                    onClick={(e) => toggleBookmark(index, e)}
                  >
                    <span
                      className={`bookmark-icon ${
                        bookmarkedJobs[index] ? "bookmarked" : ""
                      }`}
                    ></span>
                  </div>
                </div>
                <div className="job-listing-footer">
                  <ul>
                    <li>
                      <i className="icon-material-outline-location-on"></i>{" "}
                      İstanbul
                    </li>
                    <li>
                      <i className="icon-material-outline-business-center"></i>{" "}
                      Tam Zamanlı
                    </li>
                    <li>
                      <i className="icon-material-outline-account-balance-wallet"></i>{" "}
                      20000₺-35000₺
                    </li>
                    <li>
                      <i className="icon-material-outline-access-time"></i> 2
                      gün önce
                    </li>
                  </ul>
                </div>
              </Link>
            ))}

            <div className="row">
              <div className="col-md-12">
                <div className="notification notice download">
                  <p>
                    Daha fazla iş teklifini görebilmek için{" "}
                    <strong>ParttimeJET</strong> uygulamasını indirmeniz
                    gerekmektedir.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="get-app">
                  <div className="get-app-center">
                    <a href="">
                      <img
                        src={appstoreImg}
                        height="55"
                        alt="Uygulamamızı Apple Store'den İndir"
                      />
                    </a>
                    <a href="">
                      <img
                        src={playstoreImg}
                        height="55"
                        alt="Uygulamamızı Google Play'den İndir"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPosting;
