import React from 'react';
import { useModal } from '@/contexts/ModalContext';

interface ModalProps {
  id: string;
  Component: React.FC;
}

const Modals: React.FC<{ modals: ModalProps[] }> = ({ modals }) => {
  const { isModalVisible } = useModal();

  return (
    <>
      {modals.map(({ id, Component }) =>
        isModalVisible(id) ? <Component key={id} /> : null
      )}
    </>
  );
};

export default Modals;