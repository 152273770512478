// src/components/MobileNav.tsx
import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMenu } from '../contexts/MenuContext';
import NavItem from './NavItem';
import { employerLinks, jobSeekerLinks } from '@/libs/constants';

const MobileNav: React.FC = () => {
  const { showMenu, setShowMenu } = useMenu();
  const wrapperRef: LegacyRef<HTMLDivElement> | undefined = useRef(null);
  const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);

  const handleSubmenuToggle = (submenuId: string) => {
    setOpenSubmenu(submenuId);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowMenu]);

  useEffect(() => {
    const html = document.documentElement;
    if (showMenu) {
      html.classList.add('mm-blocking', 'mm-opened', 'mm-background', 'mm-opening');
      wrapperRef.current?.classList.add('mm-opened');
    } else {
      html.classList.remove('mm-opening');
      setTimeout(() => {
        html.classList.remove('mm-blocking', 'mm-opened', 'mm-background');
        wrapperRef.current?.classList.remove('mm-opened');
      }, 400);
    }
  }, [showMenu]);

  return (
    <nav ref={wrapperRef} className={`mmenu-init mm-menu mm-offcanvas`} id="mm-4">
      <div className="mm-panels">
        <div className="mm-panel mm-hasnavbar mm-opened" id="mm-5">
          <div className="mm-navbar">
            <a className="mm-title">Menu</a>
          </div>
          <ul className="mm-listview">
            <li>
              <Link to="#" className="current">
                Anasayfa
              </Link>
            </li>

            <li>
              <em className="mm-counter">8</em>
              <Link
                className="mm-next mm-fullsubopen"
                to="#"
                aria-owns="mm-6"
                aria-haspopup="true"
                onClick={() => handleSubmenuToggle('mm-6')}
              >
                <span className="mm-sronly">Open submenu (İş Arayan)</span>
              </Link>
              <a href="#">İş Arayan</a>
            </li>

            <li>
              <em className="mm-counter">6</em>
              <Link
                className="mm-next mm-fullsubopen"
                to="#"
                aria-owns="mm-7"
                aria-haspopup="true"
                onClick={() => handleSubmenuToggle('mm-7')}
              >
                <span className="mm-sronly">Open submenu (İş Veren)</span>
              </Link>
              <Link to="#">İş Veren</Link>
            </li>
          </ul>
        </div>
        <div
          className={`mm-panel ${openSubmenu === 'mm-6' ? 'mm-opened' : 'mm-hidden'} mm-hasnavbar`}
          id="mm-6"
          aria-hidden="true"
        >
          <div className="mm-navbar">
            <Link
              className="mm-btn mm-prev"
              to="#"
              aria-owns="mm-5"
              aria-haspopup="true"
              onClick={() => setOpenSubmenu(null)}
            >
              <span className="mm-sronly">Close submenu (İş Arayan)</span>
            </Link>
            <Link className="mm-title" to="#" aria-hidden="true">
              İş Arayan
            </Link>
          </div>
          <ul className="dropdown-nav mm-listview">
            {jobSeekerLinks.map((link) => (
              <NavItem key={link.to} to={link.to} label={link.label} />
            ))}
          </ul>
        </div>
        <div
          className={`mm-panel ${openSubmenu === 'mm-7' ? 'mm-opened' : 'mm-hidden'} mm-hasnavbar`}
          id="mm-7"
          aria-hidden="true"
        >
          <div className="mm-navbar">
            <Link
              className="mm-btn mm-prev"
              to="#"
              aria-owns="mm-5"
              aria-haspopup="true"
              onClick={() => setOpenSubmenu(null)}
            >
              <span className="mm-sronly">Close submenu (İş Veren)</span>
            </Link>
            <Link className="mm-title" to="#" aria-hidden="true">
              İş Veren
            </Link>
          </div>
          <ul className="dropdown-nav mm-listview">
            {employerLinks.map((link) => (
              <NavItem key={link.to} to={link.to} label={link.label} />
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MobileNav;