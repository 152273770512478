import api from './api'
export const fetchLogin = async (username: string, password: string) => {

    var bodyFormData = new FormData();
    bodyFormData.append("username", username);
    bodyFormData.append("password", password);
    const result = await api.post("/auth/token", bodyFormData);
      
    return result

};




export const fetchRegister = async ( 
        
    first_name: string, last_name: string, email: string, password: string, confirm_password: string, type: 'INDIVIDUAL' | 'COMPANY', company_name?:string, ) => {
    const result = await api.post('/registration', {  first_name, last_name, email, password, confirm_password, ...(company_name && { company_name }) ,type  });
    return result;
};


export const fetchRegisterCompany = async ( 
        
    first_name: string, last_name: string, email: string, password: string, confirm_password: string, type: 'COMPANY', company_name:string ) => {
    const result = await api.post('/registration', {  first_name, last_name, email, password, confirm_password, company_name ,type  });
    return result;
};

export const fetchMe = async () => {
    const result = await api.get('/auth/me');
    return result;
}