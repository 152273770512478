import React, { useState } from "react";
import userAvatar02 from "@/images/user-avatar-big-02.jpg";
import trFlag from "@/images/flags/tr.svg";
import { useModal } from "@/contexts/ModalContext";
const JobApplications = () => {
  const { showModal } = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Yaşa Göre");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value: string) => {
    setSelectedValue(value);
    setIsOpen(false); // Optionally close the dropdown when an option is selected
  };
  return (
    <>
      <div className="dashboard-headline">
        <h3>İş Başvuruları</h3>
        <span className="margin-top-7">
          Tüm başvuruları buradan görüntüleyebilirsiniz
        </span>
      </div>
      <div className="row">
        {/* Dashboard Box */}
        <div className="col-xl-12">
          <div className="dashboard-box margin-top-0">
            {/* Headline */}
            <div className="headline">
              <h3>
                <i className="icon-material-outline-supervisor-account"></i> 3
                İş Başvurusu
              </h3>
              <div className="sort-by">
                <div
                  className={`btn-group bootstrap-select hide-tick ${
                    isOpen ? "open" : ""
                  }`}
                  onClick={toggleDropdown}
                >
                  <button
                    type="button"
                    className="btn dropdown-toggle btn-default"
                    data-toggle="dropdown"
                    role="button"
                    title={selectedValue}
                  >
                    <span className="filter-option pull-left">
                      {selectedValue}
                    </span>
                    &nbsp;
                    <span className="bs-caret">
                      <span className="caret"></span>
                    </span>
                  </button>
                  <div
                    className={`dropdown-menu ${isOpen ? "open" : ""}`}
                    role="combobox"
                  >
                    <ul
                      className="dropdown-menu inner"
                      role="listbox"
                      aria-expanded={isOpen}
                    >
                      {["Yaşa Göre", "Maaşa Göre", "Cinsiyete Göre"].map(
                        (option, index) => (
                          <li
                            key={index}
                            data-original-index={index}
                            className={
                              selectedValue === option ? "selected" : ""
                            }
                          >
                            <a
                              tabIndex={0}
                              className=""
                              data-tokens="null"
                              role="option"
                              aria-disabled="false"
                              aria-selected={selectedValue === option}
                              onClick={() => handleOptionClick(option)}
                            >
                              <span className="text">{option}</span>
                              {selectedValue === option && (
                                <span className="glyphicon glyphicon-ok check-mark"></span>
                              )}
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <select
                    className="selectpicker hide-tick"
                    tabIndex={-98}
                    value={selectedValue}
                    onChange={(e) => setSelectedValue(e.target.value)}
                  >
                    <option value="Yaşa Göre">Yaşa Göre</option>
                    <option value="Maaşa Göre">Maaşa Göre</option>
                    <option value="Cinsiyete Göre">Cinsiyete Göre</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="content">
              <ul className="dashboard-box-list">
                {/* Job Application Item */}
                {Array(3)
                  .fill("")
                  .map((_, index) => (
                    <li key={index}>
                      <div className="freelancer-overview manage-candidates">
                        <div className="freelancer-overview-inner">
                          {/* Avatar */}
                          <div className="freelancer-avatar">
                            <div className="verified-badge"></div>
                            <a href="#">
                              <img src={userAvatar02} alt="" />
                            </a>
                          </div>

                          {/* Name */}
                          <div className="freelancer-name">
                            <h4>
                              <a href="#">
                                Ali Limon{" "}
                                <img
                                  className="flag"
                                  src={trFlag}
                                  alt=""
                                  title="Türkiye"
                                  data-tippy-placement="top"
                                />
                              </a>{" "}
                              <span className="freelancer-detail-city">
                                İstanbul
                              </span>
                            </h4>

                            {/* Details */}
                            <span className="freelancer-detail-item">
                              <a href="#">
                                <i className="icon-feather-mail"></i>{" "}
                                alilimon@gmail.com
                              </a>
                            </span>
                            <span className="freelancer-detail-item">
                              <i className="icon-feather-phone"></i> 0(544)
                              123-456-78
                            </span>

                            {/* Rating */}
                            <div className="freelancer-rating">
                              <div
                                className="star-rating"
                                data-rating="5.0"
                              ></div>
                            </div>

                            {/* Bid Details */}
                            <ul className="dashboard-task-info bid-info">
                              <li>
                                <strong>30.000₺</strong>
                                <span>Ücret</span>
                              </li>
                              <li>
                                <strong>Hemen</strong>
                                <span>Başlama Zamanı</span>
                              </li>
                            </ul>

                            {/* Buttons */}
                            <div className="buttons-to-right always-visible margin-top-25 margin-bottom-0">
                              <a
                                onClick={() => showModal("acceptance-modal")}
                                className="popup-with-zoom-anim button ripple-effect text-white"
                              >
                                <i className="icon-material-outline-check"></i>{" "}
                                Başvuruyu Kabul Et
                              </a>
                              <a
                                onClick={() => showModal("message-modal")}
                                className="popup-with-zoom-anim button dark ripple-effect text-white"
                              >
                                <i className="icon-feather-mail"></i> Mesaj
                                Gönder
                              </a>
                              <a
                                href="#"
                                className="button gray ripple-effect ico"
                                title="Başvuruyu Sil"
                                data-tippy-placement="top"
                              >
                                <i className="icon-feather-trash-2"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobApplications;
