import Dropdown from "@/components/Dropdown";
import { Form } from "@/components/Form";
import Input from "@/components/Input";
import Textarea from "@/components/TextArea";
import {
  SUBJECT_ACTIVITY_OPTIONS,
  TAX_DISTRICTS_OPTIONS,
  TAX_PROVINCES_OPTIONS,
  WOMEN_DISTRICTS_OPTIONS,
} from "@/libs/constants";
import React from "react";
import CompanyInformation from "./CompanyInformation";
import CompanyInvoice from "./CompanyInvoice";
import CompanyAuth from "./CompanyAuth";
import CompanyLocation from "./CompanyLocation";

const CorporateInformation = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <CompanyInformation />
        {/* <div className="col-md-12 dashboard-box margin-top-0">
          <div className="headline">
            <h3>
              <i className="icon-material-outline-business-center"></i> Künye
            </h3>
          </div>
          <div className="content with-padding padding-bottom-0">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Şirket Ünvanı</h5>
                      <div
                        className="input-with-icon-left"
                        data-tippy-placement="bottom"
                        data-tippy=""
                        data-original-title="Şirket Ünvanınızı Girin"
                      >
                        <i className="icon-material-outline-business"></i>
                        <input
                          type="text"
                          name="name-register"
                          id="name-register"
                          placeholder="Şirket Ünvanı"
                          // startIcon="icon-material-outline-business"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <div className="section-headline">
                        <h5>Faaliyet Konusu</h5>
                      </div>
                      <Dropdown
                        options={SUBJECT_ACTIVITY_OPTIONS}
                        placeholder="Hiçbir şey seçilmedi!"
                        // onSelect={handleSelect}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>E-posta Adresiniz</h5>
                      <div
                        className="input-with-icon-left"
                        data-tippy-placement="bottom"
                        data-tippy=""
                        data-original-title="Şirket Ünvanınızı Girin"
                      >
                        <i className="icon-material-outline-business"></i>
                        <input
                          type="text"
                          name="name-register"
                          id="name-register"
                          placeholder="E-posta Adresiniz"
                          // startIcon=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Telefon Numaranız</h5>
                      <div
                        className="input-with-icon-left"
                        data-tippy-placement="bottom"
                        data-tippy=""
                        data-original-title="Şirket Ünvanınızı Girin"
                      >
                        <i className="icon-material-outline-business"></i>
                        <input
                          type="text"
                          name="name-register"
                          id="name-register"
                          placeholder="Telefon Numaranız"
                          // startIcon="icon-material-outline-business"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Web Siteniz</h5>
                      <div
                        className="input-with-icon-left"
                        data-tippy-placement="bottom"
                        data-tippy=""
                        data-original-title="Şirket Ünvanınızı Girin"
                      >
                        <i className="icon-material-outline-business"></i>
                        <input
                          type="text"
                          name="name-register"
                          id="name-register"
                          placeholder="Web Siteniz"
                          // startIcon="icon-material-outline-business"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <div className="uploadButton margin-top-8">
                        <input
                          className="uploadButton-input"
                          type="file"
                          accept="image/*, application/pdf"
                          id="upload"
                          multiple={true}
                        />
                        <label
                          className="uploadButton-button ripple-effect"
                          htmlFor="upload"
                        >
                          Logo Yükle
                        </label>
                        <span className="uploadButton-file-name">
                          İş ilanlarında görünecek şirket logonuzu yükleyin.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Şirket Tanıtım Metni</h5>
                      <Textarea
                        rows={16}
                        placeholder="İş ilanlarında görünecek şirket tanıtım metnini giriniz."
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <button
                    className="button full-width button-sliding-icon ripple-effect margin-top-30"
                    type="submit"
                    form="login-form"
                  >
                    Bilgilerimi Güncelle{" "}
                    <i className="icon-material-outline-arrow-right-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div> */}
        <CompanyInvoice />
        {/* <div className="col-md-12 dashboard-box margin-top-30">
          <div className="headline">
            <h3>
              <i className="icon-material-outline-business-center"></i> Fatura
            </h3>
          </div>
          <div className="content with-padding padding-bottom-0">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <div className="submit-field">
                      <h5>Vergi Dairesi İli</h5>
                      <Dropdown
                        options={TAX_PROVINCES_OPTIONS}
                        placeholder="Vergi Dairesi İli"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <h5>Vergi Dairesi İlçesi</h5>
                      <Dropdown
                        options={TAX_DISTRICTS_OPTIONS}
                        placeholder="Vergi Dairesi İli"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Vergi No</h5>
                      <div
                        className="input-with-icon-left"
                        data-tippy-placement="bottom"
                        data-tippy=""
                        data-original-title="Vergi No'yu Girin"
                      >
                        <i className="icon-material-outline-business"></i>
                        <input
                          type="text"
                          name="name-register"
                          id="name-register"
                          placeholder="Vergi No"
                          // startIcon=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <div className="uploadButton margin-top-8">
                        <input
                          className="uploadButton-input"
                          type="file"
                          accept="image/*, application/pdf"
                          id="upload"
                          multiple
                        />
                        <label
                          className="uploadButton-button ripple-effect"
                          htmlFor="upload"
                        >
                          Upload Tax Certificate
                        </label>
                        <span className="uploadButton-file-name">
                          Upload your tax return.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Fatura Adresi</h5>
                      <Textarea
                        rows={4}
                        placeholder="Fatura adresinizi giriniz."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <CompanyAuth />
        {/* <div className="col-md-12 dashboard-box margin-top-30">
          <div className="headline">
            <h3>
              <i className="icon-line-awesome-comment-o"></i> Yetki
            </h3>
            <p className="authority-text">
              Tüm bilgileri tamamla, onaylı firma ol ve iş gücüne ulaş
            </p>
          </div>
          <div className="content with-padding padding-bottom-0">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <div className="uploadButton margin-top-8">
                        <input
                          className="uploadButton-input"
                          type="file"
                          accept="image/*, application/pdf"
                          id="upload"
                          multiple={true}
                        />
                        <label
                          className="uploadButton-button ripple-effect"
                          htmlFor="upload"
                        >
                          Yetki Belgesi Ekle
                        </label>
                        <span className="uploadButton-file-name">
                          Yetki belgenizi yükleyin.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <CompanyLocation />
        {/* <div className="col-md-12 dashboard-box margin-top-30">
          <div className="headline">
            <h3>
              <i className="icon-line-awesome-book"></i> Konum
            </h3>
          </div>
          <div className="content with-padding padding-bottom-0">
            <div className="row">
              
              <div className="col-md-5">
                <div className="submit-field">
                  <Dropdown options={TAX_PROVINCES_OPTIONS} placeholder="İl" />
                </div>
              </div>
              <div className="col-md-5">
                <div className="submit-field">
                  <Dropdown
                    options={WOMEN_DISTRICTS_OPTIONS}
                    placeholder="İlçe"
                  />
                </div>
              </div>
              <div className="col-md-2 margin-bottom-12">
                <button className="b-add-location" type="submit">
                  <i className="icon-feather-plus"></i>
                </button>
              </div>
              <div className="col-md-3 margin-bottom-10">
                <a href="#" className="button ripple-effect small">
                  <i className="icon-feather-plus"></i> Daha Fazla Konum Ekle
                </a>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="col-md-3">
        <button
          className="button full-width button-sliding-icon ripple-effect margin-top-30"
          type="submit"
          form="login-form"
        >
          Bilgilerimi Güncelle{" "}
          <i className="icon-material-outline-arrow-right-alt"></i>
        </button>
      </div> */}
    </div>
  );
};

export default CorporateInformation;
