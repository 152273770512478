import { Routes, Route } from "react-router-dom";
import {
  Home,
  UserProfile,
  JobApplication,
  JobPosting,
  Register,
  Login,
  ConfirmationCode,
  ResetPassword,
  PasswordRenewal,
  EmployerRegister,
  EmployerLogin,
  EmployerConfirmationCode,
  EmployerResetPassword,
  EmployerPasswordRenewal,
  CompanyInfo,
  Payment,
  UserInformation,
  MyMessages,
  CorporateInformation,
  CreateJobPosting,
  JobApplications,
  SearchWorkspace,
  SupportCenter,
  Settings,
  MyInformation,
  MyCV,
  UserDocuments,
  MyDocuments,
  MyOrder,
} from "@/pages";
import RegisterInterfaceComponent from "@/components/RegisterInterfaceComponent";
import FreelancerProfile from "@/pages/jobSeeker/FreelancerProfile/FreelancerProfile";
import ProtectedRoute from "./guarded";
import ForgotPassword from "@/pages/employer/auth/ForgotPassword";
import Contact from "@/components/Contact";

const RoutesConfig = () => {
  return (
    <Routes>
      {/* Job Seeker Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<RegisterInterfaceComponent />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/job-seeker/register" element={<Register />} />
      <Route path="/job-seeker/login" element={<Login />} />
      <Route
        path="/job-seeker/confirmation-code"
        element={<ConfirmationCode />}
      />
      <Route path="/job-seeker/reset-password" element={<ResetPassword />} />
      <Route
        path="/job-seeker/password-renewal"
        element={<PasswordRenewal />}
      />
      <Route path="/job-seeker/profile" element={<UserProfile />}>
        <Route
          index
          element={
            <ProtectedRoute>
              <MyInformation />
            </ProtectedRoute>
          }
        />
        <Route
          path="corporate-information"
          element={<CorporateInformation />}
        />
        <Route path="cv" element={<MyCV />} />
        <Route path="documents" element={<MyDocuments />} />
        <Route path="order" element={<MyOrder />} />
        <Route path="job-applications" element={<JobApplications />} />
        <Route path="settings" element={<Settings />} />
        <Route path="support-center" element={<SupportCenter />} />
      </Route>
      <Route path="/job-seeker/job-application" element={<JobApplication />} />
      <Route path="/job-seeker/job-posting" element={<JobPosting />} />
      <Route path="/job-list" element={<JobPosting />} />
      <Route path="/contact" element={<Contact />} />

      {/* Employer Routes */}
      <Route path="/register" element={<EmployerRegister />} />
      <Route path="/employer/login" element={<EmployerLogin />} />
      <Route
        path="/employer/FreelancerProfile"
        element={<FreelancerProfile />}
      />
      <Route
        path="/employer/confirmation-code"
        element={<EmployerConfirmationCode />}
      />
      <Route
        path="/employer/reset-password"
        element={<EmployerResetPassword />}
      />
      <Route
        path="/employer/password-renewal"
        element={<EmployerPasswordRenewal />}
      />
      <Route path="/employer/company-info" element={<CompanyInfo />}>
        <Route index element={<UserInformation />} />
        <Route
          path="corporate-information"
          element={<CorporateInformation />}
        />
        <Route path="messages" element={<MyMessages />} />
        <Route path="documents" element={<UserDocuments />} />
        <Route path="create-job-posting" element={<CreateJobPosting />} />
        <Route path="job-applications" element={<JobApplications />} />
        <Route path="search-workspace" element={<SearchWorkspace />} />
        <Route path="settings" element={<Settings />} />
        <Route path="support-center" element={<SupportCenter />} />
      </Route>
      <Route path="/payment" element={<Payment />} />
    </Routes>
  );
};

export default RoutesConfig;
