class TokenService {

    getAccessToken() {
      return localStorage.getItem("token")
      return localStorage.getItem("token");
    }
    getToken() {
      return localStorage.getItem("token");
    }
    setTokens(token) {
      localStorage.setItem("token", token);
    }
    getTokens(){
      return localStorage.getItem("token");
    }
  
    getUser() {
      return JSON.parse(localStorage.getItem("user"));
    }
    setUser(user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
    removeUser() {
      localStorage.removeItem("user");
    }
    clear() {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
    }
  }
  // const tokenServiceInstance = new TokenService();

  export default new TokenService();