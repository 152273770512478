interface FeedbackProps {
  title: string;
  role: string;
  rating: number;
  date: string;
  description: string;
}

const FeedbackComponent = ({
  title,
  role,
  rating,
  date,
  description,
}: FeedbackProps) => {
  if (rating < 0 || rating > 5) {
    throw new Error("Rating must be between 0 and 5");
  }
  if (isNaN(Date.parse(date))) {
    throw new Error("Invalid date format");
  }
  if (!description.trim()) {
    throw new Error("Description cannot be empty");
  }
  return (
    <li>
      <div className="boxed-list-item">
        {/* <!-- Content --> */}
        <div className="item-content">
          <h4>
            {title} <span>Rated as {role}</span>
          </h4>
          <div className="item-details margin-top-10">
            <div className="star-rating" data-rating={rating.toString()}></div>
            <div className="detail-item">
              <i className="icon-material-outline-date-range"></i> {date}
            </div>
          </div>
          <div className="item-description">
            <p>{description}</p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default FeedbackComponent;
