import { useState, useRef, useEffect, RefObject } from 'react';

interface UseOtpProps {
  otpLength: number;
}

const useOtp = ({ otpLength }: UseOtpProps) => {
  const [otp, setOtp] = useState(new Array(otpLength).fill(''));
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const [backspacePressed, setBackspacePressed] = useState(false);

  const inputRef: RefObject<HTMLInputElement> = useRef(null);

  const focusNextState = (index: number) => {
    if (index < otpLength - 1) setActiveOtpIndex(index + 1);
  };

  const focusPrevState = (index: number) => {
    let nextValue;
    const diff = index - 1;
    nextValue = diff < 0 ? 0 : diff;
    setActiveOtpIndex(nextValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (backspacePressed) {
      setBackspacePressed(false);
      return;
    }

    const { value } = event.target;
    let newValue = [...otp];
    newValue[index] = value.substring(value.length - 1, value.length);

    if (value) {
      focusNextState(index);
    }

    setOtp(newValue);
  };

  const onKeyChange = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace') {
      let newValue = [...otp];
      newValue[index] = '';
      setOtp(newValue);
      focusPrevState(index);
      setBackspacePressed(true);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIndex]);

  return {
    otp,
    activeOtpIndex,
    inputRef,
    handleChange,
    onKeyChange,
  };
};

export default useOtp;