import { useModal } from '@/contexts/ModalContext';
import React from 'react';

const MessageModal = () => {
  const { hideModal } = useModal()
  return (
    <div className="modal-container">
      <div id="small-dialog-2" className="zoom-anim-dialog dialog-with-tabs modal-content">
        {/* Tabs */}
        <div className="sign-in-form">
          <ul className="popup-tabs-nav" style={{ pointerEvents: 'none' }}>
            <li className="active">
              <a href="#tab2">Mesaj Gönder</a>
            </li>
          </ul>
          <div className="popup-tabs-container">
            {/* Tab */}
            <div className="popup-tab-content" id="tab2">
              {/* Welcome Text */}
              <div className="welcome-text">
                <h3>Ali Limon'a Mesaj Gönder</h3>
              </div>
              {/* Form */}
              <form method="post" id="send-pm">
                <textarea
                  name="textarea"
                  cols={10}
                  placeholder="Mesaj içeriği"
                  className="with-border"
                  required
                />
              </form>
              {/* Button */}
              <button
                className="button full-width button-sliding-icon ripple-effect"
                type="submit"
                form="send-pm"
                style={{ width: '30px' }}
              >
                Gönder <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => hideModal('message-modal')}></button>
      </div>
    </div>
  );
};

export default MessageModal;