// images/user-avatar-big-01.jpg
import userAvatar01 from "@/images/user-avatar-big-01.jpg";
import trFlag from "@/images/flags/tr.svg";
import Dropdown from "@/components/Dropdown";
import { POSITION_OPTIONS, sortOptions } from "@/libs/constants";
import MultiRangeSlider from "@/components/MultiRangeSlider";
import { useState } from "react";

const SearchWorkspace = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string>(sortOptions[0].value);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value: string) => {
    setSelectedValue(value);
    setIsOpen(false); // Optionally close the dropdown when an option is selected
  };
  return (
    <div className="row">
      <div className="col-md-3">
        <div className="sidebar-container">
          {/* Location */}
          <div className="sidebar-widget">
            <h3>Şehir</h3>
            <div className="input-with-icon">
              <div id="autocomplete-container">
                <input
                  id="autocomplete-input"
                  type="text"
                  placeholder="Şehir"
                />
              </div>
              <i className="icon-material-outline-location-on"></i>
            </div>
          </div>

          {/* Keywords */}
          <div className="sidebar-widget">
            <h3>Cinsiyet</h3>
            <div className="keywords-container">
              <div className="keyword-input-container">
                <div className="radio padding-right-4">
                  <input
                    id="radio-1"
                    name="radio"
                    type="radio"
                    defaultChecked
                  />
                  <label htmlFor="radio-1">
                    <span className="radio-label"></span> Tümü
                  </label>
                </div>
                <div className="radio padding-right-4 mx-3">
                  <input id="radio-2" name="radio" type="radio" />
                  <label htmlFor="radio-2">
                    <span className="radio-label"></span> Erkek
                  </label>
                </div>
                <div className="radio">
                  <input id="radio-3" name="radio" type="radio" />
                  <label htmlFor="radio-3">
                    <span className="radio-label"></span> Kadın
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Age Range */}
          <div className="sidebar-widget">
            <h3>Yaş Aralığı</h3>
            <div className="margin-top-55"></div>

            {/* Range Slider */}
            <MultiRangeSlider
              min={18}
              max={65}
              onChange={({ min, max }) => {}}
            />
          </div>

          {/* Category */}
          <div className="sidebar-widget">
            <Dropdown
              options={POSITION_OPTIONS}
              placeholder="Pozisyon"
              label="Pozisyon"
            />
          </div>

          {/* Job Types */}
          <div className="sidebar-widget">
            <h3>Çalışma Şekli</h3>
            <div className="switches-list">
              <div className="switch-container">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="switch-button"></span>
                  Uzaktan Çalışma
                </label>
              </div>
              <div className="switch-container">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="switch-button"></span>
                  Tam Zamanlı
                </label>
              </div>
              <div className="switch-container">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="switch-button"></span>
                  Yarı Zamanlı
                </label>
              </div>
              <div className="switch-container">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="switch-button"></span>
                  Günlük
                </label>
              </div>
            </div>
          </div>

          {/* Working Hours */}
          <div className="sidebar-widget">
            <h3>Maaş Aralığı</h3>
            <div className="margin-top-55"></div>

            {/* Range Slider */}
            <MultiRangeSlider
              min={15000}
              max={100000}
              onChange={({ min, max }) => {}}
            />
          </div>

          {/* Salary Range */}
          <div className="sidebar-widget">
            <h3>Çalışma Saatleri</h3>
            <div className="margin-top-55"></div>

            {/* Range Slider */}
            <MultiRangeSlider
              min={8}
              max={24}
              onChange={({ min, max }) => {}}
            />
          </div>
        </div>
      </div>

      <div className="col-md-9 content-left-offset">
        <h3 className="page-title">Aday Listesi</h3>

        <div className="notify-box margin-top-15">
          <div className="switch-container">
            <label className="switch">
              <input type="checkbox" />
              <span className="switch-button"></span>
              <span className="switch-text">
                İş başvurusu yapıldığında e-posta ile bilgilendirme yap
              </span>
            </label>
          </div>

          {/* <div className="sort-by">
            <span>Sırala:</span>
            <select className="selectpicker hide-tick">
              <option>Yüksek Puanlar</option>
              <option>Çok Değerlendirilenler</option>
              <option>Ücret(Yüksekten Düşüğe)</option>
              <option>Ücret(Düşükten Yükseğe)</option>
              <option>Yaş(Yüksekten Düşüğe)</option>
              <option>Yaş(Düşükten Yükseğe)</option>
            </select>
          </div> */}
          <div className="sort-by">
            <span>Sırala:</span>
            <div
              className={`btn-group bootstrap-select hide-tick ${
                isOpen ? "open" : ""
              }`}
              onClick={toggleDropdown}
            >
              <button
                type="button"
                className="btn dropdown-toggle btn-default"
                data-toggle="dropdown"
                role="button"
                title={selectedValue}
              >
                <span className="filter-option pull-left">{selectedValue}</span>
                &nbsp;
                <span className="bs-caret">
                  <span className="caret"></span>
                </span>
              </button>
              <div
                className={`dropdown-menu ${isOpen ? "open" : ""}`}
                role="combobox"
              >
                <ul
                  className="dropdown-menu inner"
                  role="listbox"
                  aria-expanded={isOpen}
                >
                  {sortOptions.map((option) => (
                    <li
                      key={option.value}
                      className={selectedValue === option.value ? "selected" : ""}
                    >
                      <a
                        tabIndex={0}
                        className=""
                        data-tokens="null"
                        role="option"
                        aria-disabled="false"
                        aria-selected={selectedValue === option.value}
                        onClick={() => handleOptionClick(option.value)}
                      >
                        <span className="text">{option.label}</span>
                        {selectedValue === option.value && (
                          <span className="glyphicon glyphicon-ok check-mark"></span>
                        )}
                      </a>
                    </li>
                  ))}
                  {/* {sortOptions.map(
                    (option, index) => (
                      <li
                        key={index}
                        data-original-index={index}
                        className={selectedValue === option ? "selected" : ""}
                      >
                        <a
                          tabIndex={0}
                          className=""
                          data-tokens="null"
                          role="option"
                          aria-disabled="false"
                          aria-selected={selectedValue === option}
                          onClick={() => handleOptionClick(option)}
                        >
                          <span className="text">{option}</span>
                          {selectedValue === option && (
                            <span className="glyphicon glyphicon-ok check-mark"></span>
                          )}
                        </a>
                      </li>
                    )
                  )} */}
                </ul>
              </div>
              <select
                className="selectpicker hide-tick"
                tabIndex={-98}
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value)}
              >
                <option value="Yaşa Göre">Yaşa Göre</option>
                <option value="Maaşa Göre">Maaşa Göre</option>
                <option value="Cinsiyete Göre">Cinsiyete Göre</option>
              </select>
            </div>
          </div>
        </div>

        {/* Freelancers List Container */}
        <div className="freelancers-container freelancers-list-layout margin-top-35">
          {/* Freelancer */}
          {Array(4)
            .fill("")
            .map((_, index) => (
              <div className="freelancer" key={index}>
                <div className="freelancer-overview">
                  <div className="freelancer-overview-inner">
                    {/* Bookmark Icon */}
                    <span className="bookmark-icon"></span>

                    {/* Avatar */}
                    <div className="freelancer-avatar">
                      <div className="verified-badge"></div>
                      <a href="#">
                        <img src={userAvatar01} alt="" />
                      </a>
                    </div>

                    {/* Name */}
                    <div className="freelancer-name">
                      <h4>
                        <a href="pages-aday-detay.html">
                          Ali Limon
                          <img
                            className="flag"
                            src={trFlag}
                            alt=""
                            title="Türkiye"
                            data-tippy-placement="top"
                          />
                        </a>
                      </h4>
                      <span>Bilgisayar Mühendisi</span>
                      <div className="freelancer-rating">
                        {index < 3 ? (
                          <div className="star-rating" data-rating="4.5"></div>
                        ) : (
                          <span className="company-not-rated margin-bottom-5">
                            Henüz yeterli değerlendirme almadı
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Details */}
                <div className="freelancer-details">
                  <div className="freelancer-details-list">
                    <ul>
                      <li>
                        Şehir{" "}
                        <strong>
                          <i className="icon-material-outline-location-on"></i>{" "}
                          İstanbul
                        </strong>
                      </li>
                      <li>
                        Maaş <strong>25k₺/aylık</strong>
                      </li>
                      <li>
                        İş Bitirme <strong>%95</strong>
                      </li>
                    </ul>
                  </div>
                  <a
                    href="pages-aday-detay.html"
                    className="button button-sliding-icon ripple-effect"
                  >
                    Profili İncele{" "}
                    <i className="icon-material-outline-arrow-right-alt"></i>
                  </a>
                </div>
              </div>
            ))}
        </div>

        {/* Pagination */}
        <div className="clearfix"></div>
        <div className="row">
          <div className="col-md-12">
            <div className="pagination-container margin-top-40 margin-bottom-60">
              <nav className="pagination">
                <ul>
                  <li className="pagination-arrow">
                    <a href="#" className="ripple-effect">
                      <i className="icon-material-outline-keyboard-arrow-left"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="current-page ripple-effect">
                      1
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ripple-effect">
                      2
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ripple-effect">
                      3
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ripple-effect">
                      4
                    </a>
                  </li>
                  <li className="pagination-arrow">
                    <a href="#" className="ripple-effect">
                      <i className="icon-material-outline-keyboard-arrow-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchWorkspace;
