import React from 'react'
import clockImg from '@/images/icons/clock.png'
import OtpCode from '@/components/Otp'

const ResetPassword = () => {
  const handleOtpSubmit = (otp: string[]) => {
    console.log('OTP submitted:', otp.join(''));
  };
  return (
    <OtpCode
        otpLength={6}
        onSubmit={handleOtpSubmit}
        title="Doğrulama kodunu gir"
        description="ODoğrulama kodu gönderdiğimiz e-posta adresi"
        email=" mail@mail.com"
      />
  )
}

export default ResetPassword
