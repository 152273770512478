import React from 'react'

const PasswordRenewal = () => {
  return (
      <div className='form-container'>
        <div className="row l-container">
          <div className="col-md-8 offset-md-2">
            <div className="login-register-page">
              <div className="welcome-text">
                <h3>Şifre Yenileme</h3>
                <span>Belirlemek istediğin yeni şifreni gir</span>
              </div>

              <form method="post" id="login-form">
                <div
                  className="input-with-icon-left"
                  title="Yeni şifrenizi girin"
                  data-tippy-placement="bottom"
                >
                  <i className="icon-material-outline-lock"></i>
                  <input
                    type="password"
                    className="input-text with-border"
                    name="password"
                    id="password"
                    placeholder="Yeni şifre"
                  />
                </div>

                <div
                  className="input-with-icon-left"
                  title="Yeni şifrenizi tekrar girin"
                  data-tippy-placement="bottom"
                >
                  <i className="icon-material-outline-lock"></i>
                  <input
                    type="password"
                    className="input-text with-border"
                    name="password"
                    id="password"
                    placeholder="Yeni şifrenizi tekrar girin"
                  />
                </div>
              </form>

              <button
                className="button full-width button-sliding-icon ripple-effect margin-top-10"
                type="submit"
                form="login-form"
              >
                Şifremi Yenile{' '}
                <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
  )
}

export default PasswordRenewal
