import * as Yup from "yup";

const signinSchema = Yup.object({
  email: Yup.string()
    .email("Geçersiz email adresi")
    .required("Email adresiniz gerekli!"),
  password: Yup.string()
    .min(6, "Şifreniz en az 6 haneli olmalı!")
    .required("Şifre Gerekli!"),
});

export { signinSchema };
