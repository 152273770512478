import React from "react";
import trImg from "@/images/flags/tr.svg";
import useravatar from "@/images/user-avatar-big-01.jpg";
import bgImage from "@/images/single-job.jpg";

import SinglePageHeader from "./PageHeader/SinglePageHeader";
import FreelancerAboutMe from "./AboutMe/FreelancerAboutMe";
import FreelancerFeedback from "./Feedback/FreelancerFeedback";

import EmployementComponent from "./EmployementHistory/EmployementComponent";
import MakeAnOffer from "./MakeAnOffer/MakeAnOffer";
import Indicators from "./Indicators/Indicators";
import FreelancerSkills from "./FreelancerSkills/FreelancerSkills";
import FreelancerAttachments from "./FreelancerAttachments/FreelancerAttachments";
import Bookmark from "./Bookmark/Bookmark";

const skills = [
  "iOS",
  "Android",
  "mobile apps",
  "design",
  "Python",
  "Flask",
  "PHP",
  "WordPress",
];

// const Attachment =

const FreelancerProfile = () => {
  return (
    <>
      <SinglePageHeader
        username="David Peterson"
        qualification="iOS Expert + Node Dev"
        rating={5.0}
        location="Türkiye"
        userAvatarSrc={useravatar}
        userAvatarAlt="User Avatar"
        countryFlagSrc={trImg}
        countryFlagAlt="Turkey Flag"
        isVerified={true}
        backgroundImageSrc={bgImage}
      />

      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 content-right-offset">
            {/* <!-- Page Content --> */}
            <FreelancerAboutMe
              aboutMe="Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment.

Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line."
            />

            {/* <!-- Boxed List --> */}
            <FreelancerFeedback />
            {/* <!-- Boxed List / End --> */}

            {/* <!-- Boxed List --> */}

            <EmployementComponent />

            {/* <!-- Boxed List / End --> */}
          </div>
          {/* 
      <!-- Sidebar --> */}
          <div className="col-xl-4 col-lg-4">
            <div className="sidebar-container">
              {/* <!-- Profile Overview --> */}

              <MakeAnOffer hourlyrate="35$" jobscount="53" rehiredcount="22" />

              {/* <!-- Freelancer Indicators --> */}

              <Indicators
                jobSuccessPercentage={88}
                recommendationPercentage={95}
                onTimePercentage={90}
                onBudgetPercentage={85}
              />

              {/* <!-- Widget --> */}

              <FreelancerSkills skills={skills} />

              {/* <!-- Widget --> */}

              <div className="sidebar-widget">
                <h3>Attachments</h3>
                <div className="attachments-container">
                  <a href="#" className="attachment-box ripple-effect">
                    <span>Cover Letter</span>
                    <i>PDF</i>
                  </a>
                  <a href="#" className="attachment-box ripple-effect">
                    <span>Contract</span>
                    <i>DOCX</i>
                  </a>
                </div>
              </div>
              <br />
              {/* <FreelancerAttachments /> */}

              <Bookmark />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreelancerProfile;
