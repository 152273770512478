import Dropdown from '@/components/Dropdown'
import { COMPANY_OPTIONS } from '@/libs/constants'
import React, { useState } from 'react'

type AccordionIndex = number | null

const SupportCenter: React.FC = () => {
  // States for the left and right accordions
  const [activeLeftAccordion, setActiveLeftAccordion] =
    useState<AccordionIndex>(1)
  const [activeRightAccordion, setActiveRightAccordion] =
    useState<AccordionIndex>(4) // Starts with Panel 4
  const [activeLeftSubAccordion, setActiveLeftSubAccordion] =
    useState<AccordionIndex>(null)
  const [activeRightSubAccordion, setActiveRightSubAccordion] =
    useState<AccordionIndex>(null)

  const toggleLeftAccordion = (index: number) => {
    setActiveLeftAccordion(activeLeftAccordion === index ? null : index)
    setActiveLeftSubAccordion(null) // Close sub-accordions when toggling main accordion
  }

  const toggleRightAccordion = (index: number) => {
    setActiveRightAccordion(activeRightAccordion === index ? null : index)
    setActiveRightSubAccordion(null) // Close sub-accordions when toggling main accordion
  }

  const toggleLeftSubAccordion = (index: number) => {
    setActiveLeftSubAccordion(activeLeftSubAccordion === index ? null : index)
  }

  const toggleRightSubAccordion = (index: number) => {
    setActiveRightSubAccordion(activeRightSubAccordion === index ? null : index)
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="col-md-12 dashboard-box margin-top-0">
          {/* Headline */}
          <div className="headline">
            <h3>
              <i className="icon-line-awesome-comment-o"></i> Sıkça Sorulan
              Sorular
            </h3>
          </div>

          <div className="content">
            <ul className="fields-ul">
              <li>
                <div className="row">
                  <div className="col-md-6">
                    <div className="submit-field">
                      <div className="accordion js-accordion">
                        {/* Left Accordion Items */}
                        {[1, 2, 3].map((panelIndex) => (
                          <div
                            key={panelIndex}
                            className={`accordion__item js-accordion-item ${
                              activeLeftAccordion === panelIndex ? 'active' : ''
                            }`}
                          >
                            <div
                              className="accordion-header js-accordion-header"
                              onClick={() => toggleLeftAccordion(panelIndex)}
                            >
                              Panel {panelIndex}
                            </div>

                            {/* Accordion Body */}
                            <div
                              className="accordion-body js-accordion-body"
                              style={{
                                display:
                                  activeLeftAccordion === panelIndex
                                    ? 'block'
                                    : 'none'
                              }}
                            >
                              <div className="accordion-body__contents">
                                {panelIndex === 1 &&
                                  'Globally incubate standards compliant channels before scalable benefits. Quickly disseminate superior deliverables whereas web-enabled applications. Quickly drive clicks-and-mortar catalysts for change.'}
                                {panelIndex === 2 && (
                                  <>
                                    Distinctively re-engineer revolutionary
                                    meta-services and premium architectures.
                                    Intrinsically incubate intuitive
                                    opportunities and real-time potentialities.
                                    Appropriately communicate one-to-one
                                    technology after plug-and-play networks.
                                    <div className="accordion js-accordion">
                                      {[1, 2].map((subPanelIndex) => (
                                        <div
                                          key={subPanelIndex}
                                          className={`accordion__item js-accordion-item ${
                                            activeLeftSubAccordion ===
                                            subPanelIndex
                                              ? 'active'
                                              : ''
                                          }`}
                                        >
                                          <div
                                            className="accordion-header js-accordion-header"
                                            onClick={() =>
                                              toggleLeftSubAccordion(
                                                subPanelIndex
                                              )
                                            }
                                          >
                                            Sub Panel {subPanelIndex}
                                          </div>
                                          <div
                                            className="accordion-body js-accordion-body"
                                            style={{
                                              display:
                                                activeLeftSubAccordion ===
                                                subPanelIndex
                                                  ? 'block'
                                                  : 'none'
                                            }}
                                          >
                                            <div className="accordion-body__contents">
                                              {subPanelIndex === 1 &&
                                                'Completely synergize scalable e-commerce rather than high standards in e-services. Assertively iterate resource maximizing products after leading-edge intellectual capital.'}
                                              {subPanelIndex === 2 &&
                                                'Dynamically target high-payoff intellectual capital for customized technologies. Objectively integrate emerging core competencies before process-centric communities.'}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                )}
                                {panelIndex === 3 &&
                                  'Efficiently enable enabled sources and cost effective products. Completely synthesize principle-centered information after ethical communities. Efficiently innovate open-source infrastructures via inexpensive materials.'}
                              </div>
                            </div>
                            {/* Accordion Body / End */}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <div className="accordion js-accordion">
                        {/* Right Accordion Items */}
                        {[1, 2, 3].map((panelIndex) => (
                          <div
                            key={panelIndex}
                            className={`accordion__item js-accordion-item ${
                              activeRightAccordion === panelIndex + 3
                                ? 'active'
                                : ''
                            }`}
                          >
                            <div
                              className="accordion-header js-accordion-header"
                              onClick={() =>
                                toggleRightAccordion(panelIndex + 3)
                              }
                            >
                              Panel {panelIndex}
                            </div>

                            {/* Accordion Body */}
                            <div
                              className="accordion-body js-accordion-body"
                              style={{
                                display:
                                  activeRightAccordion === panelIndex + 3
                                    ? 'block'
                                    : 'none'
                              }}
                            >
                              <div className="accordion-body__contents">
                                {panelIndex === 1 &&
                                  'Globally incubate standards compliant channels before scalable benefits. Quickly disseminate superior deliverables whereas web-enabled applications. Quickly drive clicks-and-mortar catalysts for change.'}
                                {panelIndex === 2 && (
                                  <>
                                    Distinctively re-engineer revolutionary
                                    meta-services and premium architectures.
                                    Intrinsically incubate intuitive
                                    opportunities and real-time potentialities.
                                    Appropriately communicate one-to-one
                                    technology after plug-and-play networks.
                                    <div className="accordion js-accordion">
                                      {[1, 2].map((subPanelIndex) => (
                                        <div
                                          key={subPanelIndex}
                                          className={`accordion__item js-accordion-item ${
                                            activeRightSubAccordion ===
                                            subPanelIndex + 3
                                              ? 'active'
                                              : ''
                                          }`}
                                        >
                                          <div
                                            className="accordion-header js-accordion-header"
                                            onClick={() =>
                                              toggleRightSubAccordion(
                                                subPanelIndex + 3
                                              )
                                            }
                                          >
                                            Sub Panel {subPanelIndex}
                                          </div>
                                          <div
                                            className="accordion-body js-accordion-body"
                                            style={{
                                              display:
                                                activeRightSubAccordion ===
                                                subPanelIndex + 3
                                                  ? 'block'
                                                  : 'none'
                                            }}
                                          >
                                            <div className="accordion-body__contents">
                                              {subPanelIndex === 1 &&
                                                'Completely synergize scalable e-commerce rather than high standards in e-services. Assertively iterate resource maximizing products after leading-edge intellectual capital.'}
                                              {subPanelIndex === 2 &&
                                                'Dynamically target high-payoff intellectual capital for customized technologies. Objectively integrate emerging core competencies before process-centric communities.'}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                )}
                                {panelIndex === 3 &&
                                  'Efficiently enable enabled sources and cost effective products. Completely synthesize principle-centered information after ethical communities. Efficiently innovate open-source infrastructures via inexpensive materials.'}
                              </div>
                            </div>
                            {/* Accordion Body / End */}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-md-12 dashboard-box margin-top-30">
          {/* Kimlik Bilgileri */}
          <div className="headline">
            <h3>
              <i className="icon-material-outline-business-center"></i> Bize
              Yazın
            </h3>
          </div>
          <div className="content with-padding padding-bottom-0">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Mesaj Konusu</h5>
                      
                      <input type="text" className="with-border" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Mesaj</h5>
                      <textarea
                        cols={30}
                        rows={5}
                        className="with-border"
                        placeholder="Görüş, öneri veya şikayetlerini bizimle paylaş"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <div className="uploadButton margin-top-8">
                        <input
                          className="uploadButton-input"
                          type="file"
                          accept="image/*, application/pdf"
                          id="upload"
                          multiple
                        />
                        <label
                          className="uploadButton-button ripple-effect"
                          htmlFor="upload"
                        >
                          Görsel Ekle
                        </label>
                        <span className="uploadButton-file-name">
                          Mesaj içeriğini destekleyecek görsel ekleyebilirsin.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <button
                      className="button full-width button-sliding-icon ripple-effect margin-top-8 margin-bottom-14"
                      type="submit"
                      form="login-form"
                    >
                      Mesajı Gönder{' '}
                      <i className="icon-material-outline-arrow-right-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportCenter
