import React, { useState } from "react";
import FeedbackComponent from "./FeedbackComponent";

interface Feedback {
  title: string;
  role: string;
  rating: number;
  date: string;
  description: string;
}

const feedbacks: Feedback[] = [
  {
    title: "Web, Database and API Developer",
    role: "Freelancer",
    rating: 5.0,
    date: "August 2019",
    description:
      "Excellent programmer - fully carried out my project in a very professional manner.",
  },
  {
    title: "Web, Database and API Developer",
    role: "Freelancer",
    rating: 5.0,
    date: "August 2019",
    description:
      "Excellent programmer - fully carried out my project in a very professional manner.",
  },
  {
    title: "Web, Database and API Developer",
    role: "Freelancer",
    rating: 5.0,
    date: "August 2019",
    description:
      "Excellent programmer - fully carried out my project in a very professional manner.",
  },
  {
    title: "Web, Database and API Developer",
    role: "Freelancer",
    rating: 5.0,
    date: "August 2019",
    description:
      "Excellent programmer - fully carried out my project in a very professional manner.",
  },
  {
    title: "Web, Database and API Developer",
    role: "Freelancer",
    rating: 5.0,
    date: "August 2019",
    description:
      "Excellent programmer - fully carried out my project in a very professional manner.",
  },
  {
    title: "Web, Database and API Developer",
    role: "Freelancer",
    rating: 5.0,
    date: "August 2019",
    description:
      "Excellent programmer - fully carried out my project in a very professional manner.",
  },
  {
    title: "Web, Database and API Developer",
    role: "Freelancer",
    rating: 5.0,
    date: "August 2019",
    description:
      "Excellent programmer - fully carried out my project in a very professional manner.",
  },
  {
    title: "Web, Database and API Developer",
    role: "Freelancer",
    rating: 5.0,
    date: "August 2019",
    description:
      "Excellent programmer - fully carried out my project in a very professional manner.",
  },
  // Diğer feedback öğeleri...
];

const FreelancerFeedback = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const feedbacksPerPage = 5;

  // Feedbackleri tarihe göre sıralama (en güncelden en eskiye)
  const sortedFeedbacks = feedbacks.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  // Mevcut sayfada gösterilecek feedbackleri hesaplama
  const indexOfLastFeedback = currentPage * feedbacksPerPage;
  const indexOfFirstFeedback = indexOfLastFeedback - feedbacksPerPage;
  const currentFeedbacks = sortedFeedbacks.slice(
    indexOfFirstFeedback,
    indexOfLastFeedback
  );

  // Toplam sayfa sayısını hesaplama
  const totalPages = Math.ceil(feedbacks.length / feedbacksPerPage);

  return (
    <>
      <div className="boxed-list margin-bottom-60">
        <div className="boxed-list-headline">
          <h3>
            <i className="icon-material-outline-thumb-up"></i> Work History and
            Feedback
          </h3>
        </div>
        <ul className="boxed-list-ul">
          {currentFeedbacks.map((feedback, index) => (
            <FeedbackComponent
              key={index}
              title={feedback.title}
              role={feedback.role}
              rating={feedback.rating}
              date={feedback.date}
              description={feedback.description}
            />
          ))}
        </ul>

        {/* Pagination */}
        {totalPages > 1 && (
          <>
            <div className="clearfix"></div>
            <div className="pagination-container margin-top-40 margin-bottom-10">
              <nav className="pagination">
                <ul>
                  {[...Array(totalPages)].map((_, i) => (
                    <li key={i}>
                      <a
                        href="#"
                        className={`ripple-effect ${
                          currentPage === i + 1 ? "current-page" : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentPage(i + 1);
                        }}
                      >
                        {i + 1}
                      </a>
                    </li>
                  ))}
                  {currentPage < totalPages && (
                    <li className="pagination-arrow">
                      <a
                        href="#"
                        className="ripple-effect"
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentPage(currentPage + 1);
                        }}
                      >
                        <i className="icon-material-outline-keyboard-arrow-right"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
            <div className="clearfix"></div>
          </>
        )}
      </div>
    </>
  );
};

export default FreelancerFeedback;
