import Dropdown from '@/components/Dropdown'
import { DISTRICT_OPTIONS, TAX_PROVINCES_OPTIONS } from '@/libs/constants'
import React from 'react'

const MyOrder = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="col-md-12 dashboard-box margin-top-0">
          <div className="headline">
            <h3>
              <i className="icon-material-outline-business-center"></i> Çalışma
              Düzenim
            </h3>
          </div>

          <div className="content">
            <ul className="dashboard-box-list">
              <li>
                <div className="job-listing">
                  <div className="job-listing-details">
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">
                        <a href="#">Çalışma gün ve saatleri</a>
                      </h3>
                      <div className="payment margin-top-10">
                        <div className="payment-tab payment-tab-active">
                          <div className="payment-tab-trigger">
                            <input
                              checked
                              id="paypal"
                              name="cardType"
                              type="radio"
                              value="paypal"
                            />
                            <label htmlFor="paypal">
                              Haftanın her günü ve saati çalışabilirim
                            </label>
                          </div>
                        </div>
                        <div className="payment-tab">
                          <div className="payment-tab-trigger">
                            <input
                              type="radio"
                              name="cardType"
                              id="creditCart"
                              value="creditCard"
                            />
                            <label htmlFor="creditCart">
                              Çalışacağım gün ve saatleri seçmek istiyorum
                            </label>
                          </div>

                          <div className="payment-tab-content">
                            <div className="row payment-form-row">
                              <div className="col-md-2 margin-top-10">
                                <div className="countdown yellow margin-bottom-15">
                                  <div className="switches-list">
                                    <div className="switch-container">
                                      <label className="switch">
                                        <input type="checkbox" checked />
                                        <span className="switch-button"></span>{' '}
                                        Pazartesi
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value="08:00"
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value="18:00"
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2 margin-top-10">
                                <div className="countdown yellow margin-bottom-15">
                                  <div className="switches-list">
                                    <div className="switch-container">
                                      <label className="switch">
                                        <input type="checkbox" checked />
                                        <span className="switch-button"></span>{' '}
                                        Salı{' '}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value="08:00"
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value="18:00"
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2 margin-top-10">
                                <div className="countdown yellow margin-bottom-15">
                                  <div className="switches-list">
                                    <div className="switch-container">
                                      <label className="switch">
                                        <input type="checkbox" checked />
                                        <span className="switch-button"></span>{' '}
                                        Çarşamba
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value="08:00"
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value="18:00"
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2 margin-top-10">
                                <div className="countdown yellow margin-bottom-15">
                                  <div className="switches-list">
                                    <div className="switch-container">
                                      <label className="switch">
                                        <input type="checkbox" checked />
                                        <span className="switch-button"></span>{' '}
                                        Perşembe
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value="08:00"
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value="18:00"
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2 margin-top-10">
                                <div className="countdown yellow margin-bottom-15">
                                  <div className="switches-list">
                                    <div className="switch-container">
                                      <label className="switch">
                                        <input type="checkbox" checked />
                                        <span className="switch-button"></span>{' '}
                                        Cuma
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value="08:00"
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value="18:00"
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2 margin-top-10">
                                <div className="countdown green margin-bottom-15">
                                  <div className="switches-list">
                                    <div className="switch-container">
                                      <label className="switch">
                                        <input type="checkbox" checked />
                                        <span className="switch-button"></span>{' '}
                                        Cumartesi
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value="08:00"
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value="18:00"
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2 margin-top-10">
                                <div className="countdown red margin-bottom-15">
                                  <div className="switches-list">
                                    <div className="switch-container">
                                      <label className="switch">
                                        <input type="checkbox" />
                                        <span className="switch-button"></span>{' '}
                                        Pazar
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value="08:00"
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="submit-field margin-top-12">
                                  <div className="bidding-fields">
                                    <div className="bidding-field">
                                      <div className="qtyButtons">
                                        <div className="qtyDec"></div>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value="18:00"
                                        />
                                        <div className="qtyInc"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div className="job-listing">
                  <div className="job-listing-details">
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">
                        <a href="#">Konum</a>
                      </h3>

                      <div className="job-listing-footer">
                        <ul>
                          <li>
                            <i className="icon-material-outline-location-on"></i>{' '}
                            Hangi şehirlerde çalışmak istersiniz?
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="submit-field">
                    <Dropdown
                      options={TAX_PROVINCES_OPTIONS}
                      placeholder="İl"
                    />
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="submit-field">
                  <Dropdown
                      options={DISTRICT_OPTIONS}
                      placeholder="İlçe"
                    />
                  </div>
                </div>

                <div className="col-md-2 margin-bottom-12">
                  <button className="b-add-location" type="submit">
                    <i className="icon-feather-plus"></i>
                  </button>
                </div>

                <div className="col-md-3">
                  <a href="#" className="button ripple-effect big">
                    <i className="icon-feather-plus"></i> Başka Şehir Ekle
                  </a>
                </div>
              </li>

              <li>
                {/* Job Listing */}
                <div className="job-listing">
                  {/* Job Listing Details */}
                  <div className="job-listing-details">
                    {/* Details */}
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">
                        <a href="#">Pozisyon</a>
                      </h3>
                      <div className="job-listing-footer">
                        <ul>
                          <li>
                            <i className="icon-feather-file-plus"></i> Hangi
                            pozisyonlarda çalışmak istersin?
                          </li>
                        </ul>
                      </div>
                      <div className="payment margin-top-10">
                        <div className="payment-tab payment-tab-active">
                          <div className="payment-tab-trigger">
                            <input
                              checked
                              id="paypal2"
                              name="cardType"
                              type="radio"
                              value="paypal"
                            />
                            <label htmlFor="paypal2">
                              Tüm işleri ve teklifleri görmek istiyorum
                            </label>
                          </div>
                        </div>
                        <div className="payment-tab">
                          <div className="payment-tab-trigger">
                            <input
                              type="radio"
                              name="cardType"
                              id="creditCart2"
                              value="creditCard"
                            />
                            <label htmlFor="creditCart2">
                              Çalışacağım pozisyonları seçmek istiyorum
                            </label>
                          </div>
                          <div className="payment-tab-content">
                            <div className="row payment-form-row">
                              <div className="col-md-6 margin-top-10">
                                <div className="submit-field">
                                  <div className="keywords-container margin-top-10">
                                    <div className="keyword-input-container">
                                      <input
                                        type="text"
                                        className="keyword-input with-border"
                                        placeholder="Örn: Genel müdür, yönetici..."
                                      />
                                      <button className="keyword-input-button ripple-effect">
                                        <i className="icon-material-outline-add"></i>
                                      </button>
                                    </div>
                                    <div className="keywords-list">
                                      {/* keywords go here */}
                                    </div>
                                    <div className="clearfix"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div className="job-listing">
                  <div className="job-listing-details">
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">
                        <a href="#">Ücret</a>
                      </h3>
                      <div className="job-listing-footer">
                        <ul>
                          <li>
                            <i className="icon-line-awesome-money"></i> Talep
                            edeceğin saatlik ücret nedir?
                          </li>
                        </ul>
                      </div>
                      <div className="payment margin-top-10">
                        <div className="payment-tab payment-tab-active">
                          <div className="payment-tab-trigger">
                            <input
                              checked
                              id="paypal3"
                              name="cardType"
                              type="radio"
                              value="paypal3"
                            />
                            <label htmlFor="paypal3">
                              Tüm ücretlerdeki işleri ve teklifleri görmek
                              istiyorum
                            </label>
                          </div>
                        </div>
                        <div className="payment-tab">
                          <div className="payment-tab-trigger">
                            <input
                              type="radio"
                              name="cardType"
                              id="creditCart3"
                              value="creditCard3"
                            />
                            <label htmlFor="creditCart3">
                              Ücretimi kendim belirlemek istiyorum
                            </label>
                          </div>

                          <div className="payment-tab-content">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="submit-field">
                                  <div className="bidding-widget">
                                    {/* Headline */}
                                    <span className="bidding-detail">
                                      <strong>En az ücret</strong>
                                    </span>

                                    {/* Slider */}
                                    <div className="bidding-value margin-bottom-10">
                                      <span id="biddingVal">1,000</span> ₺
                                    </div>
                                    <div className="slider slider-horizontal">
                                      <div className="slider-track">
                                        <div
                                          className="slider-track-low"
                                          style={{ left: '0px', width: '0%' }}
                                        ></div>
                                        <div
                                          className="slider-selection"
                                          style={{ left: '0%', width: '0%' }}
                                        ></div>
                                        <div
                                          className="slider-track-high"
                                          style={{
                                            right: '0px',
                                            width: '100%'
                                          }}
                                        ></div>
                                      </div>
                                      <div
                                        className="tooltip tooltip-main top hide"
                                        role="presentation"
                                        style={{ left: '0%' }}
                                      >
                                        <div className="tooltip-arrow"></div>
                                        <div className="tooltip-inner">
                                          1000
                                        </div>
                                      </div>
                                      <div
                                        className="tooltip tooltip-min top hide"
                                        role="presentation"
                                      >
                                        <div className="tooltip-arrow"></div>
                                        <div className="tooltip-inner"></div>
                                      </div>
                                      <div
                                        className="tooltip tooltip-max top hide"
                                        role="presentation"
                                      >
                                        <div className="tooltip-arrow"></div>
                                        <div className="tooltip-inner"></div>
                                      </div>
                                      <div
                                        className="slider-handle min-slider-handle custom"
                                        role="slider"
                                        aria-valuemin={1000}
                                        aria-valuemax={20000}
                                        aria-valuenow={1000}
                                        tabIndex={0}
                                        style={{ left: '0%' }}
                                      ></div>
                                      <div
                                        className="slider-handle max-slider-handle custom hide"
                                        role="slider"
                                        aria-valuemin={1000}
                                        aria-valuemax={20000}
                                        aria-valuenow={1000}
                                        tabIndex={0}
                                        style={{ left: '0%' }}
                                      ></div>
                                    </div>
                                    <input
                                      className="bidding-slider"
                                      type="text"
                                      value="1000"
                                      data-slider-handle="custom"
                                      data-slider-currency="₺"
                                      data-slider-min="1000"
                                      data-slider-max="20000"
                                      data-slider-value="35"
                                      data-slider-step="100"
                                      data-slider-tooltip="hide"
                                      style={{ display: 'none' }}
                                      data-value="1000"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="submit-field">
                                  <div className="bidding-widget">
                                    {/* Headline */}
                                    <span className="bidding-detail">
                                      <strong>En fazla ücret</strong>
                                    </span>

                                    {/* Slider */}
                                    <div className="bidding-value margin-bottom-10">
                                      <span id="biddingVal2">20,000</span> ₺
                                    </div>
                                    <div className="slider slider-horizontal">
                                      <div className="slider-track">
                                        <div
                                          className="slider-track-low"
                                          style={{ left: '0px', width: '0%' }}
                                        ></div>
                                        <div
                                          className="slider-selection"
                                          style={{ left: '0%', width: '0%' }}
                                        ></div>
                                        <div
                                          className="slider-track-high"
                                          style={{
                                            right: '0px',
                                            width: '100%'
                                          }}
                                        ></div>
                                      </div>
                                      <div
                                        className="tooltip tooltip-main top hide"
                                        role="presentation"
                                        style={{ left: '0%' }}
                                      >
                                        <div className="tooltip-arrow"></div>
                                        <div className="tooltip-inner">
                                          20000
                                        </div>
                                      </div>
                                      <div
                                        className="tooltip tooltip-min top hide"
                                        role="presentation"
                                      >
                                        <div className="tooltip-arrow"></div>
                                        <div className="tooltip-inner"></div>
                                      </div>
                                      <div
                                        className="tooltip tooltip-max top hide"
                                        role="presentation"
                                      >
                                        <div className="tooltip-arrow"></div>
                                        <div className="tooltip-inner"></div>
                                      </div>
                                      <div
                                        className="slider-handle min-slider-handle custom"
                                        role="slider"
                                        aria-valuemin={20000}
                                        aria-valuemax={200000}
                                        aria-valuenow={20000}
                                        tabIndex={0}
                                        style={{ left: '0%' }}
                                      ></div>
                                      <div
                                        className="slider-handle max-slider-handle custom hide"
                                        role="slider"
                                        aria-valuemin={20000}
                                        aria-valuemax={200000}
                                        aria-valuenow={20000}
                                        tabIndex={0}
                                        style={{ left: '0%' }}
                                      ></div>
                                    </div>
                                    <input
                                      className="bidding-slider2"
                                      type="text"
                                      value="20000"
                                      data-slider-handle="custom"
                                      data-slider-currency="₺"
                                      data-slider-min="20000"
                                      data-slider-max="200000"
                                      data-slider-value="35"
                                      data-slider-step="100"
                                      data-slider-tooltip="hide"
                                      style={{ display: 'none' }}
                                      data-value="20000"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="job-listing">
                  <div className="job-listing-details">
                    <div className="job-listing-description">
                      <h3 className="job-listing-title">
                        <a href="#">Diğer</a>
                      </h3>
                      <div className="job-listing-footer margin-bottom-10">
                        <ul>
                          <li>
                            <i className="icon-brand-accessible-icon"></i> Özel
                            bir durumun ya da hassasiyetin var mı?
                          </li>
                        </ul>
                      </div>
                      <div className="row payment-form-row">
                        <div className="col-md-3 margin-top-10">
                          <div className="countdown green">
                            <div className="switches-list">
                              <div className="switch-container">
                                <label className="switch">
                                  <input type="checkbox" checked />
                                  <span className="switch-button"></span>{' '}
                                  Evliyim
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 margin-top-10">
                          <div className="countdown green">
                            <div className="switches-list">
                              <div className="switch-container">
                                <label className="switch">
                                  <input type="checkbox" checked />
                                  <span className="switch-button"></span>{' '}
                                  Askerliğimi yaptım
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 margin-top-10">
                          <div className="countdown green">
                            <div className="switches-list">
                              <div className="switch-container">
                                <label className="switch">
                                  <input type="checkbox" checked />
                                  <span className="switch-button"></span>{' '}
                                  Ehliyetim var
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 margin-top-10">
                          <div className="countdown green">
                            <div className="switches-list">
                              <div className="switch-container">
                                <label className="switch">
                                  <input type="checkbox" checked />
                                  <span className="switch-button"></span>{' '}
                                  Emekliyim
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 margin-top-10">
                          <div className="countdown green">
                            <div
                              className="switches-list"
                              id="snackbar-user-status-s"
                            >
                              <div className="switch-container">
                                <label className="switch">
                                  <input type="checkbox" />
                                  <span className="switch-button"></span>{' '}
                                  Engelli raporum var
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <a
          href="#small-dialog"
          className="popup-with-zoom-anim button full-width button-sliding-icon ripple-effect margin-top-30"
          style={{ width: '185.25px' }}
        >
          {' '}
          Bilgilerimi Güncelle{' '}
          <i className="icon-material-outline-arrow-right-alt"></i>
        </a>
      </div>
    </div>
  )
}

export default MyOrder
