const LogInRegister = () => {
  return (
    <div className="header-widget" style={{ borderLeft: "none" }}>
      <a
        href="/login"
        className="popup-with-zoom-anim log-in-button"
      >
        <i className="icon-feather-log-in"></i>
        <span>Giriş Yap / Kayıt Ol</span>
        {/* index.css ---> .header-widget .log-in-button   yazı rengi ayarlama*/}
      </a>
    </div>
    // data-feather="log-in"

    // <div className="right-side">
    //   <div className="header-widget">
    //     <a
    //       href="#sign-in-dialog"
    //       className="popup-with-zoom-anim log-in-button"
    //     >
    //       <i className="icon-feather-log-in"></i> <span>Log In / Register</span>
    //     </a>
    //   </div>

    //   <span className="mmenu-trigger">
    //     <button className="hamburger hamburger--collapse" type="button">
    //       <span className="hamburger-box">
    //         <span className="hamburger-inner"></span>
    //       </span>
    //     </button>
    //   </span>
    // </div>
  );
};

export default LogInRegister;
