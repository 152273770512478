import { RootState } from '@/store';
import React from 'react'
import {useSelector} from "react-redux"
import {Navigate, useLocation} from "react-router-dom"

const ProtectedRoute = (a:any) => {
    const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
    let location = useLocation();
    
    if(!isLoggedIn)
        return <Navigate to="/login" state={{ from: location}} replace />
    
    return a.children;
};

export default ProtectedRoute;
